import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SubscriptionRebooking({ subscriptionCreateUrl, ticketNumber, controlNumber, subscriptionTypeUid, email }) {
	const navigate = useNavigate();

	useEffect(() => {
		if (subscriptionCreateUrl) {
			navigate(
				`${subscriptionCreateUrl}?t=${subscriptionTypeUid}&tn=${ticketNumber}&cn=${controlNumber}&email=${email}`
			);
		} else {
			navigate('/cart', {
				state: {
					gifts: [
						{
							subscriptionTypeUid,
							qty: 1,
							ticketNumber,
							controlNumber
						}
					]
				}
			});
		}
	}, [subscriptionCreateUrl, ticketNumber, controlNumber, navigate, email, subscriptionTypeUid]);

	return <></>;
}

export default SubscriptionRebooking;
