import React, { useState, useEffect, useRef } from 'react';
import { Button, Paper, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Remove, Add } from '@mui/icons-material';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import Icon from '@mui/material/Icon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
	groupSummaryListener,
	productsListener
} from '../../../types/event-group-date-selector/EventGroupDateSelectorFirestore';
import { getEventGroups } from '../../../types/manual-event-overview/ManualEventOverviewFirestore';
import {
	getList,
	mergeThemes,
	selectGoogleTagManager,
	selectLists,
	selectTheme
} from '../../../../../../store/shared/frontendSlice';
import { selectMainTheme } from '../../../../../../store/fuse/settingsSlice';
import getColor from '../shared/getColor';
import { findPricesForProduct } from '@ameroservices-platform/shared/utility/index';
import { tagManagerViewItemList } from '../../../../../../services/googleTagManagerService/googleTagManagerService';
import ProductTotalSection from '../shared/ProductSection';
import ProductGroupRow from '../shared/ProductGroupRow';
import ProductRow from '../shared/ProductRow';
import { productGroupsListener } from '../../../types/event-group-date-time-selector/EventGroupDateTimeSelectorFirestore';

let locale = 'da-DK';
const language = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : { isoCode: 'da' };
if (language && language.isoCode && language.isoCode !== 'da') {
	locale = language.isoCode;
}

function ListEventGroupDateSelector(props) {
	const { contentElementProps } = props;

	const flags = useFlags();

	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const { t } = useTranslation();
	const useStyles = makeStyles(_theme => ({
		stepNumberIcon: {
			color: `${getColor(
				contentElementProps.stepNumberIconTextColor,
				contentElementProps.customStepNumberIconTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.stepNumberIconBackgroundColor,
				contentElementProps.customStepNumberIconBackgroundColor,
				mergedTheme
			)}`
		},
		text: {
			color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`
		},
		button: {
			color: `${getColor(
				contentElementProps.buttonTextColor,
				contentElementProps.customButtonTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.buttonBackgroundColor,
				contentElementProps.customButtonBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.buttonTextColorHover,
					contentElementProps.customButtonTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.buttonBackgroundColorHover,
					contentElementProps.customButtonBackgroundColorHover,
					mergedTheme
				)}`
			}
		},
		timePickerButton: {
			color: `${getColor(
				contentElementProps.dateTimePickerTextColor,
				contentElementProps.customDateTimePickerTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.dateTimePickerBackgroundColor,
				contentElementProps.customDateTimePickerBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.dateTimePickerTextColor,
					contentElementProps.customDateTimePickerTextColor,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.dateTimePickerBackgroundColor,
					contentElementProps.customDateTimePickerBackgroundColor,
					mergedTheme
				)}`
			}
		},
		timePickerButtonActive: {
			color: `${getColor(
				contentElementProps.dateTimePickerTextColorActive,
				contentElementProps.customDateTimePickerTextColorActive,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.dateTimePickerBackgroundColorActive,
				contentElementProps.customDateTimePickerBackgroundColorActive,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.dateTimePickerTextColorActive,
					contentElementProps.customDateTimePickerTextColorActive,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.dateTimePickerBackgroundColorActive,
					contentElementProps.customDateTimePickerBackgroundColorActive,
					mergedTheme
				)}`
			}
		},
		eventGroupSelect: {
			color: `${getColor(
				contentElementProps.locationTextColor,
				contentElementProps.customLocationTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.locationBackgroundColor,
				contentElementProps.customLocationBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.locationTextColor,
					contentElementProps.customLocationTextColor,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.locationBackgroundColor,
					contentElementProps.customLocationBackgroundColor,
					mergedTheme
				)}`
			}
		},
		eventGroupActive: {
			color: `${getColor(
				contentElementProps.locationActiveTextColor,
				contentElementProps.customLocationActiveTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.locationActiveBackgroundColor,
				contentElementProps.customLocationActiveBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.locationActiveTextColor,
					contentElementProps.customLocationActiveTextColor,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.locationActiveBackgroundColor,
					contentElementProps.customLocationActiveBackgroundColor,
					mergedTheme
				)}`
			}
		},
		buyButton: {
			color: `${getColor(
				contentElementProps.buyButtonTextColor,
				contentElementProps.customBuyButtonTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.buyButtonBackgroundColor,
				contentElementProps.customBuyButtonBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.buyButtonTextColorHover,
					contentElementProps.customBuyButtonTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.buyButtonBackgroundColorHover,
					contentElementProps.customBuyButtonBackgroundColorHover,
					mergedTheme
				)}`
			}
		},
		grid: { flexGrow: 1 },

		calendarPicker: {
			width: '100%',
			overflow: 'hidden',

			// All days as a group
			// '& .react-calendar__month-view__days': { marginTop: '5px', display: 'flex', alignItems: 'center' },
			'& .react-calendar__navigation': {
				display: 'flex'
			},

			'& .react-calendar__navigation__arrow': {
				width: '70px',
				height: '40px',
				color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
				background: 'transparent'
			},
			'& .react-calendar__navigation__label': {
				color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
				background: 'transparent'
			},

			// Weekdays - man, tir, ons...
			'& .react-calendar__month-view__weekdays__weekday abbr': {
				textDecoration: 'none',
				color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`
			},

			// All days individual
			'& .react-calendar__month-view__days__day': {
				backgroundColor: `${getColor(
					contentElementProps.dateTimePickerBackgroundColor,
					contentElementProps.customDateTimePickerBackgroundColor,
					mergedTheme
				)}`,
				color: `${getColor(
					contentElementProps.dateTimePickerTextColor,
					contentElementProps.customDateTimePickerTextColor,
					mergedTheme
				)}`,
				outline: '2px solid #FDFBF7',
				'& abbr': {
					display: 'none'
				},
				'& div': {
					padding: '10px'
				}
			},

			// Active button (selected date)
			'& .react-calendar__tile.react-calendar__tile--active': {
				backgroundColor: `${getColor(
					contentElementProps.dateTimePickerBackgroundColorActive,
					contentElementProps.customDateTimePickerBackgroundColorActive,
					mergedTheme
				)}`,
				color: `${getColor(
					contentElementProps.dateTimePickerTextColorActive,
					contentElementProps.customDateTimePickerTextColorActive,
					mergedTheme
				)}`
			},

			// Disabled buttons (previous dates)
			'& button:disabled.react-calendar__month-view__days__day': {
				opacity: '0.3'
			},

			// Todays date
			'& .react-calendar__tile--now': {}
		}
	}));
	const now = moment().toDate();
	const [year, setYear] = useState(now.getFullYear());
	const [month, setMonth] = useState(now.getMonth() + 1);
	const [openBox1, setOpenBox1] = useState(true);
	const [openBox2, setOpenBox2] = useState(false);
	const [openBox3, setOpenBox3] = useState(false);
	const [ticketCount, setTicketCount] = useState(0);
	const [eventGroups, setEventGroups] = useState([]);
	const lists = useSelector(selectLists);
	const [groupSummary, setGroupSummary] = useState(null);
	const [products, setProducts] = useState([]);
	const [showingProducts, setShowingProducts] = useState([]);
	const [date, setDate] = useState(null);
	const [selectedEventGroup, setSelectedEventGroup] = useState(null);
	const [productsWithAmount, setProductsWithAmount] = useState({});
	const [totalAmount, setTotalAmount] = useState(0);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [highlightedDisclaimer, setHighlightedDisclaimer] = useState(null);
	const [productGroups, setProductGroups] = useState({});
	const [disclaimers, setDisclaimers] = useState({});
	const [customColors, setCustomColors] = useState({});

	const classes = useStyles();
	const navigate = useNavigate();
	const gridAttr = { xs: 12, sm: 12, md: 4, lg: 4, xl: 4 };

	const funcCustomColors = makeStyles(() => customColors);
	const customClasses = funcCustomColors();

	const groupByRefs = useRef();

	const googleTagManager = useSelector(selectGoogleTagManager);

	useEffect(() => {
		if (googleTagManager && groupSummary) {
			const items = Object.values(groupSummary.dates)
				.map(gsValue => {
					return Object.values(gsValue.times);
				})
				.reduce((acc, cur) => acc.concat(cur), [])
				.map(event => {
					return {
						item_id: event.eventUid,
						item_name: event.originalEventName || event.name
					};
				});
			tagManagerViewItemList(items);
		}
	}, [googleTagManager, groupSummary]);

	useEffect(() => {
		const ids = getList(lists, `lists.${contentElementProps.eventGroupsListUid}`).eventGroup;
		getEventGroups(ids, async eventGroupsData => {
			setEventGroups(Object.values(eventGroupsData));
		}).catch(err => console.error(err));
	}, [lists, contentElementProps.eventGroupsListUid]);

	useEffect(() => {
		if (
			eventGroups &&
			selectedEventGroup !== null &&
			contentElementProps !== undefined &&
			contentElementProps !== null
		) {
			const productsListenerUnsubFunc = productsListener(selectedEventGroup.ticketTypes, false, productList => {
				const copyOfProducts = productList;
				const productsWithDisclaimer = productList.filter(filterForDisclaimers);
				const indexes = {};
				let index = 0;
				productsWithDisclaimer.forEach(product => {
					if (
						product.disclaimer &&
						(indexes[product.disclaimer] === undefined || indexes[product.disclaimer] === null)
					) {
						indexes[product.disclaimer] = index;
						index += 1;
					}
				});
				productsWithDisclaimer.forEach(product => {
					if (product.disclaimer) {
						product.disclaimerIndex = indexes[product.disclaimer];
					}
				});
				setDisclaimers(indexes);
				setProducts(copyOfProducts);
			});

			const productGroupsListenerUnsubFuncs = productGroupsListener(
				selectedEventGroup.ticketTypes,
				(id, item) => {
					setProductGroups(oldState => {
						return { ...oldState, [id]: item };
					});
				}
			);
			return () => {
				productsListenerUnsubFunc();
				productGroupsListenerUnsubFuncs.forEach(f => f());
			};
		}

		return () => {};
	}, [eventGroups, contentElementProps, selectedEventGroup]);

	useEffect(() => {
		if (groupSummary) {
			const dateForUse = date || new Date();
			const timeslotsForToday = groupSummary.dates[dateForUse.getDate()]
				? groupSummary.dates[dateForUse.getDate()].times
				: findDefaultGroupSummaryDates(groupSummary.dates);

			const isAvaiableInTimeslot = Object.values(timeslotsForToday).map(value => {
				return value.available >= ticketCount;
			});

			const showPickTime = isAvaiableInTimeslot.some(slot => slot === true);
			if (Object.keys(timeslotsForToday).length > 1 && showPickTime) {
				setSelectedEvent(null);
			} else if (timeslotsForToday && date) {
				const [event] = Object.values(timeslotsForToday);
				const eventStartTime = event.start.toDate();
				const isSameDay = moment(date).isSame(eventStartTime, 'day');
				if (isSameDay && event.available >= ticketCount) {
					setSelectedEvent(event.eventUid);
				} else {
					setSelectedEvent(null);
				}
			}
		} else if (date) {
			setSelectedEvent(null);
		}
	}, [groupSummary, date, ticketCount]);

	useEffect(() => {
		if (groupSummary) {
			const colors = [];
			Object.keys(groupSummary.dates).forEach(dateKey => {
				Object.keys(groupSummary.dates[dateKey].times).forEach(timeKey => {
					colors.push({
						key: `${groupSummary.month}-${dateKey}`,
						activeColor: groupSummary.dates[dateKey].times[timeKey].activeColor,
						defaultColor: groupSummary.dates[dateKey].times[timeKey].defaultColor
					});
				});
			});

			const obj = {};
			colors.forEach(color => {
				obj[`custom-${color.key}`] = {
					backgroundColor: `${color.defaultColor} !important`,
					'&.react-calendar__tile--active': {
						backgroundColor: `${color.activeColor} !important`
					}
				};
			});
			setCustomColors(obj);
		}
	}, [groupSummary]);

	useEffect(() => {
		setProductsWithAmount(oldState => {
			const newState = {};
			products.forEach(product => {
				if (oldState[product.id] !== null && oldState[product.id] !== undefined) {
					newState[product.id] = oldState[product.id];
				} else {
					newState[product.id] = 0;
				}
			});
			return newState;
		});
	}, [products]);

	useEffect(() => {
		if (selectedEventGroup && year && month) {
			const groupSummariesListenerUnsubFunc = groupSummaryListener(selectedEventGroup.id, month, year, o =>
				setGroupSummary(o)
			);

			return () => groupSummariesListenerUnsubFunc();
		}
		return () => {};
	}, [selectedEventGroup, eventGroups, year, month]);

	useEffect(() => {
		let totals = 0;
		Object.keys(productsWithAmount).forEach(pKey => {
			const product = products.find(f => f.id === pKey);
			if (product && product.prices) {
				const price = findPricesForProduct(
					product,
					selectedEventGroup && selectedEventGroup.locations ? selectedEventGroup.locations[0] : null,
					date,
					flags.displayPeriodePrices,
					productsWithAmount[pKey]
				);
				totals += price * productsWithAmount[pKey];
			}
		});
		setTotalAmount(totals);
	}, [productsWithAmount, products, selectedEventGroup, date, flags.displayPeriodePrices]);

	useEffect(() => {
		let amount = 0;
		Object.values(productsWithAmount).forEach(val => {
			amount += val;
		});
		setTicketCount(amount);
	}, [productsWithAmount]);

	useEffect(() => {
		if (selectedEvent && ticketCount) {
			if (ticketCount > selectedEvent) {
				setDate(null);
				setSelectedEvent(null);
			}
		}
	}, [ticketCount, selectedEvent]);

	useEffect(() => {
		if (selectedEventGroup) {
			setOpenBox2(true);
		}
		if (ticketCount > 0) {
			setOpenBox3(true);
		}
		if (ticketCount === 0 && date) {
			setDate(null);
			setSelectedEvent(null);
		}
	}, [ticketCount, date, selectedEventGroup]);

	useEffect(() => {
		const groupByProductGroups = Object.values(productGroups).filter(pg => {
			return pg.groupBy && pg.groupBy.name;
		});
		if (groupByProductGroups.length > 0) {
			const newProducts = products.filter(p => !groupByProductGroups.some(pg => pg.id === p.ticketTypeUid));
			const productsWithGroupBy = products.filter(p =>
				groupByProductGroups.some(pg => pg.id === p.ticketTypeUid)
			);
			const groupBy = productsWithGroupBy.reduce((accProducts, curProduct) => {
				if (!accProducts[curProduct.ticketTypeUid]) {
					accProducts[curProduct.ticketTypeUid] = [];
				}
				accProducts[curProduct.ticketTypeUid].push(curProduct);
				return accProducts;
			}, {});
			Object.entries(groupBy).forEach(([key, value]) => {
				const prices = value.reduce((acc, cur) => {
					const p = cur.prices.find(pr => pr.type === 'Sale');
					if (p) {
						acc.push(p.price);
					} else {
						acc.push(0);
					}
					return acc;
				}, []);
				prices.sort((a, b) => a - b);
				const lowestPrice = prices[0];
				const highestPrice = prices[prices.length - 1];
				const options = [...value].sort((a, b) => {
					const nameA = a.name.toLowerCase();
					const nameB = b.name.toLowerCase();
					if (nameA < nameB) return -1;
					if (nameA > nameB) return 1;
					return 0;
				});
				newProducts.push({
					id: key,
					name: productGroups[key].groupBy.name,
					lowestPrice,
					highestPrice,
					samePrice: lowestPrice === highestPrice,
					type: 'productGroup',
					items: value.reduce((acc, cur) => {
						acc[cur.id] = cur;
						return acc;
					}, {}),
					options,
					order: 9999
				});
			});
			newProducts.sort((p1, p2) => p1.order - p2.order);
			setShowingProducts(newProducts);
		} else {
			setShowingProducts(products);
		}
	}, [products, productGroups]);

	function goToCheckOut() {
		const location = selectedEventGroup.locations ? selectedEventGroup.locations[0] : null;
		navigate('/cart', {
			state: {
				event: selectedEvent,
				products: productsWithAmount,
				location
			}
		});
	}

	function findDefaultGroupSummaryDates(groupSummaryDates) {
		const dates = Object.keys(groupSummaryDates);
		if (dates.length > 0) {
			return groupSummaryDates[dates[0]].times;
		}
		return {};
	}

	function isAllowedToBuy() {
		return !selectedEvent || Object.values(productsWithAmount).filter(p => p > 0).length === 0;
	}

	function filterForDisclaimers(product) {
		return !!product.disclaimer;
	}

	function handleClickOnEventGroup(eventGroup) {
		if (selectedEventGroup && selectedEventGroup.id === eventGroup.id) return;
		setSelectedEventGroup(eventGroup);
		setProducts([]);
		setGroupSummary(null);
		setTicketCount(0);
		setDate(null);
		setSelectedEvent(null);
		setDisclaimers({});
		setProductsWithAmount({});
	}

	function handleTileDisableState(tileData) {
		if (ticketCount === 0) return true;

		const d = tileData.date.getDate();
		if (groupSummary && groupSummary.dates[d] && groupSummary.dates[d].availableAtSameTime >= ticketCount) {
			return false;
		}
		if (
			date &&
			tileData.date.getFullYear() === date.getFullYear() &&
			tileData.date.getMonth() === date.getMonth() &&
			tileData.date.getDate() === date.getDate()
		) {
			setDate(null);
			setSelectedEvent(null);
		}
		return true;
	}

	return (
		<>
			<div className={clsx(classes.grid, 'max-w-3xl w-full mx-auto')}>
				<Grid container spacing={0}>
					<Paper elevation={3} className="px-4 py-28 md:px-28 w-full">
						<div className="flex flex-col md:flex-row w-full ">
							<Grid item {...gridAttr} className="flex flex-col px-10 pt-0">
								<div
									className={clsx(
										classes.stepNumberIcon,
										'font-bold flex items-center justify-center rounded-full h-28 w-28'
									)}
								>
									1
								</div>
								<Typography className={`font-bold whitespace-no-wrap pt-10 pb-4 ${classes.text}`}>
									{t('WHICH_LOCATION')}
								</Typography>
								<Button
									onClick={() => {
										setOpenBox1(oldstate => !oldstate);
									}}
									color="secondary"
									variant="contained"
									className={`text-center rounded-none normal-case h-28 w-full ${classes.button}`}
								>
									{t('CHOOSE_LOCATION')} <Icon>{openBox1 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
								</Button>
								{openBox1 && (
									<>
										{eventGroups.length > 0 &&
											eventGroups.map(eventGroup => (
												<Button
													role="button"
													className={`flex justify-between items-center h-40 my-8 px-16 cursor-pointer ${
														selectedEventGroup && selectedEventGroup.id === eventGroup.id
															? classes.eventGroupActive
															: classes.eventGroupSelect
													}`}
													key={eventGroup.id}
													onClick={() => handleClickOnEventGroup(eventGroup)}
												>
													<div className="flex justify-between w-full">
														<Typography className="font-bold whitespace-normal">
															{eventGroup.displayName}
														</Typography>
													</div>
												</Button>
											))}
									</>
								)}
							</Grid>
							<Grid item {...gridAttr} className="flex flex-col px-10 pt-0">
								<div
									className={clsx(
										classes.stepNumberIcon,
										'font-bold flex items-center justify-center rounded-full h-28 w-28'
									)}
								>
									2
								</div>
								<Typography className={`font-bold whitespace-no-wrap pt-10 pb-4 ${classes.text}`}>
									{t('AMOUNT_PEOPLE')}
								</Typography>
								<Button
									onClick={() => {
										setOpenBox2(oldstate => !oldstate);
									}}
									color="secondary"
									variant="contained"
									className={`text-center rounded-none normal-case h-28 w-full ${classes.button}`}
								>
									{t('CHOOSE_AMOUNT_PEOPLE')}
									<Icon>{openBox2 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
								</Button>
								{openBox2 && (
									<>
										{flags.productsOnEventSelectorRefactor && (
											<Box2
												classes={classes}
												setProductsWithAmount={setProductsWithAmount}
												disclaimers={disclaimers}
												date={date}
												groupByRefs={groupByRefs}
												eventGroup={selectedEventGroup}
												products={products}
												showingProducts={showingProducts}
												productsWithAmount={productsWithAmount}
												productGroups={productGroups}
												totalAmount={totalAmount}
											/>
										)}
										{!flags.productsOnEventSelectorRefactor && (
											<>
												{products.map(product => (
													<div className="flex justify-between items-center" key={product.id}>
														<div className="flex justify-between w-full">
															<Typography
																className={`font-bold whitespace-normal cursor-default ${classes.text}`}
																onMouseOver={() => {
																	if (
																		product.disclaimerIndex !== null &&
																		product.disclaimerIndex !== undefined
																	) {
																		setHighlightedDisclaimer(
																			product.disclaimerIndex
																		);
																	}
																}}
																onMouseLeave={() => {
																	if (
																		product.disclaimerIndex !== null &&
																		product.disclaimerIndex !== undefined
																	) {
																		setHighlightedDisclaimer(null);
																	}
																}}
															>
																{/* className="font-bold text-14 md:text-base"> */}
																{product.name}
																&nbsp;
																{product.disclaimer
																	? '*'.repeat(product.disclaimerIndex + 1)
																	: ''}
															</Typography>
															<Typography
																className={`px-8 font-bold flex items-center ${classes.text}`}
															>
																{findPricesForProduct(
																	product,
																	selectedEventGroup && selectedEventGroup.locations
																		? selectedEventGroup.locations[0]
																		: null,
																	date,
																	flags.displayPeriodePrices,
																	productsWithAmount[product.id]
																) / 100}
																,-
															</Typography>
														</div>
														<div className="flex ButtonDiv h-56 items-center">
															<Button
																variant="outlined"
																onClick={() => {
																	setProductsWithAmount(oldState => {
																		const p = { ...oldState };
																		if (p[product.id] !== 0) {
																			p[product.id] -= 1;
																		}
																		return p;
																	});
																}}
																className={`w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none ${classes.text}`}
															>
																<Remove />
															</Button>
															<div
																className={`flex items-center text-14 font-bold px-10 ${classes.text}`}
															>
																{productsWithAmount[product.id]}
															</div>
															<Button
																variant="outlined"
																onClick={() => {
																	setProductsWithAmount(oldState => {
																		const p = { ...oldState };
																		p[product.id] += 1;
																		return p;
																	});
																}}
																className={`w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none ${classes.text}`}
															>
																<Add />
															</Button>
														</div>
													</div>
												))}
												<hr />
												<div className="flex justify-between w-full my-6">
													<Typography className={`font-bold text-20 ${classes.text}`}>
														{t('TOTAL')}
													</Typography>
													<Typography className={`font-bold text-20 ${classes.text}`}>{`${
														totalAmount / 100
													} kr.`}</Typography>
												</div>
												<hr />
												<div className="flex flex-col mt-10">
													{Object.entries(disclaimers).map(([disclaimer, index]) => (
														<Typography
															key={index}
															className={`${
																highlightedDisclaimer === index
																	? 'text-14 font-bold'
																	: 'text-12'
															} leading-6`}
															variant="body1"
															style={{ wordWrap: 'break-word' }}
														>
															<span className="mr-4">{'*'.repeat(index + 1)}</span>
															{disclaimer}
														</Typography>
													))}
												</div>
											</>
										)}
									</>
								)}
							</Grid>
							<Grid item {...gridAttr} className="flex flex-col text-left px-10 pt-32 md:pt-0">
								<div
									className={clsx(
										classes.stepNumberIcon,
										'font-bold flex items-center justify-center rounded-full h-28 w-28'
									)}
								>
									3
								</div>
								<Typography className={`font-bold whitespace-no-wrap pt-10 pb-4 ${classes.text}`}>
									{t('VISIT_DATE')}
								</Typography>
								<Button
									onClick={() => {
										setOpenBox3(oldstate => !oldstate);
									}}
									color="secondary"
									variant="contained"
									className={`text-center rounded-none normal-case h-28 w-full ${classes.button}`}
								>
									{t('CHOOSE_DATE')} <Icon>{openBox3 ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
								</Button>
								{openBox3 &&
									(ticketCount > 0 ? (
										<Calendar
											locale={locale || 'da-DK'}
											next2Label={null}
											prev2Label={null}
											prevLabel={<ArrowBackIcon />}
											nextLabel={<ArrowForwardIcon />}
											minDetail="month"
											onChange={value => {
												setDate(value);
												setOpenBox3(true);
											}}
											value={date}
											showNeighboringMonth={false}
											minDate={new Date()}
											className={clsx(classes.calendarPicker, 'text-center')}
											tileDisabled={handleTileDisableState}
											onActiveStartDateChange={dateData => {
												setDate(null);
												setSelectedEvent(null);
												setMonth(dateData.activeStartDate.getMonth() + 1);
												setYear(dateData.activeStartDate.getFullYear());
											}}
											tileContent={d => {
												let tooltip = '';
												if (
													groupSummary &&
													groupSummary.dates &&
													groupSummary.dates[d.date.getDate()]
												) {
													tooltip = groupSummary.dates[d.date.getDate()].displayName || '';
												}
												return (
													<Tooltip title={tooltip}>
														<div aria-label={moment(d.date).format('DD[.] MMMM YYYY')}>
															{d.date.getDate()}
														</div>
													</Tooltip>
												);
											}}
											tileClassName={d => {
												if (
													customClasses[
														`custom-${
															d.date.getMonth() + 1
														}-${d.date.getFullYear()}-${d.date.getDate()}`
													]
												) {
													return [
														customClasses[
															`custom-${
																d.date.getMonth() + 1
															}-${d.date.getFullYear()}-${d.date.getDate()}`
														]
													];
												}
												return [];
											}}
										/>
									) : (
										<Typography
											className={`font-bold whitespace-no-wrap text-center mt-12 ${classes.text}`}
										>
											{t('CHOOSE_TICKET_AMOUNT_BEFORE')}
										</Typography>
									))}
							</Grid>
						</div>
						<Grid container className="flex justify-end">
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="px-10 pt-20">
								<Button
									onClick={goToCheckOut}
									className={`w-full rounded-none ${classes.buyButton}`}
									variant="contained"
									endIcon={<ArrowForwardIcon />}
									color="primary"
									disabled={isAllowedToBuy()}
								>
									{t('BUY_TICKET_BUTTON')}
								</Button>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</div>
		</>
	);
}

export default ListEventGroupDateSelector;

const Box2 = ({
	showingProducts,
	products,
	productsWithAmount,
	groupByRefs,
	classes,
	productGroups,
	setProductsWithAmount,
	disclaimers,
	totalAmount,
	eventGroup,
	date
}) => {
	const [highlightedDisclaimer, setHighlightedDisclaimer] = useState(null);
	const [selectedGroupBy, setSelectedGroupBy] = useState({});
	const flags = useFlags();

	const toolTipTitleAdd = (product, variant) => {
		if (variant) {
			if (
				flags.maxAmountOfProductPerProductGroupInCart &&
				selectedGroupBy[product.id] &&
				selectedGroupBy[product.id].ticketTypeUid &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid] &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets &&
				productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount &&
				productsWithAmount[selectedGroupBy[product.id].id] >=
					productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount
			) {
				return `Du kan maksimalt købe ${
					productGroups[selectedGroupBy[product.id].ticketTypeUid].maxTickets.amount
				} af dette produkt`;
			}
		} else if (
			flags.maxAmountOfProductPerProductGroupInCart &&
			product.ticketTypeUid &&
			productGroups[product.ticketTypeUid] &&
			productGroups[product.ticketTypeUid].maxTickets &&
			productGroups[product.ticketTypeUid].maxTickets.amount &&
			productsWithAmount[product.id] >= productGroups[product.ticketTypeUid].maxTickets.amount
		) {
			return `Du kan maksimalt købe ${productGroups[product.ticketTypeUid].maxTickets.amount} af dette produkt`;
		}
		return '';
	};

	const toolTipTitleRemove = product => {
		if (flags.minimumAmountOfProduct && product.minimumAmount) {
			return `Du skal minimum købe ${product.minimumAmount} af dette produkt`;
		}
		return '';
	};

	const updateProductAmount = (id, amount) => {
		setProductsWithAmount(oldState => ({
			...oldState,
			[id]: amount
		}));
	};

	const location = eventGroup && eventGroup.locations ? eventGroup.locations[0] : null;

	return (
		<ProductTotalSection
			classes={classes}
			totalAmount={totalAmount}
			disclaimers={disclaimers}
			highlightedDisclaimer={highlightedDisclaimer}
		>
			{showingProducts.map(product => {
				if (product.type === 'productGroup') {
					return (
						<ProductGroupRow
							key={product.id}
							product={product}
							productGroups={productGroups}
							productsWithAmount={productsWithAmount}
							setProductsWithAmount={setProductsWithAmount}
							groupByRefs={groupByRefs}
							setHighlightedDisclaimer={setHighlightedDisclaimer}
							updateProductAmount={updateProductAmount}
							selectedGroupBy={selectedGroupBy}
							setSelectedGroupBy={setSelectedGroupBy}
							addButtonToolTip={toolTipTitleAdd(product, true)}
							removeButtonToolTip={toolTipTitleRemove(product)}
							date={date}
						/>
					);
				}
				return (
					<ProductRow
						key={product.id}
						productGroups={productGroups}
						product={product}
						classes={classes}
						amount={productsWithAmount[product.id]}
						setHighlightedDisclaimer={setHighlightedDisclaimer}
						priceForProduct={findPricesForProduct(
							product,
							location,
							date,
							flags.displayPeriodePrices,
							productsWithAmount[product.id]
						)}
						addButtonToolTip={toolTipTitleAdd(product, false)}
						removeButtonToolTip={toolTipTitleRemove(product)}
						updateProductAmount={updateProductAmount}
						showDisclaimer
					/>
				);
			})}
		</ProductTotalSection>
	);
};
