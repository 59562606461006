export default {
	"DRAG_OR_CLICK_HERE": "Dra filer eller klicka här",
	"PROCESSING_ORDER": "Vi behandlar din beställning, vänta...",
	"PER_UNIT": "Pris per enhet",
	"REFUNDED": "Återbetalas",
	"CART_TERMS_OF_CONDITIONS_SUBSCRIPTION": "prenumerationsvillkoren",
	"CART_AUTO_RENEWAL_ACCEPT": "Jag skulle vilja förnya medlemskapen automatiskt innan varje period börjar",
	"ACCOUNT_SUBSCRIPTIONS_CHANGE_CARD": "Byt kreditkort",
	"ACCOUNT_ACTIVATING": "Ditt konto aktiveras, vänligen vänta..",
	"ERROR_UNKNOWN": "Ett okänt fel uppstod. Försök igen senare",
	"TYPE_EMAIL_UNDERNEATH": "Skriv din e-postadress för att börja skapa ditt medlemskap.",
	"I_WILL_RENEW_SUBSCRIPTION": "Jag vill förnya prenumerationen i början av varje period",
	"NO_USER_FOUND_MESSAGE": "Du verkar inte ha något konto. Därför måste du skapa ditt konto först.",
	"THE_GIFT_CARD": "Presentkortet",
	"CHOOSE_TICKET_AMOUNT_BEFORE": "Välj antal biljetter",
	"SEARCH_QUESTIONS": "Sök frågor...",
	"REMOVE_PRODUCT_FROM_CART": "Ta bort från vagnen",
	"ORDER_NOTE_LABEL": "Anteckningar om beställningen",
	"RENEW": "Förnya",
	"END": "Slutet",
	"ORDER_FAILED_TITLE": "Beställningen misslyckades",
	"THE_BALANCE_IS": "är balansen",
	"MAIL": "E-postadress",
	"CHOOSE_TICKETS": "Välj dina biljetter",
	"CHOOSE_TICKETS_BEFORE_BUY": "Välj dina biljetter först",
	"PRODUCTS_HAS_DIFFERENT_TIMESLOTS": "Utvalda biljetter har olika tidsluckor",
	"PHONE_INVALID_REMEMBER_COUNTRY_CODE": "Telefonnumret är ogiltigt. Om telefonnumret inte är från Danmark, kom ihåg att lägga till landskoden (+xx) före numret.",
	"EMAIL_ALREADY_EXISTS": "E-post finns redan. Logga in istället.",
	"AUTH_MISSING_EMAIL": "E-post saknas",
	"ACCOUNT_ADD_TO_APPLE_WALLET": "Lägg till i Apple Wallet",
	"CHOOSE_DATE_BEFORE": "Välj datum först",
	"MORNING": "Morgon",
	"MAX_ORDER": "Du kan beställa max",
	"AWAITING_RENEWAL": "Väntar på förnyelse",
	"OK_BUTTON": "Ja",
	"BEFORE_MIDDAY": "Före lunchtid",
	"MEMBERSHIP_RENEWAL_FROM": "Ditt medlemskap kommer att förnyas",
	"TOTAL": "Total",
	"ERROR_PRODUCT": "Produkten på",
	"HOW_MANY": "Hur många kommer du att besöka?",
	"AUTH_EMAIL_ALREADY_IN_USE": "E-postmeddelandet används redan",
	"CHOOSE_TICKETS_BEFORE": "Välj antal biljetter innan",
	"IF_MAIL_EXIST_YOU_WILL_RECEIVE_EMAIL": "Om din e-postadress finns i vårt system kommer du inom kort att få ett e-postmeddelande som låter dig återställa ditt lösenord",
	"ACCOUNT_NOT_FOUND": "Ditt konto hittades inte, försök igen senare",
	"IF_YOU_CHOOSE_TO_REMOVE_THESE_PRODUCTS": "If you continue without selecting, the extras will not be added to your order.",
	"THANKS_FOR_ORDER": "Tack för din beställning",
	"TO_BE_ABLE_TO_PURCHASE_THESE_PRODUCTS": "För att kunna lägga till extra till din beställning behöver du välja biljetter med samma tidslucka.",
	"YOUR_MEMBERSHIPS": "Dina medlemskap",
	"CART_SAME_NAMES_AS_BILLING": "Samma för- och efternamn som faktureringsinformation",
	"FAQ_HEADER": "Vanliga frågor",
	"ACCOUNT_NEEDED_EMAIL_CONFIRMATION": "Bekräftelse behövs",
	"TAX": "Beskatta",
	"ALL_LOCATIONS_SELECTED": "Alla platser har valts",
	"MAX_90_HEADER": "Max 90 produkter",
	"ENTER_NEW_PASSWORD": "Ange ett nytt lösenord för användaren med följande e-postadress:",
	"ADD_TO_GOOGLE_WALLET_LOGO": "/assets/images/wallet/en-GB/en_add_to_google_wallet_add-wallet-badge.png",
	"SORRY": "Förlåt",
	"LAST_NAME_MIN_2_CHAR": "Efternamn måste innehålla minst 2 tecken",
	"CREATE_MEMBERSHIP": "Skapa medlemskap",
	"ACCOUNT_EXISTS": "Har du redan ett konto?",
	"ADD_TO_APPLE_WALLET_LOGO": "/assets/images/wallet/da-DK/US-UK_Add_to_Apple_Wallet.png",
	"CITY": "Stad",
	"REMOVE_FROM_CART": "Är du säker på att du vill ta bort den här produkten från din kundvagn?",
	"YOU_CAN_LOGIN": "Du kan nu logga in",
	"MEMBERSHIP": "Medlemskap",
	"CONTINUE_CREATING_SUBCRIPTION": "Fortsätt medlemskapsregistreringen här",
	"FULL_NAME": "Fullständiga namn",
	"CANCEL_BUTTON": "Nej",
	"PRODUCT": "Produkt",
	"CART_PAY_BUTTON": "BETALA",
	"READ_MORE": "Läs mer",
	"LOGGED_IN_WITH_CORRECT_USER": "inloggad med rätt användare",
	"ERROR_EVENT": "Tiden på",
	"NEED_TO_BUY_MINIMUM_OF": "Du måste köpa minst",
	"CHOOSE_DATE": "Välj datum",
	"BUY_TICKET_BUTTON": "KÖP BILJETT",
	"TO_CREATE_MEMBERSHIP_YOU_NEED_A_USER": "För att skapa ett medlemskap måste du ha skapat ett konto hos oss. Börja därför med att ange din e-postadress så att vi kan kontrollera om du redan är registrerad hos oss.",
	"PRICE_EA": "PRIS PR. ENHET",
	"TICKETS_LEFT": "biljetter kvar",
	"NEWSLETTER_SIGNUP": "Registrera dig för nyhetsbrev",
	"ACCOUNT_ACTIVATED": "ditt konto har aktiverats",
	"CART_CARDHOLDER": "Korthållare",
	"ALL_CATEGORIES_SELECTED": "Alla kategorier har valts",
	"DEACTIVATE_RENEWAL_EXPLAINER": "Genom att avaktivera förnyelse kommer prenumerationen inte att förnyas. Du måste betala manuellt.",
	"PAYMENT": "Betalning",
	"VALUE": "Värde",
	"NO_EVENTS": "Det finns inga matchningar till dina sökkriterier",
	"YOU_HAVE_ALREADY_ADDED_THE_MAX": "Du har redan lagt till maxbeloppet",
	"PASSWORD_LENGTH": "Lösenordet måste innehålla minst 6 tecken",
	"PHONE": "Telefon",
	"SELECT": "Välj",
	"UPDATE_PROFILE": "Uppdatera profil",
	"ADDRESS": "Adress",
	"CONTINUE": "Fortsätta",
	"CORRECT_PASSWORD": "Rätt lösenord, du loggas nu in.",
	"TO": "Till",
	"ORDER_FAILED_DESCRIPTION": "Beställningen slutfördes inte och pengarna har inte dragits",
	"OF_THIS_PRODUCT": "av denna produkt",
	"START": "Start",
	"RESEND_CONFIRMATION_EMAIL": "Skicka om e-postbekräftelse",
	"APPLY": "Tillämpa",
	"GIFT_CARD": "Present kort",
	"PHONE_NR_MIN_2_CHAR_INT": "Telefonnumret måste vara mellan 6-20 tecken",
	"GUEST": "Gäst",
	"PAY": "Betala",
	"VISIT_TIME": "När vill du komma?",
	"CONTINUE_WITHOUT_UPSELL": "Fortsätt utan extrafunktioner",
	"NO_IMAGE": "Ingen bild har valts",
	"PASSWORD": "Lösenord",
	"NOT_FOUND": "Hittades inte",
	"PHONE_NUMERIC": "Telefonnumret måste vara numeriskt",
	"WHICH_LOCATION": "Vilken plats vill du besöka?",
	"SUBSCRIPTION_VALID_FROM": "Abonnemanget gäller fr.o.m",
	"PER_PERIOD": "Per period",
	"MAIL_SENT": "E-post har skickats",
	"APPLY_GIFT_CARD_CODE": "Ansök presentkort",
	"EMAIL_IS_CONFIRMED": "Din epostadress har bekräftats!",
	"DEACTIVATE_RENEWAL": "Inaktivera automatisk förnyelse",
	"I_HAVE_READ_AND_ACCEPT": "Jag har läst och accepterar",
	"AFTERNOON": "Eftermiddag",
	"LOGOUT": "Logga ut",
	"EXPIRES_ON": "Går ut den",
	"CHOOSE_QUANTITY": "Välj biljetter",
	"ADD_CREDITCARD": "Lägg till kreditkort",
	"CART_ERROR_FIELDS": "Fel",
	"LOGIN_AGAIN": "Logga in igen",
	"SEND_CONFIRMATION_MAIL": "Skicka bekräftelsemail",
	"AUTH_INVALID_EMAIL": "E-postmeddelandet är ogiltigt",
	"ERROR_RESETTING_PASSWORD": "Det uppstod ett fel när ditt lösenord skulle återställas, försök igen.",
	"WE_ALREADY_SENT_EMAIL": "Vi har redan skickat ett mail till dig.",
	"CITY_MIN_2_CHAR": "Stadsnamn måste innehålla minst 1 tecken",
	"DATO": "Datum",
	"AWAITING_PAYMENT": "Väntar på betalning",
	"AUTIMATIC_RENEWAL": "Ditt medlemskap är nu inställt på automatisk förnyelse",
	"GO_BACK_AND_CHANGE": "Gå tillbaka och ta bort biljetter med olika tidsluckor.",
	"LOADING_EVENT": "Laddar händelse...",
	"VISIT_DATE": "När vill du besöka?",
	"IS_CREATING": "håller på att skapa",
	"TRY_AGAIN": "Försök igen",
	"THE_SUBCRIPTION": "prenumerationen",
	"POSTAL_CODE_NUMERIC": "Postnummer måste vara numeriskt",
	"PRICE": "Pris",
	"CHANGE_IMAGE": "Byt bild",
	"SEND_CONFIRMATION_EMAIL": "Skicka bekräftelsemail",
	"CART_EMAIL_ALREADY_CREATED_NOT_CONFIRMED": "Du är redan registrerad, men din e-postadress är inte bekräftad ännu",
	"CONTACT_DETAILS": "Ange kontaktuppgifter",
	"MORE_DATES": "Se fler datum",
	"DOES_NOT_EXIST_OR_EXPIRED": "inte finns eller har löpt ut",
	"EXISTING_ACCOUNT": "Befintligt konto",
	"AUTH_USER_DISABLED": "Användaren är avstängd",
	"ACCOUNT_ADD_TO_GOOGLE_WALLET": "Lägg till i Google Wallet",
	"FIRST_NAME": "Förnamn",
	"ACCOUNT_INFO": "Kontoinformation",
	"AUTOMATIC_RENEWAL_EXPLAINER": "Genom att aktivera förnyelse kommer medlemskapet att förnyas automatiskt i början av nästa period",
	"CONFIRM": "Bekräfta",
	"AUTH_WRONG_PASSWORD": "Fel lösenord",
	"EMAIL_CONFIRMED": "E-post bekräftad",
	"ORDER_FAILED_DESCRIPTION_2": "Försök igen snart eller kontakta oss om du fortsätter att uppleva detta",
	"QUANTITY": "Kvantitet",
	"PRODUCTS_IN_SUBSCRIPTION": "Produkter i prenumerationen",
	"ACTIVATE_ACCOUNT": "Aktivera ditt konto",
	"SIGN_UP_BUTTON": "Bli Medlem",
	"EMAIL_MUST_CONFIRMED_EXPLAINER": "Innan du kan skapa ditt medlemskap måste vi bekräfta din e-postadress",
	"POSTAL_CODE_LENGTH_INT": "Postnummer måste innehålla 3-8 tecken.",
	"UNKOWN_EVENT": "Okänd händelse",
	"CONFIRM_EMAIL": "Bekräfta e-postadress",
	"INACTIVE": "Inaktiv",
	"TO_ACTIVE_ACCOUNT": "För att aktivera ditt konto måste du bekräfta att du äger e-postmeddelandet.",
	"CART_APPLY_CODE_ERROR": "Presentkortet eller rabattkoden du angav kunde inte tillämpas. Det kan bero på att det har gått ut eller för att du redan har lagt till en rabattkod i din varukorg.",
	"VALIDITY_PERIOD": "Giltighetsperiod",
	"AUTH_WEAK_PASSWORD": "Lösenordet är för svagt. Använd ett lösenord med mer än 8 tecken",
	"LOADING": "Läser in...",
	"NIGHT": "Natt",
	"YOUR_ORDERS": "Dina beställningar",
	"CUSTOMER": "Kund",
	"CART_BILLING_INFORMATION": "Faktureringsinformation",
	"TERMS_OF_CONDITIONS": "villkoren",
	"SELECT_DATE": "Välj datum du vill boka en aktivitet för",
	"REMOVE_PRODUCT_NOT_ALLOWED": "Det är inte tillåtet att ta bort denna produkt från varukorgen",
	"SEND_CODE": "Skicka kod",
	"COUNTRY": "Land *",
	"YES": "Ja",
	"CODE_IS_SENT_TO": "Koden har skickats till",
	"SOLD_OUT": "Utsåld",
	"MEMBERSHIP_TYPE_DOES_NOT_EXIST": "Den valda medlemskapstypen finns inte, försök med en annan typ.",
	"FIRST_NAMEFIRST_NAME_MIN_2_CHAR": "Förnamn måste innehålla minst 2 tecken",
	"CARD": "Kort",
	"CHECK_SPAM": "Om du inte har fått bekräftelsemailet inom några minuter, kontrollera din skräppostmapp.",
	"PHONE_LENGTH": "Telefonnumret måste vara minst 8-11 tecken",
	"YOU_CAN_MIN_BUY": "Du måste köpa åtminstone",
	"YOU_HAVE_ALREADY_REMOVED_THE_MIN": "Du har redan tagit bort minimibeloppet",
	"ERROR_EMAIL_ALREADY_IN_USE": "E-postadressen används redan. Försök med ett annat e-postmeddelande",
	"SIGN_UP_STATUS": "Registrerar dig..",
	"AUTH_USER_NOT_FOUND": "Användaren kunde inte hittas",
	"APPLY_DISCOUNT_CODE": "Använd kampanjkod",
	"CODE": "Koda",
	"LAST_NAME": "Efternamn",
	"YOUR_CART": "Din vagn",
	"CART_EMAIL_NOT_CREATED_BEFORE": "Du är inte skapad och kan fortsätta",
	"UPLOADING_PROFILE_PICTURE": "Laddar upp profilbild",
	"PHONE_NR_MIN_2_CHAR": "Telefonnumret måste vara mellan 8-11 tecken",
	"EMAIL_SENT": "Vi har precis skickat ett e-postmeddelande till dig som du måste bekräfta genom att klicka på knappen.",
	"FORGOT_PASSWORD": "GLÖMT DITT LÖSENORD",
	"CART_ADD_GIFTCARD_OR_DISCOUNTCODE": "Lägg till ytterligare ett presentkort eller rabattkod",
	"EVENT_TIME": "Eventtid",
	"EDIT_UPSELL_OPTIONS": "Redigera ytterligare köp",
	"CHANGING_MEMBERSHIP": "Vi ändrar ditt medlemskap, vänligen vänta...",
	"ENTER_PASSWORD": "Ange ett lösenord för ditt konto",
	"FIRST_NAME_MIN_2_CHAR": "Förnamn måste innehålla minst 2 tecken",
	"HI": "Hej",
	"I_AM_CONFIRMING": "Jag bekräftar",
	"MEMBERSHIP_NUMBER": "Prenumerationsnummer",
	"CONFIRMATION_MAIL_TO": "Ett bekräftelsemail kommer att skickas till",
	"CANCELLED": "Inställt",
	"TICKET_NUM": "Biljettnummer",
	"UPSELL_TITLE": "Extra till din beställning",
	"TICKET": "Biljett",
	"CHOOSE_CATEGORY": "Choose category",
	"FROM": "Från",
	"CHARGE_IS_SEVEN_DAYS_BEFORE": "* Observera att med automatisk förnyelse av medlemskap kommer debiteringen att ske 7 dagar före utgångsdatumet",
	"APPLY_DISCOUNT_OR_GIFT_CARD_CODE": "Använd kampanjkod eller presentkort",
	"GO_TO_PAYMENT": "Gå till kassan",
	"EMAIL_CONFIRMNIG": "Bekräftar e-post...",
	"CART_CARDHOLDER_FOR": "för",
	"YOUR_INFORMATIONS": "Din information",
	"FORGOT_PASSWORD_QUESTIONMARK": "Glömt ditt lösenord?",
	"ACCOUNT_EMAIL_NOT_CONFIRMED_YET": "Din e-post har inte bekräftats ännu, kontrollera din e-post och klicka på länken i mejlet.",
	"ACTIVATE": "Aktivera",
	"PHONE_NUMBER": "Telefonnummer",
	"SEARCH": "Sök",
	"CART_CONTINUE": "Fortsätta",
	"ON_THE_GIFT_CARD": "På presentkortet",
	"LOCATIONS": "Platser",
	"BUY": "köpa",
	"CART_CHECKING_EMAIL": "Kontrollerar e-post...",
	"SEND_CONTROL_CODE": "Skicka kontrollkod",
	"ISSUED_TO": "Utfärdat till",
	"ADDRESS_MIN_2_CHAR": "Adressen måste innehålla minst 2 tecken",
	"DEACTIVATE": "Avaktivera",
	"DOES_NOT_EXIST": "The page does not exist",
	"MY_PAGE": "Min sida",
	"BUY_TICKET_BUTTON_FREE": "Boka biljetter",
	"YOUR_EMAIL_ADDRESS": "Your email",
	"REMOVE": "Avlägsna",
	"EMAIL_BEING_CONFIRMED": "Din e-postadress håller på att bekräftas, vänligen vänta..",
	"CART_EMAIL_ALREADY_CREATED": "You are already registered, enter your password below",
	"LINK_IS_DEAD": "Länken du har följt verkar vara ogiltig..",
	"CONFIRM_EMAIL_ERROR": "Mejlen stämmer inte överens",
	"SELECT_FROM_LIST": "Välj från listan",
	"CART_ADD_GIFTCARD": "Lägg till ytterligare ett presentkort",
	"NO_TIMES_AVAILABLE": "Inga tider tillgängliga",
	"BUY_ACTIVITIES": "Köp extra aktiviteter",
	"AUTH_ERROR_TITLE": "Fel",
	"LOGIN": "Logga in",
	"CATEGORIES": "Kategorier",
	"CONFIRMATION": "Bekräftelse",
	"HAVE_NO_USER": "Har du ingen användare?",
	"RESET_PASSWORD": "Återställ lösenord",
	"ACCOUNT_YOUR_ACCOUNT": "Ditt konto",
	"CREATE_USER": "Skapa användare",
	"WELCOME": "Välkommen!",
	"ADD_ANOTHER_GIFT_CARD": "Lägg till ytterligare ett presentkort eller kampanjkod",
	"ACCOUNT_TAB_SUBSCRIPTIONS": "Medlemskap",
	"NOT_VALID_EMAIL": "Ej giltig e-post",
	"CART_ERROR_MISSING_FIELDS": "Saknar färdigställande",
	"THANKS_FOR_DETAILS": "Tack för dina uppgifter",
	"ARE_YOU_NOT": "Är du inte",
	"AMOUNT_PEOPLE": "Hur många människor är ni?",
	"CHECK_GIFT_CARD": "Kontrollera saldot på ditt presentkort",
	"SELECTED_TIME": "Vald tid:",
	"YOUR_TICKETS": "Dina biljetter",
	"ALREADY_EXISTS": "E-postmeddelandet finns redan i vårt system, men har inte aktiverats för onlineanvändning",
	"EMAIL": "E-post",
	"EMAIL_DOES_NOT_EXIST": "existerar inte",
	"CREATION_OF_MEMBERSHIP": "Skapande av medlemskap",
	"IN_THE_FIELD": "i fältet för att bekräfta",
	"CARD_NUM": "Kortnummer",
	"HOW_MANY_ACTIVITIES": "Välj antalet medlemskap du vill boka en aktivitet för",
	"MAX_90": "Du kan inte ha fler än 90 produkter i din varukorg.",
	"ERROR": "Ett fel uppstod",
	"TOTAL_PRICE": "Totalbelopp",
	"POSTAL_CODE": "Postnummer",
	"RELEASE_FILES_HERE": "Släpp filer här",
	"ACTIVE": "Aktiva",
	"SUBTOTAL": "Delsumma",
	"EMAIL_ERROR": "Detta är inte ett giltigt e-postmeddelande",
	"COMPLETED": "Avslutad",
	"YOUR_MEMBERSHIP_CARDS": "Dina medlemskort",
	"EXPIRES_SOON": "Går snart ut",
	"RENEWAL_WHEN_PAYMENT_CHARGED": "In automatic renewal, the charge will occur 7 days before the expiration date. In manual renewal, the charge will occur on the day you renew.",
	"AUTIMATIC_RENEWAL_EXPLAINER": "Genom att aktivera förnyelse kommer medlemskapet att förnyas automatiskt i början av nästa period",
	"APP_TITLE": "listEventGroupDateSelector",
	"ACCOUNT_TAB_INFORMATION": "Information",
	"PASSWORD_CHANGED": "lösenordet ändrat",
	"CHANGING_PASSWORD": "Ställer in ditt lösenord - vänligen vänta..",
	"GO_BACK": "Gå tillbaka till din varukorg",
	"BUY_TICKET": "Köp biljett",
	"ORDER_NOTE_PLACEHOLDER": "Skriv dina anteckningar här",
	"WRITE": "skriva",
	"CITY_LENGTH": "Stad måste bestå av minst 2 tecken",
	"PASSWORD_CHANGED_SUCCESSFULLY": "Ditt lösenord har ändrats",
	"CHOOSE_LOCATION": "Välj plats",
	"THIS_DAY": "biljetter denna dag.",
	"AUTH_OPERATION_NOT_ALLOWED": "Okänt fel",
	"LOGIN_INSTEAD": "Logga in istället?",
	"ACTIVATE_RENEWAL": "Aktivera automatisk förnyelse",
	"CONFIRM_PASSWORD": "Bekräfta lösenord",
	"SUBSCRIPTION_GIFT_CARD": "Prenumeration presentkort",
	"ACTIVE_ACCOUNT_BY": "Detta kan göras genom att följa länken nedan, varefter du får ett mejl.",
	"BUY_TICKET_FREE": "Boka biljett",
	"TRY_NEW_CARD": "Prova ett nytt kort",
	"AUTH_PASSWORDS_DO_NOT_MATCH": "Lösenorden stämmer inte överens",
	"YOU_MUST_CONFIRM_EMAIL": "Du måste bekräfta din e-postadress för att få ett konto.",
	"AMOUNT": "Belopp",
	"CREATE_ACCOUNT": "Skapa konto",
	"CONFIRMATION_MAIL_TO_YOU": "Ett bekräftelsemail kommer att skickas till dig",
	"CHOOSE_AMOUNT_PEOPLE": "Choose amount",
	"MISSING_ACCOUNT": "Saknat konto",
	"YOU_CAN_CLOSE_THIS_WINDOW": "Du kan nu stänga detta fönster",
	"SUBSCRIPTION_VALID_FROM_FIRST_TIME": "Abonnemanget är giltigt från första gången du använder det",
	"FINISH": "Avsluta",
	"ALL_DATES_SELECTED": "Alla datum valda",
	"CONFIRM_MAIL_TO_SHOW_ORDERS": "Innan vi kan visa din tidigare {type} måste du bekräfta din e-post.",
	"PLEASE_WAIT": "Vänta",
	"POSTAL_CODE_LENGTH": "Postnummer måste innehålla 3-4 tecken.",
	"EMAIL_MUST_BE_CONFIRMED": "Din e-post måste bekräftas",
	"YOU_NEED_TO_SELECT_TIME_OR_PRODUCTS": "Du måste välja en tid eller antal biljetter.",
	"SELECT_ACTIVITIES": "Välj aktiviteter",
	"CHOOSE_TIME": "Välj tid",
	"EVENING": "Kväll",
	"MEMBERSHIP_IS_CREATED_ON_THIS_CUSTOMER": "Medlemskapet skapas på denna kund",
	"FAQ_SUBTITLE": "Vi är här för att hjälpa",
	"ORDER": "Beställa",
	"CHECK_CODE": "Kolla presentkort",
	"YOU_CAN_MAX_BUY": "You can buy max",
	"CANCEL": "Annullera",
	"NO_QUESTIONS": "Inga frågor har hittats",
	"ENTER_DETAILS": "Skriv in dina uppgifter för att skapa ett konto.",
	"PHONE_NR": "Telefonnummer",
}