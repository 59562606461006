import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import {
	orderLinesListener,
	orderListener,
	requestIds,
	selectCustomerId,
	selectError,
	selectOrderId,
	setError
} from '../store/shared/cartSlice';
import ConfirmationDialog from '@ameroservices-platform/shared/ui-components/ConfirmationDialog';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';
import { useTranslation } from 'react-i18next';

export default function FrontendInitialization(props) {
	const dispatch = useDispatch();
	const location = useLocation();
	const orderId = useSelector(selectOrderId);
	const customerId = useSelector(selectCustomerId);
	const error = useSelector(selectError);
	const mainTheme = useSelector(selectMainTheme);
	const { t } = useTranslation();

	useEffect(() => {
		firebaseService.onAuthStateChanged(async auth => {
			if (auth && !location.pathname.startsWith('/flexpos')) {
				const idTokenResult = await auth.getIdTokenResult();
				if (idTokenResult.claims.organisationId) {
					dispatch(requestIds());
				}
			}
		});
	});

	useEffect(() => {
		if (customerId && orderId) {
			const unsubOrder = dispatch(orderListener(customerId, orderId));
			const unsubOrderLines = dispatch(orderLinesListener(customerId, orderId));
			return () => {
				unsubOrder();
				unsubOrderLines();
			};
		}
		return () => {};
	}, [dispatch, customerId, orderId]);

	return (
		<>
			<ConfirmationDialog
				mainTheme={mainTheme}
				simple
				fullWidth
				content={t(error)}
				keepMounted
				open={!!error}
				onClose={() => {
					dispatch(setError(null));
				}}
				cancelButton={false}
				title={'Fejl'}
				okButton="Luk"
			/>
			{props.children}
		</>
	);

	// this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{props.children}</>;
}
