import Typography from '@mui/material/Typography';
import { Button, TextField, Tooltip } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getLocaleFromData } from '../../../../../../utilities';
import { findPricesForProduct } from '@ameroservices-platform/shared/utility/index';

const ProductGroupRow = ({
	product,
	addButtonToolTip,
	removeButtonToolTip,
	productsWithAmount,
	setHighlightedDisclaimer,
	updateProductAmount,
	productGroups,
	selectedGroupBy,
	groupByRefs,
	setSelectedGroupBy,
	setProductsWithAmount,
	date = null
}) => {
	const flags = useFlags();
	const amount =
		selectedGroupBy && selectedGroupBy[product.id] && selectedGroupBy && selectedGroupBy[product.id].id
			? productsWithAmount[selectedGroupBy[product.id].id]
			: 0;

	const handleMouseOver = _product => {
		if (_product.disclaimerIndex !== null && _product.disclaimerIndex !== undefined) {
			setHighlightedDisclaimer(_product.disclaimerIndex);
		}
	};
	const handleMouseLeave = _product => {
		if (_product.disclaimerIndex !== null && _product.disclaimerIndex !== undefined) {
			setHighlightedDisclaimer(null);
		}
	};

	const handleClickOnRemoveProductAmount = _product => {
		const productAmount = amount || 0;
		let newAmount = productAmount - 1;

		const selectedProduct = selectedGroupBy[_product.id];
		if (productAmount === 0) {
			return;
		}
		if (
			flags.minimumAmountOfProduct &&
			selectedProduct.minimumAmount &&
			productAmount <= selectedProduct.minimumAmount
		) {
			newAmount = 0;
		}
		updateProductAmount(selectedProduct.id, newAmount);
	};

	const handleClickOnAddProductAmount = _product => {
		const productAmount = amount || 0;
		let newAmount = productAmount + 1;
		const selectedProduct = selectedGroupBy[_product.id];

		if (flags.minimumAmountOfProduct && selectedProduct.minimumAmount) {
			if (productAmount < selectedProduct.minimumAmount) {
				newAmount = selectedProduct.minimumAmount;
			}
		}
		if (flags.maxAmountOfProductPerProductGroupInCart) {
			const { maxTickets } = productGroups[selectedProduct.ticketTypeUid];
			if (maxTickets && maxTickets.amount && maxTickets.amount <= productsWithAmount[selectedProduct.id]) {
				newAmount = maxTickets.amount;
			}
		}
		updateProductAmount(selectedProduct.id, newAmount);
	};

	const inputRefFunc = r => {
		if (r) {
			groupByRefs.current = {
				...groupByRefs.current,
				[product.id]: r
			};
		}
	};

	const handleChangeSelectedGroupBy = async (event, newValue, _product) => {
		const selectedProduct = selectedGroupBy[_product.id];
		if (selectedProduct && selectedProduct.id) {
			setProductsWithAmount(oldState => ({
				...oldState,
				[selectedProduct.id]: 0
			}));
		}
		setSelectedGroupBy(oldState => ({
			...oldState,
			[_product.id]: newValue
		}));
	};

	const productPrice =
		selectedGroupBy[product.id] && product.items[selectedGroupBy[product.id].id]
			? `${
					findPricesForProduct(
						product.items[selectedGroupBy[product.id].id],
						null,
						date,
						flags.displayPeriodePrices,
						selectedGroupBy[product.id] && productsWithAmount[selectedGroupBy[product.id].id]
							? productsWithAmount[selectedGroupBy[product.id].id]
							: 0
					) / 100
			  },-`
			: product.samePrice
			? `${product.lowestPrice / 100},-`
			: `${product.lowestPrice / 100} - ${product.highestPrice / 100},-`;
	const { t } = useTranslation();
	return (
		<div className="w-full">
			<div className="flex justify-between items-center w-full">
				<div className="flex justify-between w-full items-center h-56">
					<Typography
						className="font-bold whitespace-normal cursor-default"
						onMouseOver={() => handleMouseOver(product)}
						onMouseLeave={() => handleMouseLeave(product)}
					>
						{/* className="font-bold text-14 md:text-base"> */}
						{!flags.multiLanguageFunctions && <>{product.name} &nbsp;</>}
						{flags.multiLanguageFunctions && <>{getLocaleFromData(product, 'name')} &nbsp;</>}
						{product.disclaimer ? '*'.repeat(product.disclaimerIndex + 1) : ''}
					</Typography>
					<Typography className="px-8 font-bold flex items-center">{productPrice}</Typography>
				</div>
				<div className="flex ButtonDiv h-56 items-center">
					{/* eslint-disable-next-line */}
					<div
						className="flex items-center w-full"
						onClick={() => {
							if (
								(!selectedGroupBy[product.id] || !selectedGroupBy[product.id].id) &&
								groupByRefs.current[product.id]
							) {
								groupByRefs.current[product.id].focus();
							}
						}}
					>
						<Tooltip title={removeButtonToolTip}>
							<Button
								variant="outlined"
								disabled={!selectedGroupBy[product.id] || !selectedGroupBy[product.id].id}
								onClick={() => handleClickOnRemoveProductAmount(product)}
								className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
							>
								<Remove />
							</Button>
						</Tooltip>
						<div className="flex items-center text-14 font-bold px-10">
							{selectedGroupBy[product.id] && productsWithAmount[selectedGroupBy[product.id].id]
								? productsWithAmount[selectedGroupBy[product.id].id]
								: '0'}
						</div>

						<Tooltip title={addButtonToolTip}>
							<Button
								variant="outlined"
								disabled={!selectedGroupBy[product.id] || !selectedGroupBy[product.id].id}
								onClick={() => handleClickOnAddProductAmount(product)}
								className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
							>
								<Add />
							</Button>
						</Tooltip>
					</div>
				</div>
			</div>
			<div className="mr-92 pr-8 -mt-8 mb-10">
				<Autocomplete
					className="w-full"
					fullWidth
					id="groupBySelected"
					name="groupBySelected"
					options={product.options}
					value={selectedGroupBy[product.id] || null}
					onChange={(event, newValue) => handleChangeSelectedGroupBy(event, newValue, product)}
					getOptionLabel={option => {
						return option && option.name ? option.name : '-';
					}}
					size="small"
					renderInput={params => (
						<TextField
							{...params}
							label={t('SELECT_FROM_LIST')}
							variant="outlined"
							inputRef={inputRefFunc}
						/>
					)}
					openOnFocus
					required
				/>
			</div>
		</div>
	);
};

export default ProductGroupRow;
