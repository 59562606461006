export default {
	"DRAG_OR_CLICK_HERE": "Træk og slip en fil eller klik for at vælge en fil",
	"PROCESSING_ORDER": "Vi behandler din ordre, vent venligst...",
	"PER_UNIT": "Stk. pris",
	"REFUNDED": "Refunderet",
	"CART_TERMS_OF_CONDITIONS_SUBSCRIPTION": "abonnementsbetingelserne",
	"CART_AUTO_RENEWAL_ACCEPT": "Jeg vil gerne forny medlemskaberne automatisk inden starten af hver periode",
	"ACCOUNT_SUBSCRIPTIONS_CHANGE_CARD": "Skift betalingskort",
	"ACCOUNT_ACTIVATING": "Din konto er ved at blive aktiveret, vent venligst..",
	"ERROR_UNKNOWN": "Der opstod en ukendt fejl. Prøv igen senere",
	"TYPE_EMAIL_UNDERNEATH": "Indtast din e-mail for at begynde at oprette dit medlemskab.",
	"I_WILL_RENEW_SUBSCRIPTION": "Jeg vil gerne forny medlemskabet automatisk inden starten af hver periode",
	"NO_USER_FOUND_MESSAGE": "Det ser ikke ud til at du har en bruger hos os. Du bliver derfor først nødt til at oprette din bruger.",
	"THE_GIFT_CARD": "Gavekortet",
	"CHOOSE_TICKET_AMOUNT_BEFORE": "Vælg antal billetter først",
	"SEARCH_QUESTIONS": "Søg i spørgsmål...",
	"REMOVE_PRODUCT_FROM_CART": "Fjern fra kurv",
	"ORDER_NOTE_LABEL": "Bemærkninger til ordren",
	"RENEW": "Forny",
	"END": "Slut",
	"ORDER_FAILED_TITLE": "Ordren mislykkedes",
	"THE_BALANCE_IS": "er saldoen på",
	"MAIL": "Email adresse",
	"CHOOSE_TICKETS": "Vælg billetter",
	"CHOOSE_TICKETS_BEFORE_BUY": "Vælg dine billetter først",
	"PRODUCTS_HAS_DIFFERENT_TIMESLOTS": "Valgte billetter har forskellige tidspunkter",
	"PHONE_INVALID_REMEMBER_COUNTRY_CODE": "Telefonnummeret er ugyldigt. Hvis telefonnummeret ikke er fra Danmark, skal du huske at tilføje landekoden (+xx) før nummeret.",
	"EMAIL_ALREADY_EXISTS": "Den indtastede email, har allerede en bruger hos os. Du bedes derfor logge ind.",
	"AUTH_MISSING_EMAIL": "Manglende e-mail",
	"ACCOUNT_ADD_TO_APPLE_WALLET": "Tilføj til Apple Wallet",
	"CHOOSE_DATE_BEFORE": "Vælg dato først",
	"MORNING": "Morgen",
	"MAX_ORDER": "Du kan maksimalt bestille",
	"AWAITING_RENEWAL": "Afventer fornyelse",
	"OK_BUTTON": "Ja",
	"BEFORE_MIDDAY": "Før middag",
	"MEMBERSHIP_RENEWAL_FROM": "Medlemskabet vil blive fornyet med endnu en periode fra",
	"TOTAL": "I alt",
	"ERROR_PRODUCT": "Produktet på",
	"HOW_MANY": "Hvor mange kommer I?",
	"AUTH_EMAIL_ALREADY_IN_USE": "E-mailen er allerede i brug",
	"CHOOSE_TICKETS_BEFORE": "Vælg antal billetter først",
	"IF_MAIL_EXIST_YOU_WILL_RECEIVE_EMAIL": "Hvis din email er oprettet hos os, vil du om kort tid modtage en mail, hvor du kan nulstille din adgangskode",
	"ACCOUNT_NOT_FOUND": "Din konto blev ikke fundet, prøv venligst igen senere",
	"IF_YOU_CHOOSE_TO_REMOVE_THESE_PRODUCTS": "Hvis du fortsætter uden at vælge tilkøb, vil dine tilkøb ikke blive tilføjet din ordre.",
	"THANKS_FOR_ORDER": "Tak for din ordre",
	"TO_BE_ABLE_TO_PURCHASE_THESE_PRODUCTS": "For at kunne tilføje tilkøb til din ordre, skal du vælge billetter med samme tidspunkt.",
	"YOUR_MEMBERSHIPS": "Dine medlemskaber",
	"CART_SAME_NAMES_AS_BILLING": "Samme for- og efternavn som faktureringsoplysninger",
	"FAQ_HEADER": "Ofte stillede spørgsmål",
	"ACCOUNT_NEEDED_EMAIL_CONFIRMATION": "Manglende bekræftelse",
	"TAX": "MOMS",
	"ALL_LOCATIONS_SELECTED": "Alle steder er valgt",
	"MAX_90_HEADER": "Maksimalt 90 produkter",
	"ENTER_NEW_PASSWORD": "Indtast en ny adgangskode til brugeren med følgende e-mail:",
	"ADD_TO_GOOGLE_WALLET_LOGO": "/assets/images/wallet/da-DK/dk_add_to_google_wallet_add-wallet-badge.png",
	"SORRY": "Beklager",
	"LAST_NAME_MIN_2_CHAR": "Efternavn skal indeholde mindst 2 tegn",
	"CREATE_MEMBERSHIP": "Opret medlemskab",
	"ACCOUNT_EXISTS": "Har du allerede en konto?",
	"ADD_TO_APPLE_WALLET_LOGO": "/assets/images/wallet/da-DK/add-to-apple-wallet-logo.png",
	"CITY": "By",
	"REMOVE_FROM_CART": "Er du sikker på, at du vil fjerne dette produkt fra din indkøbskurv?",
	"YOU_CAN_LOGIN": "Du kan nu logge ind",
	"MEMBERSHIP": "Medlemskab",
	"CONTINUE_CREATING_SUBCRIPTION": "Fortsæt oprettelse af medlemskab her",
	"FULL_NAME": "Fulde navn",
	"CANCEL_BUTTON": "Nej",
	"PRODUCT": "Produkt",
	"CART_PAY_BUTTON": "BETAL",
	"READ_MORE": "Læs mere",
	"LOGGED_IN_WITH_CORRECT_USER": "logget ind med den rigtige bruger",
	"ERROR_EVENT": "Tidspunkt på",
	"NEED_TO_BUY_MINIMUM_OF": "Du skal minimum købe",
	"CHOOSE_DATE": "Vælg dato",
	"BUY_TICKET_BUTTON": "KØB BILLET",
	"TO_CREATE_MEMBERSHIP_YOU_NEED_A_USER": "For at oprette et medlemskab, skal du have oprettet en konto hos os. Start derfor med at indtaste din email adresse, så vi kan tjekke om du er oprettet hos os i forvejen.",
	"PRICE_EA": "PRIS PR. STK",
	"TICKETS_LEFT": "billetter tilbage",
	"NEWSLETTER_SIGNUP": "Tilmeld nyhedsbrev",
	"ACCOUNT_ACTIVATED": "Din konto er blevet aktiveret",
	"CART_CARDHOLDER": "Kortholder",
	"ALL_CATEGORIES_SELECTED": "Alle kategorier er valgt",
	"DEACTIVATE_RENEWAL_EXPLAINER": "Ved at deaktivere automatisk fornyelse, vil medlemskabet ikke blive fornyet automatisk. Du skal altså selv betale det inden udløb.",
	"PAYMENT": "Betaling",
	"VALUE": "Værdi",
	"NO_EVENTS": "Der er ingen begivenheder der matcher dine søgekriterier",
	"YOU_HAVE_ALREADY_ADDED_THE_MAX": "Du har allerede tilføjet det maksimale antal",
	"PASSWORD_LENGTH": "Adgangskoden skal indeholde mindst 6 tegn",
	"PHONE": "telefon",
	"SELECT": "Vælg",
	"UPDATE_PROFILE": "Opdater profil",
	"ADDRESS": "Adresse",
	"CONTINUE": "Fortsæt",
	"CORRECT_PASSWORD": "Korrekt adgangskode, du bliver nu logget ind.",
	"TO": "Til",
	"ORDER_FAILED_DESCRIPTION": "Ordren blev ikke gennemført, og pengene er ikke blevet trukket",
	"OF_THIS_PRODUCT": "af dette produkt",
	"START": "Start",
	"RESEND_CONFIRMATION_EMAIL": "Gensend bekræftelses e-mail",
	"APPLY": "Indløs",
	"GIFT_CARD": "Gavekort",
	"PHONE_NR_MIN_2_CHAR_INT": "Telefonnummeret skal være mellem 6-20 tegn",
	"GUEST": "Gæst",
	"PAY": "Betal",
	"VISIT_TIME": "Hvornår ønsker du at ankomme?",
	"CONTINUE_WITHOUT_UPSELL": "Fortsæt uden tilkøb",
	"NO_IMAGE": "Intet billede valgt",
	"PASSWORD": "Adgangskode",
	"NOT_FOUND": "Ikke fundet",
	"PHONE_NUMERIC": "Telefonnummer må kun indeholde tal",
	"WHICH_LOCATION": "Hvilken lokation vil I gerne besøge?",
	"SUBSCRIPTION_VALID_FROM": "Medlemskabet er gyldigt fra",
	"PER_PERIOD": "Pr. periode",
	"MAIL_SENT": "Emailen er sendt",
	"APPLY_GIFT_CARD_CODE": "Indløs gavekort",
	"EMAIL_IS_CONFIRMED": "Din e-mail er blevet bekræftet!",
	"DEACTIVATE_RENEWAL": "Deaktiver automatisk fornyelse",
	"I_HAVE_READ_AND_ACCEPT": "Jeg har læst og accepterer",
	"AFTERNOON": "Eftermiddag",
	"LOGOUT": "Log ud",
	"EXPIRES_ON": "Udløber",
	"CHOOSE_QUANTITY": "Vælg antal",
	"ADD_CREDITCARD": "Tilføj betalingskort",
	"CART_ERROR_FIELDS": "Fejl i udfyldelse",
	"LOGIN_AGAIN": "Log ind igen",
	"SEND_CONFIRMATION_MAIL": "Send bekræftelsesmail",
	"AUTH_INVALID_EMAIL": "E-mailen er ugyldig",
	"ERROR_RESETTING_PASSWORD": "Der skete en fejl under nulstillingen, prøv igen.",
	"WE_ALREADY_SENT_EMAIL": "Vi har allerede sendt dig en e-mail.",
	"CITY_MIN_2_CHAR": "Bynavn skal indeholde mindst 1 tegn",
	"DATO": "Dato",
	"AWAITING_PAYMENT": "Afventer betaling",
	"AUTIMATIC_RENEWAL": "Dit medlemskab er nu sat til automatisk fornyelse",
	"GO_BACK_AND_CHANGE": "Gå tilbage og fjern billetter med forskellige tidspunkter.",
	"LOADING_EVENT": "Indlæser begivenhed...",
	"VISIT_DATE": "Hvornår ønsker du at besøge?",
	"IS_CREATING": "Under Oprettelse",
	"TRY_AGAIN": "Prøv igen",
	"THE_SUBCRIPTION": "medlemskabet",
	"POSTAL_CODE_NUMERIC": "Postnummer må kun indeholde tal",
	"PRICE": "Pris",
	"CHANGE_IMAGE": "Opdater billede",
	"SEND_CONFIRMATION_EMAIL": "Send bekræftelsesmail",
	"CART_EMAIL_ALREADY_CREATED_NOT_CONFIRMED": "Du er allerede oprettet, men din email er ikke bekræftet",
	"CONTACT_DETAILS": "Indtast dine kontaktinformationer",
	"MORE_DATES": "Se flere datoer",
	"DOES_NOT_EXIST_OR_EXPIRED": "eksisterer ikke eller er udløbet",
	"EXISTING_ACCOUNT": "Eksisterende konto",
	"AUTH_USER_DISABLED": "Brugeren er suspenderet",
	"ACCOUNT_ADD_TO_GOOGLE_WALLET": "Tilføj til Google Wallet",
	"FIRST_NAME": "Fornavn",
	"ACCOUNT_INFO": "Oplysninger",
	"AUTOMATIC_RENEWAL_EXPLAINER": "Ved at aktivere automatisk fornyelse, vil medlemskabet automatisk blive fornyet inden starten af næste periode",
	"CONFIRM": "Bekræft",
	"AUTH_WRONG_PASSWORD": "Forkert kodeord",
	"EMAIL_CONFIRMED": "Din email er bekræftet",
	"ORDER_FAILED_DESCRIPTION_2": "Prøv venligst igen om lidt, eller kontakt os, hvis du fortsat oplever dette",
	"QUANTITY": "Antal",
	"PRODUCTS_IN_SUBSCRIPTION": "Produkter i abonnementet",
	"ACTIVATE_ACCOUNT": "Aktiver din konto",
	"SIGN_UP_BUTTON": "Tilmeld nyhedsbrev",
	"EMAIL_MUST_CONFIRMED_EXPLAINER": "Før du kan oprette dit medlemskab, skal vi have bekræftet din email.",
	"POSTAL_CODE_LENGTH_INT": "Postnummer skal indeholde 3-8 tegn.",
	"UNKOWN_EVENT": "Ukendt begivenhed",
	"CONFIRM_EMAIL": "Bekræft email",
	"INACTIVE": "Inaktiv",
	"TO_ACTIVE_ACCOUNT": "For at aktivere kontoen, skal du bekræfte at du er ejeren af overstående email.",
	"CART_APPLY_CODE_ERROR": "Det indtastede gavekort eller rabatkode kunne ikke anvendes. Det kan være fordi det er udløbet, eller fordi du allerede har tilføjet én rabatkode til kurven.",
	"VALIDITY_PERIOD": "Gyldighedsperiode",
	"AUTH_WEAK_PASSWORD": "Adgangskoden er for svag. Brug en adgangskode med mere end 8 tegn",
	"LOADING": "Indlæser...",
	"NIGHT": "Nat",
	"YOUR_ORDERS": "Dine ordrer",
	"CUSTOMER": "Kunde",
	"CART_BILLING_INFORMATION": "Faktureringsoplysninger",
	"TERMS_OF_CONDITIONS": "handelsbetingelserne",
	"SELECT_DATE": "Vælg den dato, du gerne vil booke en aktivitet til",
	"REMOVE_PRODUCT_NOT_ALLOWED": "Det er ikke tilladt at fjerne dette produkt fra indkøbskurven",
	"SEND_CODE": "Send kode",
	"COUNTRY": "Land *",
	"YES": "Ja",
	"CODE_IS_SENT_TO": "Koden er sendt til",
	"SOLD_OUT": "Udsolgt",
	"MEMBERSHIP_TYPE_DOES_NOT_EXIST": "Den valgte medlemskabstype findes ikke, prøv at gå tilbage og vælg en anden.",
	"FIRST_NAMEFIRST_NAME_MIN_2_CHAR": "Fornavn skal indeholde mindst 2 tegn",
	"CARD": "Kort",
	"CHECK_SPAM": "Har du ikke modtaget bekræftelsesmailen indenfor et par minutter, tjek spamindbakken.",
	"PHONE_LENGTH": "Telefonnummer skal indeholde 8-11 tegn",
	"YOU_CAN_MIN_BUY": "Du skal mindst købe",
	"YOU_HAVE_ALREADY_REMOVED_THE_MIN": "Du har allerede fjernet det minimale antal",
	"ERROR_EMAIL_ALREADY_IN_USE": "E-mail-adressen er allerede i brug. Prøv venligst en anden e-mail",
	"SIGN_UP_STATUS": "Tilmelder..",
	"AUTH_USER_NOT_FOUND": "Brugeren kunne ikke findes",
	"APPLY_DISCOUNT_CODE": "Indløs rabatkode",
	"CODE": "Kode",
	"LAST_NAME": "Efternavn",
	"YOUR_CART": "Din kurv",
	"CART_EMAIL_NOT_CREATED_BEFORE": "Du er ikke oprettet før, og kan fortsætte",
	"UPLOADING_PROFILE_PICTURE": "Uploader profilbillede..",
	"PHONE_NR_MIN_2_CHAR": "Telefonnummer skal være mellem 8-11 tegn",
	"EMAIL_SENT": "Vi har netop sendt dig en mail, som du bedes bekræfte ved at klikke på knappen deri.",
	"FORGOT_PASSWORD": "GLEMT KODEORD",
	"CART_ADD_GIFTCARD_OR_DISCOUNTCODE": "Tilføj endnu et gavekort / rabatkode",
	"EVENT_TIME": "Begivenhedsperiode",
	"EDIT_UPSELL_OPTIONS": "Rediger tilkøbsmuligheder",
	"CHANGING_MEMBERSHIP": "Vi ændrer dit medlemskab, vent venligst...",
	"ENTER_PASSWORD": "Indtast en adgangskode til din bruger",
	"FIRST_NAME_MIN_2_CHAR": "Fornavn skal indeholde mindst 2 tegn",
	"HI": "Hej",
	"I_AM_CONFIRMING": "Jeg bekræfter",
	"MEMBERSHIP_NUMBER": "Medlemsnummer",
	"CONFIRMATION_MAIL_TO": "En ordrebekræftelse vil blive sendt til",
	"CANCELLED": "Annulleret",
	"TICKET_NUM": "Billetnummer",
	"UPSELL_TITLE": "Tilkøbsmuligheder til din ordre",
	"TICKET": "Billet",
	"CHOOSE_CATEGORY": "Vælg kategori",
	"FROM": "Fra",
	"CHARGE_IS_SEVEN_DAYS_BEFORE": "* Bemærk at ved automatisk fornyelse af medlemskab, vil opkrævningen ske 7 dage før udløbsdatoen.",
	"APPLY_DISCOUNT_OR_GIFT_CARD_CODE": "Indløs rabatkode eller gavekort",
	"GO_TO_PAYMENT": "Gå til betaling",
	"EMAIL_CONFIRMNIG": "Din email bekræftes...",
	"CART_CARDHOLDER_FOR": "for",
	"YOUR_INFORMATIONS": "Dine kontaktoplysninger",
	"FORGOT_PASSWORD_QUESTIONMARK": "glemt kodeord?",
	"ACCOUNT_EMAIL_NOT_CONFIRMED_YET": "Din email er ikke blevet bekræftet endnu, tjek din email og klik på linket i mailen der er blevet sendt.",
	"ACTIVATE": "Aktiver",
	"PHONE_NUMBER": "Telefonnummer",
	"SEARCH": "Søg",
	"CART_CONTINUE": "Fortsæt",
	"ON_THE_GIFT_CARD": "På gavekortet",
	"LOCATIONS": "Lokationer",
	"BUY": "Køb",
	"CART_CHECKING_EMAIL": "Tjekker din email...",
	"SEND_CONTROL_CODE": "Send kontrolkode",
	"ISSUED_TO": "Udstedt til",
	"ADDRESS_MIN_2_CHAR": "Adressen skal indeholde mindst 2 tegn",
	"DEACTIVATE": "Deaktiver",
	"DOES_NOT_EXIST": "Siden eksisterer ikke",
	"MY_PAGE": "Min side",
	"BUY_TICKET_BUTTON_FREE": "Reserver billetter",
	"YOUR_EMAIL_ADDRESS": "Din email adresse",
	"REMOVE": "Fjern",
	"EMAIL_BEING_CONFIRMED": "Din e-mail bliver bekræftet, vent venligst..",
	"CART_EMAIL_ALREADY_CREATED": "Du er allerede oprettet, indtast din adgangskode herunder",
	"LINK_IS_DEAD": "Det link du har fulgt er enten udløbet eller ugyldig.",
	"CONFIRM_EMAIL_ERROR": "E-mails stemmer ikke overens",
	"SELECT_FROM_LIST": "Vælg fra listen",
	"CART_ADD_GIFTCARD": "Tilføj endnu et gavekort",
	"NO_TIMES_AVAILABLE": "Ingen tider tilgængelige",
	"BUY_ACTIVITIES": "Book ekstra aktiviteter",
	"AUTH_ERROR_TITLE": "Fejl",
	"LOGIN": "Log ind",
	"CATEGORIES": "Kategorier",
	"CONFIRMATION": "Bekræftelse",
	"HAVE_NO_USER": "Har du ingen bruger?",
	"RESET_PASSWORD": "Nulstil adgangskode",
	"ACCOUNT_YOUR_ACCOUNT": "Din konto",
	"CREATE_USER": "Opret bruger",
	"WELCOME": "Velkommen!",
	"ADD_ANOTHER_GIFT_CARD": "Tilføj endnu et gavekort / rabatkode",
	"ACCOUNT_TAB_SUBSCRIPTIONS": "Medlemskaber",
	"NOT_VALID_EMAIL": "Ikke gyldig e-mail",
	"CART_ERROR_MISSING_FIELDS": "Manglende udfyldelse",
	"THANKS_FOR_DETAILS": "Tak for dine oplysninger",
	"ARE_YOU_NOT": "Er du ikke",
	"AMOUNT_PEOPLE": "Hvor mange kommer I?",
	"CHECK_GIFT_CARD": "Tjek saldoen på dit gavekort",
	"SELECTED_TIME": "Valgt tidspunkt:",
	"YOUR_TICKETS": "Dine billetter",
	"ALREADY_EXISTS": "Overstående email er allerede oprettet i vores system, men endnu ikke aktiveret til online brug",
	"EMAIL": "E-mail",
	"EMAIL_DOES_NOT_EXIST": "eksisterer ikke",
	"CREATION_OF_MEMBERSHIP": "Oprettelse af medlemskab",
	"IN_THE_FIELD": "i feltet under for at bekræfte",
	"CARD_NUM": "Kortnummer",
	"HOW_MANY_ACTIVITIES": "Vælg antallet af medlemskaber, du ønsker at booke en aktivitet for",
	"MAX_90": "Du kan ikke have mere end 90 produkter i din indkøbskurv.",
	"ERROR": "Der skete en fejl",
	"TOTAL_PRICE": "Samlet pris",
	"POSTAL_CODE": "Postnummer",
	"RELEASE_FILES_HERE": "Slip filerne her",
	"ACTIVE": "Aktiv",
	"SUBTOTAL": "Subtotal",
	"EMAIL_ERROR": "Dette er ikke en gyldig e-mail",
	"COMPLETED": "Gennemført",
	"YOUR_MEMBERSHIP_CARDS": "Dine medlemskort",
	"EXPIRES_SOON": "Udløber snart",
	"RENEWAL_WHEN_PAYMENT_CHARGED": "Ved automatisk fornyelse vil opkrævningen ske 7 dage før udløbsdato. Ved manuel fornyelse vil opkrævningen ske på dagen du fornyer.",
	"AUTIMATIC_RENEWAL_EXPLAINER": "Ved at aktivere automatisk fornyelse, vil medlemskabet automatisk blive fornyet inden starten af næste periode",
	"APP_TITLE": "listEventGroupDateSelector",
	"ACCOUNT_TAB_INFORMATION": "Kontaktoplysninger",
	"PASSWORD_CHANGED": "Adgangskode ændret",
	"CHANGING_PASSWORD": "Indstiller din adgangskode - vent et øjeblik..",
	"GO_BACK": "Gå tilbage til dit køb",
	"BUY_TICKET": "Køb billet",
	"ORDER_NOTE_PLACEHOLDER": "Skriv dine bemærkninger her",
	"WRITE": "Skriv",
	"CITY_LENGTH": "By skal være på mindst 2 tegn",
	"PASSWORD_CHANGED_SUCCESSFULLY": "Din adgangskode er blevet ændret",
	"CHOOSE_LOCATION": "Vælg lokation",
	"THIS_DAY": "billetter på denne dag.",
	"AUTH_OPERATION_NOT_ALLOWED": "Ukendt fejl",
	"LOGIN_INSTEAD": "Log ind i stedet?",
	"ACTIVATE_RENEWAL": "Aktiver automatisk fornyelse",
	"CONFIRM_PASSWORD": "Bekræft kodeord",
	"SUBSCRIPTION_GIFT_CARD": "Abonnement gavekort",
	"ACTIVE_ACCOUNT_BY": "Dette kan gøres ved at klikke på nedenstående link, hvorefter vi fremsender en bekræftelsesmail",
	"BUY_TICKET_FREE": "Reserver billet",
	"TRY_NEW_CARD": "Prøv et nyt kort",
	"AUTH_PASSWORDS_DO_NOT_MATCH": "Adgangskoderne stemmer ikke overens",
	"YOU_MUST_CONFIRM_EMAIL": "Du skal nu bekræfte din email adresse, for at få en brugerprofil hos os.",
	"AMOUNT": "Antal",
	"CREATE_ACCOUNT": "Opret konto",
	"CONFIRMATION_MAIL_TO_YOU": "En ordrebekræftelse vil blive sendt til dig",
	"CHOOSE_AMOUNT_PEOPLE": "Vælg antal",
	"MISSING_ACCOUNT": "Manglende konto",
	"YOU_CAN_CLOSE_THIS_WINDOW": "Du kan nu lukke dette vindue",
	"SUBSCRIPTION_VALID_FROM_FIRST_TIME": "Medlemskabet er gyldigt fra første gang du bruger det",
	"FINISH": "Færdiggør",
	"ALL_DATES_SELECTED": "Alle datoer er valgt",
	"CONFIRM_MAIL_TO_SHOW_ORDERS": "Før vi kan vise din tidligere {type}, skal du bekræfte din e-mail.",
	"PLEASE_WAIT": "Vent venligst",
	"POSTAL_CODE_LENGTH": "Postnummer skal indeholde 3-4 tegn.",
	"EMAIL_MUST_BE_CONFIRMED": "Din e-mail skal bekræftes",
	"YOU_NEED_TO_SELECT_TIME_OR_PRODUCTS": "Du mangler at vælge følgende:",
	"SELECT_ACTIVITIES": "Book aktiviteter",
	"CHOOSE_TIME": "Vælg tidspunkt",
	"EVENING": "Aften",
	"MEMBERSHIP_IS_CREATED_ON_THIS_CUSTOMER": "Medlemskabet oprettes på denne kunde",
	"FAQ_SUBTITLE": "Vi er her for at hjælpe",
	"ORDER": "Ordre",
	"CHECK_CODE": "Tjek gavekort",
	"YOU_CAN_MAX_BUY": "Du kan maksimalt købe",
	"CANCEL": "Annuller",
	"NO_QUESTIONS": "Der er ingen spørgsmål",
	"ENTER_DETAILS": "Indtast dine oplysninger for at oprette en konto.",
	"PHONE_NR": "Telefonnummer",
}