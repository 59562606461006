import moment from 'moment';
import _ from 'lodash';

export function textUpperCase(text, options) {
	return _.capitalize(text);
}

export function getParameterByName(name, url = window.location.href) {
	// eslint-disable-next-line no-useless-escape
	name = name.replace(/[\[\]]/g, '\\$&');
	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getPrisFromProduct(product, currentLocation) {
	const defaultPrice = product.prices.find(p => p.type === 'Sale').price;

	if (
		currentLocation &&
		product.locations &&
		product.locations[currentLocation] &&
		!product.locations[currentLocation].deleted &&
		!product.locations[currentLocation].pricesDeleted
	) {
		const locationPrices = product.locations[currentLocation].prices;
		if (locationPrices) {
			const price = locationPrices.find(p => p.type === 'Sale');
			if (price) {
				return price.price;
			}
		}
	}

	return defaultPrice;
}

window.ATTdebugListener = false;

window.addEventListener('keydown', e => {
	if (e.altKey && e.ctrlKey && e.key === 'l') {
		window.ATTdebugListener = !window.ATTdebugListener;
		if (window.ATTdebugListener) {
			console.log('%c Listener debug mode enabled', 'color: #baed91;');
		} else {
			console.log('%c Listener debug mode disabled', 'color: #fea3aa;');
		}
	}
});

export function debugListener(listener) {
	if (window.location.hostname === 'localhost' || window.ATTdebugListener) {
		console.groupCollapsed('%c Listener', 'color: orange;', listener);
		console.trace();
		console.groupEnd();
	}
}

export function returnActivePeriodPricesOrNull(product, date) {
	if (product.periodPrices) {
		const activePeriods = getActivePeriodPrices(product.periodPrices, date);
		if (activePeriods.length > 0) {
			const { price } = activePeriods[0].prices.find(f => f.type === 'Sale');
			if (price !== 'undefined' && price !== null) {
				return price;
			}
		}
	}
	return null;
}

export function returnLocationPriceOrNull(product, location) {
	if (location && product.locations && product.locations[location] && product.locations[location].prices) {
		if (product.locations[location].pricesDeleted !== true && product.locations[location].prices.length > 0) {
			const { price } = product.locations[location].prices.find(f => f.type === 'Sale');
			if (price !== 'undefined' && price !== null) {
				return price;
			}
		}
	}
	return null;
}

export function returnActivePeriodPriceByLocationOrNull(product, location, date) {
	if (location && product.locations && product.locations[location] && product.locations[location].periodPrices) {
		const activePeriods = getActivePeriodPrices(product.locations[location].periodPrices, date);
		if (activePeriods.length > 0) {
			const { price } = activePeriods[0].prices.find(f => f.type === 'Sale');
			if (price !== 'undefined' && price !== null) {
				return price;
			}
		}
	}
	return null;
}

const getActivePeriodPrices = (periodPrices, date = null) => {
	return Object.values(periodPrices)
		.map(period => {
			return {
				...period,
				deleted: period.deleted,
				fromDate:
					period.fromDate && typeof period.fromDate.toDate === 'function' ? period.fromDate.toDate() : null,
				toDate: period.toDate && typeof period.toDate.toDate === 'function' ? period.toDate.toDate() : null,
				eventFromDate:
					period.eventFromDate && typeof period.eventFromDate.toDate === 'function'
						? period.eventFromDate.toDate()
						: null,
				eventToDate:
					period.eventToDate && typeof period.eventToDate.toDate === 'function'
						? period.eventToDate.toDate()
						: null,
				prices: period.prices
			};
		})
		.filter(period => {
			let isInActivePeriod = false;
			let isInActiveEventPeriod = false;
			if (period.deleted) return false;

			const defaultDate = new Date();
			if (period.fromDate) {
				const fromDateString = getDateString(period.fromDate);
				const defaultDateString = getDateString(defaultDate);

				if (!!period.toDate === false) {
					isInActivePeriod = moment(fromDateString).isSameOrBefore(defaultDateString);

					if (period.fromDate && isInActivePeriod === false) {
						return false;
					}
				} else {
					const toDateString = getDateString(period.toDate);
					isInActivePeriod =
						moment(fromDateString).isSameOrBefore(defaultDateString) &&
						moment(toDateString).isSameOrAfter(defaultDateString);

					if (period.fromDate && period.toDate && isInActivePeriod === false) {
						return false;
					}
				}
			}

			const eventDate = date || new Date();

			if (period.eventFromDate) {
				const eventFromDateString = getDateString(period.eventFromDate);
				const eventDateString = getDateString(eventDate);

				if (!!period.eventToDate === false) {
					isInActiveEventPeriod = moment(eventFromDateString).isSameOrBefore(eventDateString);

					if (period.eventFromDate && isInActiveEventPeriod === false) {
						return false;
					}
				} else {
					const eventToDateString = getDateString(period.eventToDate);

					isInActiveEventPeriod =
						moment(eventFromDateString).isSameOrBefore(eventDateString) &&
						moment(eventToDateString).isSameOrAfter(eventDateString);

					if (period.eventFromDate && period.eventToDate && isInActiveEventPeriod === false) {
						return false;
					}
				}
			}
			return true;
		});
};

const getDateString = date => {
	if (date && date.getTime) {
		return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
	}
	return new Date();
};

export const getLocaleFromData = (object, value) => {
	const currentLanguageLocalStorage = localStorage.getItem('language')
		? JSON.parse(localStorage.getItem('language'))
		: null;
	if (
		currentLanguageLocalStorage &&
		currentLanguageLocalStorage.isoCode &&
		object &&
		object.contentStrings &&
		object.contentStrings[value] &&
		object.contentStrings[value][currentLanguageLocalStorage.isoCode] !== null &&
		object.contentStrings[value][currentLanguageLocalStorage.isoCode] !== undefined
	) {
		return object.contentStrings[value][currentLanguageLocalStorage.isoCode];
	}
	return object[value] || '';
};

export const moneyFormatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});

export const GTMPriceFormatter = new Intl.NumberFormat('da-DK', {
	minimumFractionDigits: 2
});
