export default {
	"DRAG_OR_CLICK_HERE": "Drag files or click here",
	"PROCESSING_ORDER": "We are processing your order, please wait...",
	"PER_UNIT": "Price per unit",
	"REFUNDED": "Refunded",
	"CART_TERMS_OF_CONDITIONS_SUBSCRIPTION": "the subscription terms and conditions",
	"CART_AUTO_RENEWAL_ACCEPT": "I would like to renew the memberships automatically before the start of each period",
	"ACCOUNT_SUBSCRIPTIONS_CHANGE_CARD": "Change creditcard",
	"ACCOUNT_ACTIVATING": "Your account is being activated, please wait..",
	"ERROR_UNKNOWN": "An unknown error occurred. Try again later",
	"TYPE_EMAIL_UNDERNEATH": "Type your email to start creating your membership.",
	"I_WILL_RENEW_SUBSCRIPTION": "I want to renew the subscription at the beginning of each period",
	"NO_USER_FOUND_MESSAGE": "You don't seem to have an account. Therefore, you'll need to create your account first.",
	"THE_GIFT_CARD": "The gift card",
	"CHOOSE_TICKET_AMOUNT_BEFORE": "Please choose amount of tickets",
	"SEARCH_QUESTIONS": "Search questions...",
	"REMOVE_PRODUCT_FROM_CART": "Remove from cart",
	"ORDER_NOTE_LABEL": "Notes on the order",
	"RENEW": "Renew",
	"END": "End",
	"ORDER_FAILED_TITLE": "The order failed",
	"THE_BALANCE_IS": "is the balance",
	"MAIL": "Email address",
	"CHOOSE_TICKETS": "Choose your tickets",
	"CHOOSE_TICKETS_BEFORE_BUY": "Choose your tickets first",
	"PRODUCTS_HAS_DIFFERENT_TIMESLOTS": "Selected tickets have different timeslots",
	"PHONE_INVALID_REMEMBER_COUNTRY_CODE": "The phonenumber is invalid. If the phonenumber is not from Denmark, remember to add the country code (+xx) before the number.",
	"EMAIL_ALREADY_EXISTS": "Email already exists. Sign in instead.",
	"AUTH_MISSING_EMAIL": "Missing email",
	"ACCOUNT_ADD_TO_APPLE_WALLET": "Add to Apple Wallet",
	"CHOOSE_DATE_BEFORE": "Please choose date first",
	"MORNING": "Morning",
	"MAX_ORDER": "You can order a maximum of",
	"AWAITING_RENEWAL": "Awaiting renewal",
	"OK_BUTTON": "Yes",
	"BEFORE_MIDDAY": "Before noon",
	"MEMBERSHIP_RENEWAL_FROM": "Your membership will be renewed",
	"TOTAL": "Total",
	"ERROR_PRODUCT": "The product on",
	"HOW_MANY": "How many will you be visiting?",
	"AUTH_EMAIL_ALREADY_IN_USE": "The email is already in use",
	"CHOOSE_TICKETS_BEFORE": "Please choose amount of tickets before",
	"IF_MAIL_EXIST_YOU_WILL_RECEIVE_EMAIL": "If your email exist in our system, you will shortly recieve an email allowing you to reset your password",
	"ACCOUNT_NOT_FOUND": "Your account was not found, please try again later",
	"IF_YOU_CHOOSE_TO_REMOVE_THESE_PRODUCTS": "If you continue without selecting, the extras will not be added to your order.",
	"THANKS_FOR_ORDER": "Thank you for your order",
	"TO_BE_ABLE_TO_PURCHASE_THESE_PRODUCTS": "To be able to add extras to your order, you need to select tickets with the same timeslot.",
	"YOUR_MEMBERSHIPS": "Your memberships",
	"CART_SAME_NAMES_AS_BILLING": "Same first and last name as billing information",
	"FAQ_HEADER": "Frequently asked questions",
	"ACCOUNT_NEEDED_EMAIL_CONFIRMATION": "Confirmation needed",
	"TAX": "Tax",
	"ALL_LOCATIONS_SELECTED": "All locations selected",
	"MAX_90_HEADER": "Maximum 90 products",
	"ENTER_NEW_PASSWORD": "Enter a new password for the user with the following email:",
	"ADD_TO_GOOGLE_WALLET_LOGO": "/assets/images/wallet/en-GB/en_add_to_google_wallet_add-wallet-badge.png",
	"SORRY": "Sorry",
	"LAST_NAME_MIN_2_CHAR": "Last name must contain at least 2 characters",
	"CREATE_MEMBERSHIP": "Create membership",
	"ACCOUNT_EXISTS": "Do you already have an account?",
	"ADD_TO_APPLE_WALLET_LOGO": "/assets/images/wallet/da-DK/US-UK_Add_to_Apple_Wallet.png",
	"CITY": "City",
	"REMOVE_FROM_CART": "Are you sure you want to remove this product from your cart?",
	"YOU_CAN_LOGIN": "You can now log in",
	"MEMBERSHIP": "Membership",
	"CONTINUE_CREATING_SUBCRIPTION": "Continue membership registration here",
	"FULL_NAME": "Full name",
	"CANCEL_BUTTON": "No",
	"PRODUCT": "Product",
	"CART_PAY_BUTTON": "PAY",
	"READ_MORE": "Read more",
	"LOGGED_IN_WITH_CORRECT_USER": "logged in with the correct user",
	"ERROR_EVENT": "The time on",
	"NEED_TO_BUY_MINIMUM_OF": "You need to buy a minimum of",
	"CHOOSE_DATE": "Choose date",
	"BUY_TICKET_BUTTON": "BUY TICKET",
	"TO_CREATE_MEMBERSHIP_YOU_NEED_A_USER": "To create a membership you must have an account. Start by typing your email, so we are able to check if you exist in our system.",
	"PRICE_EA": "PRICE PER UNIT",
	"TICKETS_LEFT": "tickets left",
	"NEWSLETTER_SIGNUP": "Sign up for newsletter",
	"ACCOUNT_ACTIVATED": "Your account has been activated",
	"CART_CARDHOLDER": "Card holder",
	"ALL_CATEGORIES_SELECTED": "All categories selected",
	"DEACTIVATE_RENEWAL_EXPLAINER": "By deactivating renewal, the subscription will not be renewed. You will have to pay manually.",
	"PAYMENT": "Payment",
	"VALUE": "Value",
	"NO_EVENTS": "There are no matches to your search criteria",
	"YOU_HAVE_ALREADY_ADDED_THE_MAX": "You have already added the maximum amount",
	"PASSWORD_LENGTH": "The password must contain at least 6 characters",
	"PHONE": "Phone",
	"SELECT": "Select",
	"UPDATE_PROFILE": "Update profile",
	"ADDRESS": "Address",
	"CONTINUE": "Continue",
	"CORRECT_PASSWORD": "Correct password, you are now being logged in.",
	"TO": "To",
	"ORDER_FAILED_DESCRIPTION": "The order was not completed and the money has not been withdrawn",
	"OF_THIS_PRODUCT": "of this product",
	"START": "Start",
	"RESEND_CONFIRMATION_EMAIL": "Resend confirmation email",
	"APPLY": "Apply",
	"GIFT_CARD": "Gift card",
	"PHONE_NR_MIN_2_CHAR_INT": "Phone number must be between 6-20 characters",
	"GUEST": "Guest",
	"PAY": "Pay",
	"VISIT_TIME": "When do you wish to arrive?",
	"CONTINUE_WITHOUT_UPSELL": "Continue without extras",
	"NO_IMAGE": "No image selected",
	"PASSWORD": "Password",
	"NOT_FOUND": "Not found",
	"PHONE_NUMERIC": "Phone number must be numeric",
	"WHICH_LOCATION": "Which location do you wish to visit?",
	"SUBSCRIPTION_VALID_FROM": "The subscription is valid from",
	"PER_PERIOD": "Per period",
	"MAIL_SENT": "Email has been sent",
	"APPLY_GIFT_CARD_CODE": "Apply gift card",
	"EMAIL_IS_CONFIRMED": "Your email has been confirmed!",
	"DEACTIVATE_RENEWAL": "Deactivate automatic renewal",
	"I_HAVE_READ_AND_ACCEPT": "I have read and accept",
	"AFTERNOON": "Afternoon",
	"LOGOUT": "Logout",
	"EXPIRES_ON": "Expires on",
	"CHOOSE_QUANTITY": "Choose tickets",
	"ADD_CREDITCARD": "Add creditcard",
	"CART_ERROR_FIELDS": "Error",
	"LOGIN_AGAIN": "Login again",
	"SEND_CONFIRMATION_MAIL": "Send confirmation mail",
	"AUTH_INVALID_EMAIL": "The email is invalid",
	"ERROR_RESETTING_PASSWORD": "There has been an error while resetting your password, please try again.",
	"WE_ALREADY_SENT_EMAIL": "We have already sent you an email.",
	"CITY_MIN_2_CHAR": "City name must contain at least 1 character",
	"DATO": "Date",
	"AWAITING_PAYMENT": "Awaiting payment",
	"AUTIMATIC_RENEWAL": "Your membership is now set to automatic renewal",
	"GO_BACK_AND_CHANGE": "Go back and remove tickets with different timeslots.",
	"LOADING_EVENT": "Loading event...",
	"VISIT_DATE": "When do you wish to visit?",
	"IS_CREATING": "Creating subscription",
	"TRY_AGAIN": "Try again",
	"THE_SUBCRIPTION": "the subscription",
	"POSTAL_CODE_NUMERIC": "Postal code must be numeric",
	"PRICE": "Price",
	"CHANGE_IMAGE": "Change image",
	"SEND_CONFIRMATION_EMAIL": "Send confirmation email",
	"CART_EMAIL_ALREADY_CREATED_NOT_CONFIRMED": "You are already registered, but your email is not confirmed yet",
	"CONTACT_DETAILS": "Enter contact details",
	"MORE_DATES": "See more dates",
	"DOES_NOT_EXIST_OR_EXPIRED": "does not exist or has expired",
	"EXISTING_ACCOUNT": "Existing account",
	"AUTH_USER_DISABLED": "The user is suspended",
	"ACCOUNT_ADD_TO_GOOGLE_WALLET": "Add to Google Wallet",
	"FIRST_NAME": "First name",
	"ACCOUNT_INFO": "Account information",
	"AUTOMATIC_RENEWAL_EXPLAINER": "By activating renewal, the membership will be renewed automatically at the beginning of next period",
	"CONFIRM": "Confirm",
	"AUTH_WRONG_PASSWORD": "Wrong password",
	"EMAIL_CONFIRMED": "Email confirmed",
	"ORDER_FAILED_DESCRIPTION_2": "Please try again shortly or contact us if you continue to experience this",
	"QUANTITY": "Quantity",
	"PRODUCTS_IN_SUBSCRIPTION": "Products in the subscription",
	"ACTIVATE_ACCOUNT": "Activate your account",
	"SIGN_UP_BUTTON": "Sign up",
	"EMAIL_MUST_CONFIRMED_EXPLAINER": "Before you can create your membership, we need to confirm your email",
	"POSTAL_CODE_LENGTH_INT": "Postal code must contain 3-8 characters.",
	"UNKOWN_EVENT": "Unknown event",
	"CONFIRM_EMAIL": "Confirm email",
	"INACTIVE": "Inactive",
	"TO_ACTIVE_ACCOUNT": "To activate your account, you must confirm ownership of the email.",
	"CART_APPLY_CODE_ERROR": "The gift card or discount code you entered could not be applied. This may be because it is expired or because you already have added a discount code to your cart.",
	"VALIDITY_PERIOD": "Validity period",
	"AUTH_WEAK_PASSWORD": "The password is too weak. Use a password with more than 8 characters",
	"LOADING": "Loading...",
	"NIGHT": "Night",
	"YOUR_ORDERS": "Your orders",
	"CUSTOMER": "Customer",
	"CART_BILLING_INFORMATION": "Billing Information",
	"TERMS_OF_CONDITIONS": "the terms and conditions",
	"SELECT_DATE": "Select date you would like to book an activity for",
	"REMOVE_PRODUCT_NOT_ALLOWED": "It is not allowed to remove this product from the cart",
	"SEND_CODE": "Send code",
	"COUNTRY": "Country *",
	"YES": "Yes",
	"CODE_IS_SENT_TO": "The code has been sent to",
	"SOLD_OUT": "Sold out",
	"MEMBERSHIP_TYPE_DOES_NOT_EXIST": "The selected membership type does not exist, please try another type.",
	"FIRST_NAMEFIRST_NAME_MIN_2_CHAR": "First name must contain at least 2 characters",
	"CARD": "Card",
	"CHECK_SPAM": "If you haven't received the confirmation email within a few minutes, please check your spam folder.",
	"PHONE_LENGTH": "Phone number must be at least 8-11 characters",
	"YOU_CAN_MIN_BUY": "You must buy at least",
	"YOU_HAVE_ALREADY_REMOVED_THE_MIN": "You have already removed the minimum amount",
	"ERROR_EMAIL_ALREADY_IN_USE": "The email adress is already in use. Please try another email",
	"SIGN_UP_STATUS": "Signing up..",
	"AUTH_USER_NOT_FOUND": "The user could not be found",
	"APPLY_DISCOUNT_CODE": "Apply promotion code",
	"CODE": "Code",
	"LAST_NAME": "Last name",
	"YOUR_CART": "Your cart",
	"CART_EMAIL_NOT_CREATED_BEFORE": "You are not created, and can continue",
	"UPLOADING_PROFILE_PICTURE": "Uploading profile picture",
	"PHONE_NR_MIN_2_CHAR": "Phone number must be between 8-11 characters",
	"EMAIL_SENT": "We have just sent you an email, which you must confirm by clicking the button.",
	"FORGOT_PASSWORD": "FORGOT PASSWORD",
	"CART_ADD_GIFTCARD_OR_DISCOUNTCODE": "Add another giftcard or discountcode",
	"EVENT_TIME": "Event time",
	"EDIT_UPSELL_OPTIONS": "Edit additional purchases",
	"CHANGING_MEMBERSHIP": "We are changing your membership, please wait...",
	"ENTER_PASSWORD": "Enter a password for your account",
	"FIRST_NAME_MIN_2_CHAR": "First name must contain at least 2 characters",
	"HI": "Hi",
	"I_AM_CONFIRMING": "I am confirming",
	"MEMBERSHIP_NUMBER": "Subscription number",
	"CONFIRMATION_MAIL_TO": "A confirmation mail will be sent to",
	"CANCELLED": "Cancelled",
	"TICKET_NUM": "Ticket number",
	"UPSELL_TITLE": "Extras for your order",
	"TICKET": "Ticket",
	"CHOOSE_CATEGORY": "Choose category",
	"FROM": "From",
	"CHARGE_IS_SEVEN_DAYS_BEFORE": "* Note that with automatic renewal of membership, the charge will occur 7 days before the expiration date",
	"APPLY_DISCOUNT_OR_GIFT_CARD_CODE": "Apply promotion code or gift card",
	"GO_TO_PAYMENT": "Go to checkout",
	"EMAIL_CONFIRMNIG": "Confirming email...",
	"CART_CARDHOLDER_FOR": "for",
	"YOUR_INFORMATIONS": "Your informations",
	"FORGOT_PASSWORD_QUESTIONMARK": "Forgot password?",
	"ACCOUNT_EMAIL_NOT_CONFIRMED_YET": "Your email has not been confirmed yet, check your email and click on the link in the mail.",
	"ACTIVATE": "Activate",
	"PHONE_NUMBER": "Phone number",
	"SEARCH": "Search",
	"CART_CONTINUE": "Continue",
	"ON_THE_GIFT_CARD": "On the gift card",
	"LOCATIONS": "Locations",
	"BUY": "Buy",
	"CART_CHECKING_EMAIL": "Checking email...",
	"SEND_CONTROL_CODE": "Send control code",
	"ISSUED_TO": "Issued to",
	"ADDRESS_MIN_2_CHAR": "Address must contain at least 2 characters",
	"DEACTIVATE": "Deactivate",
	"DOES_NOT_EXIST": "The page does not exist",
	"MY_PAGE": "My page",
	"BUY_TICKET_BUTTON_FREE": "Reserve tickets",
	"YOUR_EMAIL_ADDRESS": "Your email",
	"REMOVE": "Remove",
	"EMAIL_BEING_CONFIRMED": "Your email is being confirmed, please wait..",
	"CART_EMAIL_ALREADY_CREATED": "You are already registered, enter your password below",
	"LINK_IS_DEAD": "The link you have followed seems to be invalid..",
	"CONFIRM_EMAIL_ERROR": "The emails does not match",
	"SELECT_FROM_LIST": "Choose from the list",
	"CART_ADD_GIFTCARD": "Add another giftcard",
	"NO_TIMES_AVAILABLE": "No times available",
	"BUY_ACTIVITIES": "Book extra activities",
	"AUTH_ERROR_TITLE": "Error",
	"LOGIN": "Login",
	"CATEGORIES": "Categories",
	"CONFIRMATION": "Confirmation",
	"HAVE_NO_USER": "Have no user?",
	"RESET_PASSWORD": "Reset password",
	"ACCOUNT_YOUR_ACCOUNT": "Your account",
	"CREATE_USER": "Create user",
	"WELCOME": "Welcome!",
	"ADD_ANOTHER_GIFT_CARD": "Add another gift card or promo code",
	"ACCOUNT_TAB_SUBSCRIPTIONS": "Memberships",
	"NOT_VALID_EMAIL": "Not valid email",
	"CART_ERROR_MISSING_FIELDS": "Missing completion",
	"THANKS_FOR_DETAILS": "Thank you for your details",
	"ARE_YOU_NOT": "Are you not",
	"AMOUNT_PEOPLE": "How many people are you?",
	"CHECK_GIFT_CARD": "Check the balance on your gift card",
	"SELECTED_TIME": "Selected time:",
	"YOUR_TICKETS": "Your tickets",
	"ALREADY_EXISTS": "The email already exists in our system, but has not been activated for online use",
	"EMAIL": "Email",
	"EMAIL_DOES_NOT_EXIST": "does not exist",
	"CREATION_OF_MEMBERSHIP": "Creation of membership",
	"IN_THE_FIELD": "in the field to confirm",
	"CARD_NUM": "Card number",
	"HOW_MANY_ACTIVITIES": "Choose the amount of memberships you would like to book an activity for",
	"MAX_90": "Du kan ikke have mere end 90 produkter i kurven på samme tid.",
	"ERROR": "An error occurred",
	"TOTAL_PRICE": "Total price",
	"POSTAL_CODE": "Postal code",
	"RELEASE_FILES_HERE": "Release files here",
	"ACTIVE": "Active",
	"SUBTOTAL": "Subtotal",
	"EMAIL_ERROR": "This is not a valid email",
	"COMPLETED": "Completed",
	"YOUR_MEMBERSHIP_CARDS": "Your membership cards",
	"EXPIRES_SOON": "Expires soon",
	"RENEWAL_WHEN_PAYMENT_CHARGED": "In automatic renewal, the charge will occur 7 days before the expiration date. In manual renewal, the charge will occur on the day you renew.",
	"AUTIMATIC_RENEWAL_EXPLAINER": "By activating renewal, the membership will be renewed automatically at the beginning of next period",
	"APP_TITLE": "listEventGroupDateSelector",
	"ACCOUNT_TAB_INFORMATION": "Information",
	"PASSWORD_CHANGED": "Password changed",
	"CHANGING_PASSWORD": "Setting your password - please wait..",
	"GO_BACK": "Go back to your cart",
	"BUY_TICKET": "Buy ticket",
	"ORDER_NOTE_PLACEHOLDER": "Write your notes here",
	"WRITE": "Type",
	"CITY_LENGTH": "City must be at least 2 characters",
	"PASSWORD_CHANGED_SUCCESSFULLY": "Your password has been changed",
	"CHOOSE_LOCATION": "Choose location",
	"THIS_DAY": "tickets on this day.",
	"AUTH_OPERATION_NOT_ALLOWED": "Unknown error",
	"LOGIN_INSTEAD": "Login instead?",
	"ACTIVATE_RENEWAL": "Activate automatic renewal",
	"CONFIRM_PASSWORD": "Confirm password",
	"SUBSCRIPTION_GIFT_CARD": "Subscription gift card",
	"ACTIVE_ACCOUNT_BY": "This can be done by following the link below, after which you will receive an email.",
	"BUY_TICKET_FREE": "Reserve ticket",
	"TRY_NEW_CARD": "Try a new card",
	"AUTH_PASSWORDS_DO_NOT_MATCH": "The passwords does not match",
	"YOU_MUST_CONFIRM_EMAIL": "You must confirm your email address, to get an account.",
	"AMOUNT": "Amount",
	"CREATE_ACCOUNT": "Create account",
	"CONFIRMATION_MAIL_TO_YOU": "A confirmation mail will be sent to you",
	"CHOOSE_AMOUNT_PEOPLE": "Choose amount",
	"MISSING_ACCOUNT": "Missing account",
	"YOU_CAN_CLOSE_THIS_WINDOW": "You can now close this window",
	"SUBSCRIPTION_VALID_FROM_FIRST_TIME": "The subscription is valid from the first time you use it",
	"FINISH": "Finish",
	"ALL_DATES_SELECTED": "All dates selected",
	"CONFIRM_MAIL_TO_SHOW_ORDERS": "Before we can show your prior {type} you must confirm your email.",
	"PLEASE_WAIT": "Please wait",
	"POSTAL_CODE_LENGTH": "Postal code must contain 3-4 characters.",
	"EMAIL_MUST_BE_CONFIRMED": "Your email must be confirmed",
	"YOU_NEED_TO_SELECT_TIME_OR_PRODUCTS": "You need to select the following:",
	"SELECT_ACTIVITIES": "Book activities",
	"CHOOSE_TIME": "Choose time",
	"EVENING": "Evening",
	"MEMBERSHIP_IS_CREATED_ON_THIS_CUSTOMER": "The membership is being created on this customer",
	"FAQ_SUBTITLE": "We are here to help",
	"ORDER": "Order",
	"CHECK_CODE": "Check gift card",
	"YOU_CAN_MAX_BUY": "You can buy max",
	"CANCEL": "Cancel",
	"NO_QUESTIONS": "No questions has been found",
	"ENTER_DETAILS": "Type your informations to create an account.",
	"PHONE_NR": "Phone number",
}