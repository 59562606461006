import { Control, Controller, FieldError } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import React, { HTMLInputTypeAttribute, useMemo } from 'react';
import ValidationsType from '@ameroservices-platform/shared/ui-components/fields/ValidationsType';
import VariantType from '@ameroservices-platform/shared/ui-components/fields/VariantType';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Icon, Tooltip } from '@mui/material';

function TextFieldForm({
	name,
	label,
	control,
	className,
	type,
	onlyPositive,
	validations,
	variant,
	fullWidth,
	required,
	disabled,
	hideHelperText,
	noEmptyHelperText,
	InputProps,
	InputLabelProps,
	placeholder,
	helperText,
	size,
	multiline,
	onlyAmeroAdmin,
	autoFocus
}: {
	name: string;
	label: string;
	control: Control;
	className?: string;
	type?: HTMLInputTypeAttribute | 'integer';
	onlyPositive?: boolean;
	validations?: ValidationsType;
	variant?: VariantType;
	fullWidth?: boolean;
	required?: boolean;
	disabled?: boolean;
	hideHelperText?: boolean;
	noEmptyHelperText?: boolean;
	helperText?: string;
	InputProps?: any;
	InputLabelProps?: any;
	placeholder?: string;
	size?: 'small' | 'medium';
	multiline?: boolean;
	onlyAmeroAdmin?: boolean;
	autoFocus?: boolean;
}) {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const user = useSelector(state => state.auth.user);
	const userRoleIsAmeroAdmin = useMemo(() => user.role.includes('ameroAdmin'), [user.role]);
	const rules = useMemo(() => {
		if (disabled) {
			return {};
		}
		const rules: ValidationsType = validations ? validations : {};
		if (required) {
			rules.required = 'Dette felt er påkrævet';
		}
		if (type === 'number') {
			rules.pattern = {
				message: 'Indtast et korrekt nummer',
				value: /^[0-9][0-9]*[,.]{0,1}:*[0-9]{0,2}?$/
			};
		}
		if (type === 'integer') {
			if (onlyPositive) {
				rules.pattern = {
					message: 'Indtast et positivt heltal',
					value: /^\d*$/
				};
			} else {
				rules.pattern = {
					message: 'Indtast et heltal',
					value: /^[-]*[0-9][0-9]*[,.]{0,1}:*[0-9]{0,2}?$/
				};
			}
		}
		if (rules.isEmail) {
			rules.pattern = {
				message: 'Indtast en korrekt email adresse',
				value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
			};
		}
		return rules;
	}, [validations, required, disabled, onlyPositive]);

	const getHelperText = (
		error: FieldError | undefined,
		hideHelperText: boolean | undefined,
		noEmptyHelperText: boolean | undefined
	) => {
		if (hideHelperText) {
			return null;
		}
		if (error && error.message) {
			return error.message;
		}
		if (helperText) {
			return helperText;
		}
		if (noEmptyHelperText) {
			return ' ';
		}
		return null;
	};

	if (onlyAmeroAdmin && !userRoleIsAmeroAdmin) {
		return null;
	}

	return (
		<div className={'w-full flex items-center gap-4'}>
			{onlyAmeroAdmin && (
				<Tooltip title="Kun muligt for amero admins">
					<Icon className={'mr-10'}>security</Icon>
				</Tooltip>
			)}
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field, fieldState: { invalid, error } }) => {
					return (
						<TextField
							{...field}
							value={field.value || ''}
							error={invalid || !!error}
							helperText={getHelperText(error, hideHelperText, noEmptyHelperText)}
							className={className}
							type={type === 'integer' ? 'number' : type || 'text'}
							name={name}
							label={label}
							variant={variant || 'outlined'}
							fullWidth={fullWidth}
							required={required}
							disabled={disabled}
							size={size}
							InputProps={{
								...(InputProps || {}),
								className: clsx(InputProps?.className, disabled ? 'bg-gray-100' : '')
							}}
							InputLabelProps={InputLabelProps}
							placeholder={placeholder}
							multiline={multiline}
							autoFocus={autoFocus}
						/>
					);
				}}
			/>
		</div>
	);
}

export default TextFieldForm;
