import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import Hidden from '@mui/material/Hidden';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { DeleteOutline } from '@mui/icons-material';
import React from 'react';
import CartAmountField from '../../../../types/cart/cart/CartAmountField';

const ProductAmountCell = ({ classes, orderLine, finished, onAmountChange, followProduct = false, disabled }) => {
	const { t } = useTranslation();
	const { maxAmountOfProductPerProductGroupInCart, minimumAmountOfProduct } = useFlags();
	return (
		<div className="flex flex-no-wrap justify-center items-end">
			{(followProduct || disabled) && (
				<TextField
					className={clsx(classes.numberInput, 'md:ml-36 lg:ml-36 rounded-none w-42 h-32')}
					value={orderLine.qty}
					type="number"
					disabled
				/>
			)}
			{!followProduct && !disabled && (
				<>
					{(maxAmountOfProductPerProductGroupInCart || minimumAmountOfProduct) && (
						<CartAmountField
							className={clsx(classes.numberInput, 'md:ml-36 lg:ml-36 rounded-none w-42 h-32')}
							defaultValue={!followProduct && orderLine.qty}
							value={followProduct && orderLine.qty}
							type="number"
							disabled={finished}
							onBlur={ev => {
								const a = Number.parseInt(ev.currentTarget.value, 10);
								if (!Number.isNaN(a)) {
									onAmountChange(orderLine, a);
								}
							}}
						/>
					)}
					{!maxAmountOfProductPerProductGroupInCart && !minimumAmountOfProduct && (
						<TextField
							className={clsx(classes.numberInput, 'md:ml-36 lg:ml-36 rounded-none w-42 h-32')}
							defaultValue={!followProduct && orderLine.qty}
							value={followProduct && orderLine.qty}
							type="number"
							disabled={finished}
							onBlur={ev => {
								const a = Number.parseInt(ev.currentTarget.value, 10);
								if (!Number.isNaN(a)) {
									onAmountChange(orderLine, a);
								}
							}}
						/>
					)}
				</>
			)}

			<Hidden only={['xs']}>
				<Tooltip
					title={
						followProduct
							? orderLine.allowCustomerToRemove
								? t('REMOVE_PRODUCT_FROM_CART')
								: t('REMOVE_PRODUCT_NOT_ALLOWED')
							: t('REMOVE_PRODUCT_FROM_CART')
					}
				>
					<span>
						<Button
							size="small"
							className="min-w-0"
							onClick={() => onAmountChange(orderLine, 0)}
							disabled={finished || (followProduct && !orderLine.allowCustomerToRemove)}
						>
							<DeleteOutline />
						</Button>
					</span>
				</Tooltip>
			</Hidden>
		</div>
	);
};

export default ProductAmountCell;
