import React from 'react';
import Button from '@mui/material/Button';
import { Skeleton } from '@mui/material';
import { Tooltip, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Add, ArrowForward, Remove } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { sharedTailwindClasses } from '../shared/sharedTailwindClasses';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import { findPricesForProduct } from '@ameroservices-platform/shared/utility/index';
import { useTranslation } from 'react-i18next';

const moneyFormatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});
function ProductAmountCart(props) {
	const navigate = useNavigate();
	const flags = useFlags();
	const { contentElementProps } = props;
	const [product, setProduct] = React.useState(null);
	const [productGroup, setProductGroup] = React.useState(null);
	const [amount, setAmount] = React.useState(0);
	const { t } = useTranslation();

	React.useEffect(() => {
		if (contentElementProps.productUid) {
			const unsubFunc = firebaseService
				.getOrganisationRootDB()
				.collection('products')
				.doc(contentElementProps.productUid)
				.onSnapshot(doc => {
					setProduct({ ...doc.data(), id: doc.id });
				});
			return () => unsubFunc();
		}
		return () => {};
	}, [contentElementProps.productUid]);

	React.useEffect(() => {
		if (product && product.ticketTypeUid) {
			const unsubFunc = firebaseService
				.getOrganisationRootDB()
				.collection('ticketTypes')
				.doc(product.ticketTypeUid)
				.onSnapshot(doc => {
					setProductGroup({ ...doc.data(), id: doc.id });
				});
			return () => unsubFunc();
		}
		return () => {};
	}, [product]);

	const price = React.useMemo(() => {
		if (!product) {
			return null;
		}
		return findPricesForProduct(product, null, null, flags.displayPeriodePrices, amount) / 100;
	}, [product, flags.displayPeriodePrices, amount]);

	function handleAdd() {
		setAmount(oldState => {
			if (
				productGroup &&
				productGroup.maxTickets &&
				productGroup.maxTickets.amount &&
				oldState >= productGroup.maxTickets.amount
			) {
				return oldState;
			}
			return oldState + 1;
		});
	}

	function handleRemove() {
		setAmount(oldState => {
			if (oldState <= 0) {
				return 0;
			}
			return oldState - 1;
		});
	}
	const toolTipTitleAdd = React.useMemo(() => {
		if (
			flags.maxAmountOfProductPerProductGroupInCart &&
			productGroup &&
			productGroup.maxTickets &&
			productGroup.maxTickets.amount &&
			amount >= productGroup.maxTickets.amount
		) {
			return `Du kan maksimalt købe ${productGroup.maxTickets.amount} af dette produkt`;
		}
		return '';
	}, [flags.maxAmountOfProductPerProductGroupInCart, productGroup, amount]);

	const toolTipTitleRemove = React.useMemo(() => {
		if (flags.minimumAmountOfProduct && product && product.minimumAmount) {
			return `Du skal minimum købe ${product.minimumAmount} af dette produkt`;
		}
		return '';
	}, [flags.minimumAmountOfProduct, product]);

	function handleBuy() {
		navigate('/cart', { state: { products: { [product.id]: amount } } });
	}

	return (
		<div
			className={`min-w-320 ${contentElementProps.tailwindClasses} ${sharedTailwindClasses({
				...contentElementProps
			})}`}
		>
			<div className="flex items-center justify-end">
				<div className="flex items-center mr-20">
					<Typography className="mr-10 font-bold whitespace-normal cursor-default">
						{product && (
							<>
								{price % 1 === 0 && <>{price},-</>}
								{price % 1 !== 0 && <>{moneyFormatter.format(price)}</>}
							</>
						)}
						{!product && <Skeleton />}
					</Typography>
					<div className="flex h-56 items-center">
						<Tooltip title={toolTipTitleRemove}>
							<Button
								variant="outlined"
								disabled={!product}
								onClick={handleRemove}
								className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32 p-0 min-w-0 rounded-none"
							>
								<Remove />
							</Button>
						</Tooltip>
						<div className="flex items-center text-14 font-bold px-10">{amount}</div>

						<Tooltip title={toolTipTitleAdd}>
							<Button
								variant="outlined"
								disabled={!product}
								onClick={handleAdd}
								className="w-32 h-32 md:w-20 md:h-20 lg:w-32 lg:h-32  p-0 min-w-0 rounded-none"
							>
								<Add />
							</Button>
						</Tooltip>
					</div>
				</div>
				<div>
					<Button className="w-full" onClick={handleBuy} disabled={!product || amount === 0}>
						{t('BUY_TICKET')}
						<ArrowForward />
					</Button>
				</div>
			</div>
		</div>
	);
}

export default ProductAmountCart;
