import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectEventGroupSummaries,
	selectOrderLines,
	selectSelectedUpsellEvents,
	selectSelectedUpsellProducts,
	setSelectedUpsellEvent,
	setSelectedUpsellProduct,
	setSelectedUpsellProducts
} from '@ameroservices-platform/attraction-frontend/app/store/shared/cartSlice';
import moment from 'moment-timezone';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import getColor from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/shared/getColor';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';

// Can only be used on upsell module in cart
function UpsellModuleTimeslotSelector({ dataFromExecutor, contentElementProps }) {
	const { eventGroup, selectedDate, upsellProducts } = dataFromExecutor;
	const orderLines = useSelector(selectOrderLines);
	const eventGroupSummaries = useSelector(selectEventGroupSummaries);
	const selectedUpsellProducts = useSelector(selectSelectedUpsellProducts);
	const selectedUpsellEvents = useSelector(selectSelectedUpsellEvents);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		root: {
			color: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
			borderColor: `${getColor(contentElementProps.textColor, contentElementProps.customTextColor, mergedTheme)}`,
			backgroundColor: `${getColor(
				contentElementProps.backgroundColor,
				contentElementProps.customBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.textColorHover,
					contentElementProps.customTextColorHover,
					mergedTheme
				)}`,
				borderColor: `${getColor(
					contentElementProps.textColorHover,
					contentElementProps.customTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.backgroundColorHover,
					contentElementProps.customBackgroundColorHover,
					mergedTheme
				)}`
			}
		},
		rootActive: {
			color: `${getColor(
				contentElementProps.activeTextColor,
				contentElementProps.activeCustomTextColor,
				mergedTheme
			)}`,
			borderColor: `${getColor(
				contentElementProps.activeTextColor,
				contentElementProps.activeCustomTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.activeBackgroundColor,
				contentElementProps.activeCustomBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.activeTextColorHover,
					contentElementProps.activeCustomTextColorHover,
					mergedTheme
				)}`,
				borderColor: `${getColor(
					contentElementProps.activeTextColorHover,
					contentElementProps.activeCustomTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.activeBackgroundColorHover,
					contentElementProps.activeCustomBackgroundColorHover,
					mergedTheme
				)}`
			}
		}
	}));

	const classes = useStyles();

	const summaryForSelectedDate = useMemo(() => {
		if (!selectedDate) {
			return null;
		}
		if (!eventGroupSummaries) {
			return null;
		}
		const groupSummaries = eventGroupSummaries[eventGroup?.id];
		if (!groupSummaries) {
			return null;
		}
		const groupSummary = groupSummaries.find(summary => summary.month === selectedDate.format('M-YYYY'));
		if (!groupSummary) {
			return null;
		}
		const times = groupSummary.dates?.[selectedDate.date()]?.times;
		if (!times) {
			return null;
		}
		const mappedTimes = Object.entries(times).map(([time, timeslot]) => {
			return {
				id: time,
				...timeslot,
				start: moment(timeslot.start.toDate()),
				end: moment(timeslot.end.toDate())
			};
		});

		mappedTimes.sort((a, b) => a.start - b.start);

		return mappedTimes;
	}, [eventGroupSummaries, selectedDate, eventGroup?.id]);

	const maximumAmount = useMemo(() => {
		return orderLines.filter(orderLine => orderLine.upsellProducts).reduce((acc, cur) => acc + cur.qty, 0);
	}, [orderLines]);

	function handleSelectEvent(event) {
		const upsellProduct = upsellProducts.length === 1 ? upsellProducts[0] : null;
		if (selectedUpsellEvents?.[eventGroup.id] === event.eventUid) {
			if (upsellProduct) {
				const amount = selectedUpsellProducts?.[eventGroup.id]?.[upsellProduct.id] || 0;
				if (event.available <= amount || amount >= maximumAmount) {
					return;
				}
				dispatch(
					setSelectedUpsellProduct({
						eventGroupUid: eventGroup.id,
						productUid: upsellProduct.id,
						amount: amount + 1
					})
				);
			} else {
				dispatch(setSelectedUpsellEvent({ eventGroupUid: eventGroup.id, eventUid: null }));
			}
		} else {
			dispatch(setSelectedUpsellEvent({ eventGroupUid: eventGroup.id, eventUid: event.eventUid }));
			if (upsellProduct) {
				const amount = selectedUpsellProducts?.[eventGroup.id]?.[upsellProduct.id] || 0;
				if (amount === 0) {
					dispatch(
						setSelectedUpsellProduct({
							eventGroupUid: eventGroup.id,
							productUid: upsellProduct.id,
							amount: 1
						})
					);
				}
			}
		}
	}

	const productAmountSum = useMemo(() => {
		return Object.values(selectedUpsellProducts?.[eventGroup.id] || {}).reduce((acc, cur) => acc + cur, 0);
	}, [selectedUpsellProducts, eventGroup]);

	return (
		<>
			<Grid container spacing={1}>
				{!summaryForSelectedDate?.length && (
					<Grid item xs={12}>
						<Typography className={'text-center'}>{t('NO_TIMES_AVAILABLE')}</Typography>
					</Grid>
				)}
				{summaryForSelectedDate?.map(timeslot => (
					<Grid item xs={6} sm={4} key={`${eventGroup.id}-${timeslot.id}`}>
						<Button
							variant="outlined"
							className={`w-full rounded-none normal-case`}
							disabled={
								timeslot.available <= 0 ||
								moment().isAfter(moment(timeslot.end)) ||
								timeslot.available < productAmountSum
							}
							onClick={() => {
								handleSelectEvent(timeslot);
							}}
							id={`timeslot-${timeslot.eventUid}`}
							classes={{
								root:
									selectedUpsellEvents?.[eventGroup.id] === timeslot.eventUid
										? classes.rootActive
										: classes.root
							}}
						>
							<span className="text-13 font-bold whitespace-no-wrap">
								{timeslot.start.format('HH:mm')} - {timeslot.end.format('HH:mm')}
							</span>
						</Button>
					</Grid>
				))}
			</Grid>
		</>
	);
}

export default UpsellModuleTimeslotSelector;
