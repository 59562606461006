import React, { useEffect, useRef, useState } from 'react';
import { Card, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LoadingButton from '../../../../../../../ui-components/LoadingButton';
import { confirmEmail } from '../../../../../../../store/shared/userSlice';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import { getParameterByName } from '../../../../../../../utilities';
import { logoutUser } from '../../../../../../../auth/store/userSlice';
import SubscriptionStepper from './SubscriptionStepper';
import { useTranslation } from 'react-i18next';

function SubscriptionContentVerification({ queries }) {
	const flags = useFlags();
	const [loading, setLoading] = useState(false);
	const [confirmationLoading, setConfirmationLoading] = useState(true);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const user = useSelector(({ auth }) => auth.user);
	const { t } = useTranslation();

	const calledOnce = useRef(false);

	useEffect(() => {
		const token = getParameterByName('verify', location.search);
		if (!calledOnce.current && location.search && token) {
			firebaseService.onAuthStateChanged(async auth => {
				if (auth) {
					const idTokenResult = await auth.getIdTokenResult();
					if (idTokenResult && idTokenResult.claims.organisationId) {
						calledOnce.current = true;
						await dispatch(confirmEmail(token));
						setConfirmationLoading(false);
					}
				}
			});
		}
	}, [location, dispatch]);

	const signInOnce = useRef(false);

	useEffect(() => {
		if (flags.subscriptionGiftTicket) {
			if (user && user.isAnonymous === true && isLoggedIn && !signInOnce.current) {
				console.log('signing in again...');
				signInOnce.current = true;
				const token = getParameterByName('login', location.search);
				firebaseService
					.getAuth()
					.signInWithCustomToken(token)
					.then(resp => {
						console.log('signed in', { resp });
						setLoading(false);
						let search = queries.t ? `?t=${queries.t}` : `?s=${queries.s}`;
						if (queries.tn) {
							search += `&tn=${queries.tn}`;
						}
						if (queries.cn) {
							search += `&cn=${queries.cn}`;
						}
						if (queries.dc) {
							search += `&dc=${queries.dc}`;
						}
						navigate({
							pathname: location.pathname,
							search
						});
					})
					.catch(error => console.error(error));
			}
		}
	}, [user, isLoggedIn, flags.subscriptionGiftTicket, navigate, location, queries]);

	async function handleDoHere() {
		setLoading(true);
		const token = getParameterByName('login', location.search);
		if (flags.subscriptionGiftTicket) {
			console.log('checking token', token);
			const customerUid = getParameterByName('cus', location.search);
			if (location.search && token) {
				if (user.uid === customerUid) {
					let search = queries.t ? `?t=${queries.t}` : `?s=${queries.s}`;
					if (queries.tn) {
						search += `&tn=${queries.tn}`;
					}
					if (queries.cn) {
						search += `&cn=${queries.cn}`;
					}
					if (queries.dc) {
						search += `&dc=${queries.dc}`;
					}
					navigate({
						pathname: location.pathname,
						search
					});
				} else if (user.isAnonymous === false) {
					setIsLoggedIn(true);
					console.log('logging out...');
					dispatch(logoutUser());
				} else {
					firebaseService
						.getAuth()
						.signInWithCustomToken(token)
						.then(resp => {
							console.log('signed in', { resp });
							setLoading(false);
							let search = queries.t ? `?t=${queries.t}` : `?s=${queries.s}`;
							if (queries.tn) {
								search += `&tn=${queries.tn}`;
							}
							if (queries.cn) {
								search += `&cn=${queries.cn}`;
							}
							if (queries.dc) {
								search += `&dc=${queries.dc}`;
							}
							navigate({
								pathname: location.pathname,
								search
							});
						})
						.catch(error => console.error(error));
				}
			}
		} else if (!flags.subscriptionGiftTicket && location.search && token) {
			if (user.isAnonymous === false) {
				dispatch(logoutUser());
			}
			firebaseService
				.getAuth()
				.signInWithCustomToken(token)
				.then(resp => {
					setLoading(false);
					navigate({
						pathname: location.pathname,
						search: queries.t ? `?t=${queries.t}` : `?s=${queries.s}`
					});
				})
				.catch(error => console.error(error));
		}
	}

	return (
		<div className="max-w-screen flex flex-col">
			<div className="w-md mx-auto py-16">
				<SubscriptionStepper activeStep={1} />
			</div>
			<Card className={`max-w-md mx-auto mb-48`}>
				<CardContent className="flex flex-col items-center justify-center p-32">
					<div className="min-w-full flex flex-col justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
						{confirmationLoading ? (
							<>
								<div className="relative mx-8">
									<CircularProgress className="text-72" color="primary" />
								</div>

								<div className="mx-8">
									<Typography variant="h6" className="mb-8">
										{t('EMAIL_CONFIRMNIG')}
									</Typography>
									<Typography color="textSecondary">{t('EMAIL_BEING_CONFIRMED')}</Typography>
								</div>
							</>
						) : (
							<>
								<div className="relative mx-8">
									<Icon className="text-72" color="primary">
										check
									</Icon>
								</div>

								<div className="mx-8">
									<Typography variant="h6" className="mb-8">
										{t('EMAIL_CONFIRMED')}
									</Typography>
									<Typography color="textSecondary">{t('EMAIL_IS_CONFIRMED')}</Typography>
								</div>
							</>
						)}
					</div>
					<div className="flex flex-col justify-center w-full">
						<div className="mx-auto mt-16">
							<LoadingButton
								variant="contained"
								color="primary"
								className="w-224"
								onClick={handleDoHere}
								loading={loading}
								disabled={confirmationLoading}
							>
								{t('CONTINUE_CREATING_SUBCRIPTION')}
							</LoadingButton>
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	);
}

export default SubscriptionContentVerification;
