import FuseScrollbars from '@ameroservices-platform/attraction-frontend/fuse/core/FuseScrollbars';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { NavigateNext } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { Checkbox, FormControlLabel, Paper, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Alert, Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	addProductsToOrder,
	requestOrderDraft,
	selectAllOrderLinesLoading,
	selectCustomerId,
	selectFinished,
	selectIdsLoading,
	selectOrder,
	selectOrderDraft,
	selectOrderDraftLoading,
	selectOrderId,
	selectOrderLines,
	selectOrderLoading,
	selectMaxError,
	submitOrder,
	updateOrderDraft,
	verifyOrder,
	setMaxError,
	cloneOrder,
	applyCode
} from '../../../../../../../store/shared/cartSlice';
import TextFieldFormsyAdapter from '@ameroservices-platform/shared/ui-components/fields/TextFieldFormsyAdapter';
// eslint-disable-next-line import/no-cycle
import CartTermsDialog from '../../../../types/cart/cart/CartTermsDialog';
import {
	selectCountries,
	selectGoogleTagManager,
	selectModules,
	selectRequiredOrderFields,
	selectPostalCodes,
	selectTheme,
	mergeThemes
} from '../../../../../../../store/shared/frontendSlice';
import CartContentOverviewWithFollowProduct from './CartContentOverviewWithFollowProduct';
import CartContentOverviewOld from './CartContentOverviewOld';
import {
	tagManagerBeginCheckOut,
	tagManagerViewCart
} from '../../../../../../../services/googleTagManagerService/googleTagManagerService';
import requiredContactInfoOnOrder from '../../../../../../../ui-components/states/requiredContactInfoOnOrder';
import RedeemCode from './RedeemCode';
import { useForm } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import getColor from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/shared/getColor';
import clsx from 'clsx';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';
import CartInformation from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/CartInformation';
import productType from '@ameroservices-platform/shared/enums/productType';
import CartSubscriptionTermsDialog from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/cart/CartSubscriptionTermsDialog';

const moneyFormatter = new Intl.NumberFormat('da-DK', {
	style: 'currency',
	currency: 'DKK',
	minimumFractionDigits: 2
});

function Cart(props) {
	const { contentElementProps } = props;
	const flags = useFlags();
	const dispatch = useDispatch();
	const location = useLocation();
	const locationState = location.state || {};
	const modules = useSelector(selectModules);
	const googleTagManager = useSelector(selectGoogleTagManager);
	const orderId = useSelector(selectOrderId);
	const customerId = useSelector(selectCustomerId);
	const orderLoading = useSelector(selectOrderLoading);
	const idsLoading = useSelector(selectIdsLoading);
	const orderDraftLoading = useSelector(selectOrderDraftLoading);
	const allOrderLinesLoading = useSelector(selectAllOrderLinesLoading);
	const order = useSelector(selectOrder);
	const orderDraft = useSelector(selectOrderDraft);
	const orderLines = useSelector(selectOrderLines);
	const finished = useSelector(selectFinished);
	const maxError = useSelector(selectMaxError);
	const countries = useSelector(selectCountries);
	const postalCodes = useSelector(selectPostalCodes);
	const user = useSelector(({ auth }) => auth.user);
	const {
		watch,
		setValue: setInForm,
		control,
		formState,
		reset
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			firstname: '',
			lastname: '',
			email: '',
			phone: '',
			postalCode: '',
			city: '',
			address: ''
		}
	});
	const form = watch();
	const { isValid: isFormValid, errors } = formState;
	const [showInformationPage, setShowInformationPage] = useState(false);
	const [enableAutomaticRenewal, setEnableAutomaticRenewal] = useState(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [acceptedSubscriptionTerms, setAcceptedSubscriptionTerms] = useState(false);
	const [newsletterOptIn, setNewsletterOptIn] = useState(false);
	const [countryCode, setCountryCode] = useState(null);
	const formRef = useRef(null);
	const { t } = useTranslation();
	const requiredOrderFields = useSelector(selectRequiredOrderFields);
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const cloneCustomerUid = searchParams.get('c');
		const cloneOrderUid = searchParams.get('o');
		const isInfoTab = searchParams.get('n');
		if (cloneCustomerUid && cloneOrderUid) {
			cloneOrder(cloneCustomerUid, cloneOrderUid).finally(() => {
				setSearchParams({});
			});
			if (isInfoTab) {
				setShowInformationPage(true);
			}
		}
	}, [searchParams]);

	const useStyles = makeStyles(_theme => ({
		termsButton: {
			color:
				contentElementProps.termsButtonBackgroundColor && contentElementProps.termsButtonCustomBackgroundColor
					? getColor(
							contentElementProps.termsButtonBackgroundColor,
							contentElementProps.termsButtonCustomBackgroundColor,
							mergedTheme
					  )
					: undefined
		}
	}));
	const classes = useStyles();

	const requiredFields = useMemo(() => {
		if (flags.requiredFieldsOnOrderContactInfoFrontend && requiredOrderFields) {
			if (requiredOrderFields === requiredContactInfoOnOrder.ALL_SHOWN_FIRST_LAST_EMAIL_REQUIRED) {
				return ['firstname', 'lastname', 'email'];
			}
			if (requiredOrderFields === requiredContactInfoOnOrder.ALL_SHOWN_FIRST_LAST_EMAIL_POSTAL_REQUIRED) {
				return ['firstname', 'lastname', 'email', 'postalCode'];
			}
		}
		return ['firstname', 'lastname', 'email', 'phone', 'postalCode', 'city', 'address', 'countryCode'];
	}, [flags.requiredFieldsOnOrderContactInfoFrontend, requiredOrderFields]);

	const navigate = useNavigate();

	useEffect(() => {
		if (
			order &&
			order.state === 'pendingPayment' &&
			order.payments &&
			order.payments.length > 0 &&
			order.payments[0].prepData &&
			order.payments[0].prepData
		) {
			if (order.payments[0].prepData.state === 'pending' && order.payments[0].prepData.link) {
				window.location.href = order.payments[0].prepData.link;
			} else if (order.payments[0].state === 'processed') {
				window.location.href = `${window.location.pathname}/complete/${order.orderUid}`;
			}
		}
		return () => {};
	}, [order]);

	useEffect(() => {
		if (customerId && orderId) {
			dispatch(requestOrderDraft(customerId, orderId));
		}
	}, [customerId, orderId, dispatch]);

	useEffect(() => {
		if (orderDraft && orderDraft.contactInfo && orderDraft.contactInfo.billing) {
			const ci = orderDraft.contactInfo.billing;
			reset(ci);
			if (flags.salesToOtherCountries && orderDraft.contactInfo.billing.countryCode) {
				setCountryCode(orderDraft.contactInfo.billing.countryCode);
			}
			setAcceptedTerms(orderDraft.acceptedTerms);
			setEnableAutomaticRenewal(orderDraft.enableAutomaticRenewal ?? false);
			setNewsletterOptIn(orderDraft.newsletterOptIn ? orderDraft.newsletterOptIn : false);
		}
	}, [orderDraft, reset, flags.salesToOtherCountries]);

	useEffect(() => {
		if (!idsLoading && orderId && customerId) {
			dispatch(verifyOrder());
		}
	}, [dispatch, idsLoading, orderId, customerId]);

	useEffect(() => {
		if (!orderId || !customerId) {
			return;
		}
		if (locationState.products) {
			const lines = Object.keys(locationState.products)
				.map(productUid => ({
					productUid,
					qty: locationState.products[productUid],
					eventUid: locationState.event,
					locationUid: locationState.location
				}))
				.filter(p => p.qty > 0);
			console.log('adding new products...', lines);
			dispatch(addProductsToOrder(lines));
			navigate(location.pathname, { replace: true });
		}
		if (flags.moveCartInformationToNewPage && locationState.subscriptions) {
			const subscriptionsToAdd = Object.keys(locationState.subscriptions)
				.map(subscriptionTypeUid => ({
					qty: locationState.subscriptions[subscriptionTypeUid],
					subscriptionTypeUid
				}))
				.filter(p => p.qty > 0);
			console.log('adding new subscriptions...', subscriptionsToAdd);
			dispatch(addProductsToOrder(subscriptionsToAdd));
			navigate(location.pathname, { replace: true });
		}
		if (locationState.gifts) {
			console.log('adding new gift...', locationState.gifts);
			dispatch(
				addProductsToOrder(
					locationState.gifts.map(gift => ({
						qty: gift.qty,
						subscriptionTypeUid: gift.subscriptionTypeUid,
						gift: {
							ticketNumber: gift.ticketNumber,
							controlNumber: gift.controlNumber
						}
					}))
				)
			);
			navigate(location.pathname, { replace: true });
		}
	}, [locationState, orderId, customerId, dispatch, navigate, flags.moveCartInformationToNewPage]);

	useEffect(() => {
		if (flags.printDiscountCodeOnPos && searchParams.has('st') && searchParams.has('dc')) {
			dispatch(addProductsToOrder([{ subscriptionTypeUid: searchParams.get('st'), qty: 1 }]));
			dispatch(applyCode(searchParams.get('dc')));
			navigate(location.pathname, { replace: true });
		}
	}, [flags.printDiscountCodeOnPos, searchParams, dispatch, location.pathname]);

	useEffect(() => {
		const amount = orderLines.reduce((accOrderLines, curOrderLine) => accOrderLines + curOrderLine.qty, 0);
		if (amount > 90) {
			dispatch(setMaxError(true));
		} else {
			dispatch(setMaxError(false));
		}
	}, [dispatch, orderLines]);

	function handleTermAccept(event) {
		setAcceptedTerms(event.target.checked);
		dispatch(updateOrderDraft(customerId, orderId, { acceptedTerms: event.target.checked }));
	}

	function handleSubscriptionTermAccept(event) {
		setAcceptedSubscriptionTerms(event.target.checked);
		dispatch(updateOrderDraft(customerId, orderId, { acceptedSubscriptionTerms: event.target.checked }));
	}

	function handleAutomaticRenewalClick(event) {
		setEnableAutomaticRenewal(event.target.checked);
		dispatch(updateOrderDraft(customerId, orderId, { enableAutomaticRenewal: event.target.checked }));
	}

	function handleNewsletterOptIn(event) {
		setNewsletterOptIn(event.target.checked);
		dispatch(updateOrderDraft(customerId, orderId, { newsletterOptIn: event.target.checked }));
	}

	async function goToPayment() {
		if (flags.moveCartInformationToNewPage) {
			await dispatch(verifyOrder());
			setShowInformationPage(true);
			return;
		}
		if (googleTagManager) {
			tagManagerBeginCheckOut(orderLines);
		}
		dispatch(submitOrder());
	}

	const [open, setOpen] = React.useState(false);
	const [openSubscriptionTerms, setOpenSubscriptionTerms] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOnBlurOnPostalCodeInput = e => {
		const { value } = e.target;
		if (postalCodes[value] && postalCodes[value].name) {
			setInForm('city', postalCodes[value].name);
		}
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	const runTagManagerViewCartOnce = useRef(false);

	useEffect(() => {
		if (!runTagManagerViewCartOnce.current && googleTagManager && orderLines.length > 0) {
			runTagManagerViewCartOnce.current = true;
			tagManagerViewCart(orderLines);
		}
	}, [googleTagManager, orderLines]);

	const isUserNotLoggedIn = user && (user.isAnonymous || !user.role || user.role.length === 0);

	const confirmEmailField = React.useMemo(
		() => flags.retypeEmailInCart && contentElementProps && contentElementProps.confirmEmailField,
		[flags.retypeEmailInCart, contentElementProps]
	);

	useEffect(() => {
		if (!customerId || !orderId) {
			return;
		}
		const d = {};
		Object.keys(form).forEach(key => {
			if (form[key]) {
				d[`contactInfo.billing.${key}`] = form[key];
			}
		});
		dispatch(updateOrderDraft(customerId, orderId, d));
	}, [form, customerId, orderId]);

	const containingSubscription = useMemo(() => {
		return (
			flags.moveCartInformationToNewPage &&
			orderLines?.some(orderLine => orderLine.productType === productType.SUBSCRIPTION)
		);
	}, [orderLines, flags.moveCartInformationToNewPage]);

	if (flags.moveCartInformationToNewPage && showInformationPage) {
		return (
			<CartInformation contentElementProps={contentElementProps} onGoBack={() => setShowInformationPage(false)} />
		);
	}

	return (
		<div className="w-full mx-auto pt-40">
			<div className="max-w-screen flex flex-col">
				<FuseScrollbars className="flex-grow overflow-x-auto">
					<Hidden only={['lg', 'xl']}>
						<Link to="/">
							<div className="flex justify-center items-start py-16">
								<img
									className="w-2/6 max-h-full"
									src={contentElementProps.headerMobileImage}
									alt="Billede"
								/>
							</div>
						</Link>
					</Hidden>
					<div
						className={
							orderLoading || allOrderLinesLoading || idsLoading || orderDraftLoading ? 'opacity-75' : ''
						}
					>
						{maxError && (
							<Alert severity="error" className="mb-20">
								<strong>{t('MAX_90_HEADER')}</strong>
								<br />
								{t('MAX_90')}
							</Alert>
						)}
						{flags.followProduct ? (
							<CartContentOverviewWithFollowProduct contentElementProps={contentElementProps} />
						) : (
							<CartContentOverviewOld contentElementProps={contentElementProps} />
						)}
					</div>
					{!flags.moveCartInformationToNewPage && (
						<Paper elevation={3} className="mb-48 mx-12 md:mx-0">
							<div className="p-24">
								<form className="flex flex-col">
									<div className="flex">
										<Grid container spacing={0} className="sm:max-w-screen">
											<Grid item sm={12} sl={12}>
												<Typography variant="h6" className="">
													{t('CONTACT_DETAILS')}
												</Typography>
											</Grid>
											<Grid item xs={6} sm={6}>
												<TextFieldFormsyAdapter
													control={control}
													id="firstname"
													className="mt-8 mb-16 pr-6"
													type="text"
													name="firstname"
													label={t('FIRST_NAME')}
													value={form.firstname}
													validations={{
														minLength: 2
													}}
													validationErrors={{
														minLength: t('FIRST_NAME_MIN_2_CHAR')
													}}
													variant="standard"
													fullWidth
													required={
														!flags.requiredFieldsOnOrderContactInfoFrontend ||
														requiredFields.includes('firstname')
													}
												/>
											</Grid>
											<Grid item xs={6} sm={6}>
												<TextFieldFormsyAdapter
													control={control}
													id="lastname"
													className="mt-8 mb-16"
													type="text"
													name="lastname"
													label={t('LAST_NAME')}
													value={form.lastname}
													validations={{
														minLength: 2
													}}
													validationErrors={{
														minLength: t('LAST_NAME_MIN_2_CHAR')
													}}
													variant="standard"
													fullWidth
													required={
														!flags.requiredFieldsOnOrderContactInfoFrontend ||
														requiredFields.includes('lastname')
													}
												/>
											</Grid>
											<Grid item xs={12} sm={12}>
												<TextFieldFormsyAdapter
													control={control}
													id="address"
													className="mt-8 mb-16"
													type="text"
													name="address"
													label={t('ADDRESS')}
													value={form.address}
													validations={{
														minLength: 2
													}}
													validationErrors={{
														minLength: t('ADDRESS_MIN_2_CHAR')
													}}
													variant="standard"
													fullWidth
													required={
														!flags.requiredFieldsOnOrderContactInfoFrontend ||
														requiredFields.includes('address')
													}
												/>
											</Grid>

											<Grid item xs={6} sm={6}>
												<TextFieldFormsyAdapter
													control={control}
													id="postalCode"
													className="mt-8 mb-16 pr-6"
													type="text"
													name="postalCode"
													label={t('POSTAL_CODE')}
													value={form.postalCode}
													onBlur={handleOnBlurOnPostalCodeInput}
													validations={
														!flags.salesToOtherCountries || countryCode === 'DK'
															? {
																	minLength: 3,
																	maxLength: 4
															  }
															: {
																	minLength: 3,
																	maxLength: 8
															  }
													}
													validationErrors={
														!flags.salesToOtherCountries || countryCode === 'DK'
															? {
																	minLength: t('POSTAL_CODE_LENGTH'),
																	maxLength: t('POSTAL_CODE_LENGTH')
															  }
															: {
																	minLength: t('POSTAL_CODE_LENGTH_INT'),
																	maxLength: t('POSTAL_CODE_LENGTH_INT')
															  }
													}
													variant="standard"
													fullWidth
													required={
														!flags.requiredFieldsOnOrderContactInfoFrontend ||
														requiredFields.includes('postalCode')
													}
												/>
											</Grid>
											<Grid item xs={6} sm={6}>
												<TextFieldFormsyAdapter
													control={control}
													id="city"
													className="mt-8 mb-16"
													type="text"
													name="city"
													label={t('CITY')}
													value={form.city}
													validations={{
														minLength: 1
													}}
													validationErrors={{
														minLength: t('CITY_MIN_2_CHAR')
													}}
													variant="standard"
													fullWidth
													required={
														!flags.requiredFieldsOnOrderContactInfoFrontend ||
														requiredFields.includes('city')
													}
												/>
											</Grid>
											<Grid item xs={6} sm={6}>
												<TextFieldFormsyAdapter
													control={control}
													id="email"
													className="mt-8 mb-16 pr-6"
													type="text"
													name="email"
													label="Email"
													value={form.email}
													validations={{
														isEmail: true
													}}
													validationErrors={{
														isEmail: t('EMAIL_ERROR')
													}}
													variant="standard"
													fullWidth
													required={
														!flags.requiredFieldsOnOrderContactInfoFrontend ||
														requiredFields.includes('email')
													}
													enableTrim
												/>
											</Grid>
											{confirmEmailField && (
												<Grid item xs={6} sm={6}>
													<TextFieldFormsyAdapter
														control={control}
														id="confirmEmail"
														className="mt-8 mb-16 pr-6"
														type="text"
														name="confirmEmail"
														label={t('CONFIRM_EMAIL')}
														value={form.confirmEmail}
														validations={{
															isEmail: true,
															equalsField: 'email'
														}}
														validationErrors={{
															isEmail: t('EMAIL_ERROR'),
															equalsField: t('CONFIRM_EMAIL_ERROR')
														}}
														variant="standard"
														fullWidth
														required={
															!flags.requiredFieldsOnOrderContactInfoFrontend ||
															requiredFields.includes('email')
														}
														enableTrim
													/>
												</Grid>
											)}
											<Grid item xs={confirmEmailField ? 12 : 6} sm={confirmEmailField ? 12 : 6}>
												<TextFieldFormsyAdapter
													control={control}
													id="phone"
													className="mt-8 mb-16"
													type="text"
													name="phone"
													label={t('PHONE_NR')}
													value={form.phone}
													validations={
														!flags.salesToOtherCountries || countryCode === 'DK'
															? {
																	minLength: 8,
																	maxLength: 11
															  }
															: {
																	minLength: 6,
																	maxLength: 20
															  }
													}
													validationErrors={
														!flags.salesToOtherCountries || countryCode === 'DK'
															? {
																	minLength: t('PHONE_NR_MIN_2_CHAR'),
																	maxLength: t('PHONE_NR_MIN_2_CHAR')
															  }
															: {
																	minLength: t('PHONE_NR_MIN_2_CHAR_INT'),
																	maxLength: t('PHONE_NR_MIN_2_CHAR_INT')
															  }
													}
													variant="standard"
													fullWidth
													required={
														!flags.requiredFieldsOnOrderContactInfoFrontend ||
														requiredFields.includes('phone')
													}
												/>
											</Grid>
											{flags.salesToOtherCountries && (
												<Grid item sm={12}>
													<Autocomplete
														className="mt-8 mb-16 w-full"
														fullWidth
														id="countryCode"
														name="countryCode"
														options={Object.keys(countries)}
														value={countryCode}
														onChange={async (event, newValue) => {
															setCountryCode(newValue);
															dispatch(
																updateOrderDraft(customerId, orderId, {
																	[`contactInfo.billing.countryCode`]: newValue
																})
															);
														}}
														getOptionLabel={option => {
															if (!countries[option]) {
																return '-';
															}
															const { name } = countries[option];
															return name || '-';
														}}
														renderInput={params => (
															<TextField
																{...params}
																label={t('COUNTRY')}
																variant={'standard'}
															/>
														)}
														openOnFocus
														required={
															!flags.requiredFieldsOnOrderContactInfoFrontend ||
															requiredFields.includes('countryCode')
														}
													/>
												</Grid>
											)}

											{flags.emailSubscription &&
												flags.showNewsletterOptInCart &&
												modules &&
												(modules.mailchimp || modules.newsletterSignup) &&
												isUserNotLoggedIn && (
													<FormControlLabel
														className="whitespace-no-wrap"
														control={
															<Checkbox
																name="newsletterOptIn"
																id="newsletterOptIn"
																checked={newsletterOptIn}
																onChange={handleNewsletterOptIn}
																color="primary"
															/>
														}
														label={
															<Typography className="flex flex-col sm:flex-row items-start sm:items-center">
																{flags.newsletterSignupCustomText && (
																	<>
																		{contentElementProps.newsletterSignupText ||
																			t('NEWSLETTER_SIGNUP')}
																	</>
																)}
																{!flags.newsletterSignupCustomText && (
																	<>{t('NEWSLETTER_SIGNUP')}</>
																)}
															</Typography>
														}
													/>
												)}
										</Grid>
										<Hidden only={['xs', 'sm']}>
											<Grid container className="pl-24">
												<Grid item sm={12} xl={12} className="m-auto">
													<img
														src={contentElementProps.contactInformationImage}
														alt="Billede"
													/>
												</Grid>
											</Grid>
										</Hidden>
									</div>
								</form>
							</div>
						</Paper>
					)}
					{flags.orderNotesOnAttractionOrder && contentElementProps.showOrderNoteField === true && (
						<Paper elevation={3} className="mb-48 mx-12 md:mx-0">
							<div className="p-24">
								<Formsy
									ref={formRef}
									className="flex flex-col"
									disabled={finished}
									onChange={data => {
										const d = {};
										Object.keys(data).forEach(key => {
											if (data[key]) {
												d[`${key}`] = data[key];
											}
										});
										dispatch(updateOrderDraft(customerId, orderId, d));
									}}
								>
									<Typography variant="h6" className="">
										{t('ORDER_NOTE_LABEL')}
									</Typography>
									<TextFieldFormsyAdapter
										control={control}
										id="orderNote"
										className="mt-8 mb-16"
										type="text"
										name="orderNote"
										label={t('ORDER_NOTE_PLACEHOLDER')}
										value={form.orderNote}
										variant="standard"
										fullWidth
									/>
								</Formsy>
							</div>
						</Paper>
					)}

					{((flags.discountCodes && modules && modules.discountCodes) ||
						(flags.integrationToFlexVoucher && modules && modules.giftCards)) && <RedeemCode />}
					<Paper elevation={3} className="mb-48 mx-12 md:mx-0 pb-10">
						<Typography variant="h6" className="p-24">
							{t('CONFIRM')}
						</Typography>
						<div className="w-full flex flex-col md:flex-row">
							<div className={'w-full flex flex-col'}>
								{flags.moveCartInformationToNewPage &&
									containingSubscription &&
									!flags.disableAutomaticRenewalButtonOnFrontend &&
									flags.pensoPaySubscription &&
									!(flags.subscriptionGiftTicket && order?.totals?.total === 0) &&
									modules &&
									modules.subscriptions &&
									modules.subscriptions.automaticRenewal && (
										<FormControlLabel
											className="pl-24 whitespace-no-wrap"
											control={
												<Checkbox
													name="enableAutomaticRenewal"
													checked={enableAutomaticRenewal}
													onChange={handleAutomaticRenewalClick}
													color="primary"
													disabled={finished}
												/>
											}
											label={
												<Typography className="flex flex-col sm:flex-row items-start sm:items-center">
													{t('CART_AUTO_RENEWAL_ACCEPT')}
												</Typography>
											}
										/>
									)}
								{containingSubscription && flags.pensoPaySubscription && enableAutomaticRenewal && (
									<FormControlLabel
										className="pl-24 whitespace-no-wrap"
										control={
											<Checkbox
												name="acceptedSubscriptionTerms"
												checked={acceptedSubscriptionTerms}
												onChange={handleSubscriptionTermAccept}
												color="primary"
											/>
										}
										label={
											<Typography className="flex flex-col sm:flex-row items-start sm:items-center whitespace-nowrap">
												{t('I_AM_CONFIRMING')}
												<Button
													className={clsx(
														'normal-case p-0 xs:ml-0 sm:ml-4 underline',
														flags.addedColorOptionsForButtonsInCart && classes.termsButton
													)}
													onClick={() => setOpenSubscriptionTerms(true)}
												>
													{t('CART_TERMS_OF_CONDITIONS_SUBSCRIPTION')}
												</Button>
											</Typography>
										}
									/>
								)}
								<FormControlLabel
									className="pl-24 whitespace-no-wrap"
									control={
										<Checkbox
											name="acceptedTerms"
											checked={acceptedTerms}
											onChange={handleTermAccept}
											color="primary"
										/>
									}
									label={
										<Typography className="flex flex-col sm:flex-row items-start sm:items-center whitespace-nowrap">
											{t('I_AM_CONFIRMING')}
											<Button
												className={clsx(
													'normal-case p-0 xs:ml-0 sm:ml-4 underline',
													flags.addedColorOptionsForButtonsInCart && classes.termsButton
												)}
												onClick={handleClickOpen}
											>
												{t('TERMS_OF_CONDITIONS')}
											</Button>
										</Typography>
									}
								/>
							</div>

							<div className="w-full flex flex-col justify-start md:justify-end items-start md:items-end">
								<div className="px-24">
									<Typography className="font-bold text-24" color="textSecondary">
										{t('TOTAL')}{' '}
										{moneyFormatter.format(order && order.totals ? order.totals.total / 100 : 0)}
									</Typography>
								</div>
							</div>
						</div>
						<div className="flex flex-col md:flex-row justify-end pt-24 pb-6 px-16">
							<LoadingButton
								endIcon={<NavigateNext />}
								variant="contained"
								color="primary"
								className={'w-full md:w-256'}
								disabled={
									!isFormValid ||
									!order ||
									!orderLines ||
									orderLines.length <= 0 ||
									!acceptedTerms ||
									allOrderLinesLoading ||
									orderLoading ||
									orderDraftLoading ||
									maxError ||
									(!flags.moveCartInformationToNewPage &&
										flags.salesToOtherCountries &&
										(!flags.requiredFieldsOnOrderContactInfoFrontend ||
											requiredFields.includes('countryCode')) &&
										!countryCode) ||
									(containingSubscription &&
										flags.pensoPaySubscription &&
										enableAutomaticRenewal &&
										!acceptedSubscriptionTerms)
								}
								onClick={goToPayment}
								loading={finished}
							>
								{flags.moveCartInformationToNewPage ? t('CART_CONTINUE') : t('GO_TO_PAYMENT')}
							</LoadingButton>
						</div>
					</Paper>
				</FuseScrollbars>

				<CartTermsDialog open={open} handleClose={handleClose} />
				<CartSubscriptionTermsDialog
					open={openSubscriptionTerms}
					onClose={() => setOpenSubscriptionTerms(false)}
				/>
			</div>
		</div>
	);
}

export default Cart;
