import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { DeleteOutline } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import Hidden from '@mui/material/Hidden';
import { Paper } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Icon from '@mui/material/Icon';
import { selectMainTheme } from '../../../../../../../store/fuse/settingsSlice';
import { mergeThemes, selectTheme } from '../../../../../../../store/shared/frontendSlice';
import getColor from '../../shared/getColor';
import {
	deleteOrderLine,
	getEventsByUids,
	selectAllOrderLinesLoading,
	selectEvents,
	selectFinished,
	selectIdsLoading,
	selectOrder,
	selectOrderDraftLoading,
	selectOrderLines,
	selectOrderLinesLoading,
	selectOrderLoading,
	selectTotalsLoading,
	updateAmountOnOrderLine
} from '../../../../../../../store/shared/cartSlice';
import followProductVisualView from '../../../../../../../ui-components/states/followProductVisualView';
import ConfirmationDialog from '../../../../../../../ui-components/ConfirmationDialog';
import SpinnerLoading from '../../../../../../../ui-components/SpinnerLoading';
import CartAmountField from '../../../../types/cart/cart/CartAmountField';
import { getLocaleFromData } from '../../../../../../../utilities';
import ProductAmountCell from './ProductAmountCell';
import ProductTotalCell from './ProductTotalCell';
import ProductNameCell from './ProductNameCell';
import discountCodeDiscountCalculateType from './discountCodeDiscountCalculateType';
import UpsellModule from './UpsellModule';
import _ from 'lodash';
import productType from '@ameroservices-platform/shared/enums/productType';
import { getSubscriptionTypeCurrentEvent } from '@ameroservices-platform/attraction-frontend/app/main/apps/content/types/subscription/SubscriptionFirestore';

function getEventTime(s, e) {
	const start = moment(s);
	const end = moment(e);
	if (start.clone().startOf('day').isSame(end.clone().startOf('day'))) {
		return `${start.format('dddd DD-MM-YYYY')}, ${start.format('HH:mm')} - ${end.format('HH:mm')}`;
	}
	return `${start.format('dddd DD-MM-YYYY, HH:mm')} - ${end.format('dddd DD-MM-YYYY, HH:mm')}`;
}

// ******************************************************************************************************************
// When making changes, consider if changes should also be made in CartContentOverviewOld
// ******************************************************************************************************************

function CartContentOverview(props) {
	const {
		maxAmountOfProductPerProductGroupInCart,
		minimumAmountOfProduct,
		multiLanguageFunctions,
		cartMobileLayout,
		discountCodes,
		optionToHideEventPeriodInCart,
		upsellModule,
		upsellModuleWithEvents,
		addedColorOptionsForButtonsInCart,
		changeSortingForFollowProducts,
		moveCartInformationToNewPage
	} = useFlags();
	const { contentElementProps } = props;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);

	const useStyles = makeStyles(_theme => ({
		numberInput: {
			'& div': {
				'&:before': {
					borderBottom: 'none'
				},
				'&:after': {
					borderBottom: 'none'
				},
				'&:hover': {
					'&:before': {
						borderBottom: 'none !important'
					},
					'&:after': {
						borderBottom: 'none !important'
					}
				}
			},
			'& input::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
				margin: 0
			},
			'& input': {
				width: '26px',
				color: `${getColor(
					contentElementProps.quantityNumberColor,
					contentElementProps.quantityNumberCustomColor,
					mergedTheme
				)}`,
				backgroundColor: theme.palette.secondary.light,
				padding: '10px',
				zIndex: '100',
				boxShadow: 'none',
				textAlign: 'center'
			}
		},
		buttonLeft: {
			borderBottomRightRadius: '0',
			borderTopRightRadius: '0',
			boxShadow: 'none',
			minWidth: 'auto',
			backgroundColor: theme.palette.secondary.light
		},
		buttonRight: {
			borderBottomLeftRadius: '0',
			borderTopLeftRadius: '0',
			boxShadow: 'none',
			minWidth: 'auto',
			backgroundColor: theme.palette.secondary.light
		},
		spinner: {
			color: theme.palette.primary.main
		},
		upsellButton: {
			color: `${getColor(
				contentElementProps.upsellButtonTextColor,
				contentElementProps.upsellButtonCustomTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.upsellButtonBackgroundColor,
				contentElementProps.upsellButtonCustomBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.upsellButtonTextColorHover,
					contentElementProps.upsellButtonCustomTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.upsellButtonBackgroundColorHover,
					contentElementProps.upsellButtonCustomBackgroundColorHover,
					mergedTheme
				)}`
			}
		}
	}));

	const classes = useStyles();
	const moneyFormatter = new Intl.NumberFormat('da-DK', {
		style: 'currency',
		currency: 'DKK',
		minimumFractionDigits: 2
	});
	const allOrderLinesLoading = useSelector(selectAllOrderLinesLoading);
	const orderLoading = useSelector(selectOrderLoading);
	const order = useSelector(selectOrder);
	const orderLinesRaw = useSelector(selectOrderLines);
	const events = useSelector(selectEvents);
	const idsLoading = useSelector(selectIdsLoading);
	const orderDraftLoading = useSelector(selectOrderDraftLoading);
	const orderLinesLoading = useSelector(selectOrderLinesLoading);
	const totalsLoading = useSelector(selectTotalsLoading);
	const finished = useSelector(selectFinished);
	const [confirmDeleteId, setConfirmDeleteId] = useState(null);
	const [disabled] = useState(props.disabled || false);
	const [totals, setTotals] = useState(null);
	const [orderLines, setOrderLines] = useState([]);
	const [upsellModal, setUpsellModal] = useState(false);
	const [subscriptionPeriods, setSubscriptionPeriods] = useState({});

	useEffect(() => {
		setOrderLines(
			orderLinesRaw.map(ol => {
				const followProducts = orderLinesRaw.filter(
					followProductOl =>
						followProductOl.followProductForOrderLines &&
						followProductOl.followProductForOrderLines.includes(ol.id) &&
						followProductOl.visualRelation === followProductVisualView.AS_PRODUCT
				);
				let discountCodeProducts = [];
				if (discountCodes) {
					discountCodeProducts = orderLinesRaw.filter(
						orderLine =>
							orderLine.discountCode &&
							orderLine.discountCode.discountCalculateType ===
								discountCodeDiscountCalculateType.PER_PRODUCT &&
							orderLine.forOrderLines &&
							orderLine.forOrderLines.includes(ol.id)
					);
				}
				return { ...ol, followProducts, discountCodeProducts };
			})
		);
	}, [orderLinesRaw, discountCodes]);

	useEffect(() => {
		if (!moveCartInformationToNewPage) {
			return;
		}
		const subscriptionOrderLines = orderLinesRaw?.filter(
			orderLine => orderLine.productType === productType.SUBSCRIPTION && orderLine.subscriptionTypeUid
		);
		const subscriptionTypeUids = subscriptionOrderLines.map(orderLine => orderLine.subscriptionTypeUid);
		const uniqueSubscriptionTypeUids = subscriptionTypeUids.filter(
			(value, index, self) => self.indexOf(value) === index
		);
		Promise.all(
			uniqueSubscriptionTypeUids.map(subscriptionTypeUid =>
				getSubscriptionTypeCurrentEvent(subscriptionTypeUid).then(event => {
					if (event) {
						setSubscriptionPeriods(oldState => ({
							...oldState,
							[subscriptionTypeUid]: event
						}));
					} else {
						setSubscriptionPeriods(oldState => ({
							...oldState,
							[subscriptionTypeUid]: null
						}));
					}
				})
			)
		);
	}, [orderLinesRaw, moveCartInformationToNewPage]);

	function onAmountChange(orderLine, amount) {
		if (finished) return;
		if (amount === 0) {
			setConfirmDeleteId(orderLine.id);
		} else {
			dispatch(updateAmountOnOrderLine(orderLines, orderLine.id, amount));
		}
	}

	function handleDelete(val) {
		if (val && !finished) {
			dispatch(deleteOrderLine(confirmDeleteId));
		}
		setConfirmDeleteId(null);
	}

	useEffect(() => {
		if (order) {
			setTotals(order.totals);
		}
	}, [order]);

	useEffect(() => {
		const eventUids = orderLines
			.filter(value => value.eventUid)
			.map(m => m.eventUid)
			.filter((value, index, self) => self.indexOf(value) === index);
		if (eventUids.length > 0) {
			dispatch(getEventsByUids(eventUids));
		}

		return () => {};
	}, [orderLines, dispatch]);

	const sortedOrderLines = changeSortingForFollowProducts
		? _.orderBy(
				orderLines,
				[
					object =>
						object.lineType === 'followProduct'
							? object.visualRelation || followProductVisualView.NONE
							: null,
					object => events?.[object?.eventUid]?.start || null
				],
				['desc', 'asc']
		  )
		: orderLines;

	return (
		<div>
			<ConfirmationDialog
				content={t('REMOVE_FROM_CART')}
				keepMounted
				open={confirmDeleteId !== null}
				onClose={handleDelete}
				cancelButton={t('CANCEL_BUTTON')}
				okButton={t('OK_BUTTON')}
			/>
			{upsellModule && (
				<UpsellModule
					forceOpen={upsellModal}
					onClose={() => setUpsellModal(false)}
					contentElementProps={contentElementProps}
				/>
			)}
			<Paper elevation={3} className="mb-48 mx-12 md:mx-0 pr-12 relative">
				{orderLoading || allOrderLinesLoading || idsLoading || orderDraftLoading ? (
					<div className="absolute w-full h-full bg-gray-700 bg-opacity-25 z-50">
						<div className="flex justify-center items-center h-full">
							<SpinnerLoading title={t('LOADING')} />
						</div>
					</div>
				) : (
					''
				)}
				<Link color="primary" to="/">
					<Typography color="textPrimary" className="p-24 font-bold">
						<ArrowBackIcon />
						{t('GO_BACK')}
					</Typography>
				</Link>
				<Typography variant="h6" className="p-24">
					{t('YOUR_CART')}
				</Typography>
				<Table className="simple">
					<TableHead>
						<TableRow>
							<TableCell className={disabled ? '' : classes.tdLeft}>{t('PRODUCT')}</TableCell>
							<Hidden only={['xs', 'sm', 'md']}>
								<TableCell align="center">{t('PER_UNIT')}</TableCell>
							</Hidden>
							<TableCell align="center">{t('QUANTITY')}</TableCell>
							<TableCell align="right">{t('TOTAL_PRICE')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody className="">
						{sortedOrderLines
							.filter(ol => {
								if (ol.lineType === 'followProduct') {
									return ol.visualRelation === followProductVisualView.NONE || !ol.visualRelation;
								}
								if (discountCodes && ol.lineType === 'discountCode') {
									return false;
								}
								return true;
							})
							.map(ol => (
								<>
									<TableRow key={ol.id}>
										<TableCell
											className={clsx(
												!disabled && classes.tdLeft,
												((ol.followProducts && ol.followProducts.length > 0) ||
													(discountCodes &&
														ol.discountCodeProducts &&
														ol.discountCodeProducts.length > 0)) &&
													'no-border border-b-0'
											)}
										>
											{cartMobileLayout && (
												<ProductNameCell
													orderLine={ol}
													events={events}
													multiLanguageFunctions={multiLanguageFunctions}
													subscriptionPeriods={subscriptionPeriods}
												/>
											)}
											{!cartMobileLayout && (
												<>
													<Typography
														className="font-medium"
														variant="subtitle1"
														color="textSecondary"
													>
														{multiLanguageFunctions
															? getLocaleFromData(ol, 'name')
															: ol.name}
													</Typography>
													{subscriptionPeriods[ol.subscriptionTypeUid] && (
														<Typography
															className="font-medium"
															variant="subtitle2"
															color="textSecondary"
														>
															{moment(
																subscriptionPeriods[ol.subscriptionTypeUid].start
															).format('dddd DD-MM-YYYY')}
															{' - '}
															{moment(
																subscriptionPeriods[ol.subscriptionTypeUid].end
															).format('dddd DD-MM-YYYY')}
														</Typography>
													)}
													{ol.eventUid && ol.productType !== 'giftTicket' && (
														<>
															<Typography
																className="font-medium"
																variant="subtitle2"
																color="textSecondary"
															>
																{!multiLanguageFunctions && (
																	<>
																		{events[ol.eventUid]
																			? events[ol.eventUid].displayName ||
																			  events[ol.eventUid].name ||
																			  t('UNKOWN_EVENT')
																			: t('LOADING_EVENT')}
																	</>
																)}
																{multiLanguageFunctions && (
																	<>
																		{events[ol.eventUid]
																			? getLocaleFromData(
																					events[ol.eventUid],
																					'displayName'
																			  ) ||
																			  getLocaleFromData(
																					events[ol.eventUid],
																					'name'
																			  ) ||
																			  t('UNKOWN_EVENT')
																			: t('LOADING_EVENT')}
																	</>
																)}
															</Typography>
															{events[ol.eventUid] &&
																(!optionToHideEventPeriodInCart ||
																	ol.showEventPeriodInCart === null ||
																	ol.showEventPeriodInCart === undefined ||
																	ol.showEventPeriodInCart === true) && (
																	<Typography
																		className="font-medium"
																		variant="subtitle2"
																		color="textSecondary"
																	>
																		{getEventTime(
																			events[ol.eventUid].start,
																			events[ol.eventUid].end
																		)}
																	</Typography>
																)}
														</>
													)}
												</>
											)}
										</TableCell>
										<Hidden only={['xs', 'sm', 'md']}>
											<TableCell
												align="center"
												className={clsx(
													((ol.followProducts && ol.followProducts.length > 0) ||
														(discountCodes &&
															ol.discountCodeProducts &&
															ol.discountCodeProducts.length > 0)) &&
														'no-border border-b-0'
												)}
											>
												<Typography
													className="font-medium"
													variant="subtitle1"
													color="textSecondary"
												>
													{moneyFormatter.format(ol.unitPrice / 100)}
												</Typography>
											</TableCell>
										</Hidden>
										<TableCell
											className={clsx(
												((ol.followProducts && ol.followProducts.length > 0) ||
													(discountCodes &&
														ol.discountCodeProducts &&
														ol.discountCodeProducts.length > 0)) &&
													'no-border border-b-0'
											)}
											align="center"
										>
											{disabled ||
											(ol.lineType === 'followProduct' && !ol.allowCustomerToRemove) ? (
												ol.qty
											) : (
												<>
													{cartMobileLayout && (
														<ProductAmountCell
															classes={classes}
															finished={finished}
															orderLine={ol}
															flags={{
																maxAmountOfProductPerProductGroupInCart,
																minimumAmountOfProduct
															}}
															onAmountChange={onAmountChange}
															disabled={!!ol.giftValidation}
														/>
													)}
													{!cartMobileLayout && (
														<>
															{maxAmountOfProductPerProductGroupInCart ||
															minimumAmountOfProduct ? (
																<CartAmountField
																	className={clsx(
																		classes.numberInput,
																		'md:ml-64 lg:ml-64 rounded-none w-42 h-32'
																	)}
																	defaultValue={ol.qty}
																	type="number"
																	disabled={
																		finished ||
																		ol.giftValidation ||
																		(discountCodes &&
																			ol.lineType === 'discountCode')
																	}
																	onBlur={ev => {
																		const a = Number.parseInt(
																			ev.currentTarget.value,
																			10
																		);
																		if (!Number.isNaN(a)) {
																			onAmountChange(ol, a);
																		}
																	}}
																/>
															) : (
																<TextField
																	className={clsx(
																		classes.numberInput,
																		'md:ml-64 lg:ml-64 rounded-none w-42 h-32'
																	)}
																	defaultValue={ol.qty}
																	type="number"
																	disabled={
																		finished ||
																		ol.giftValidation ||
																		(discountCodes &&
																			ol.lineType === 'discountCode')
																	}
																	onBlur={ev => {
																		const a = Number.parseInt(
																			ev.currentTarget.value,
																			10
																		);
																		if (!Number.isNaN(a)) {
																			onAmountChange(ol, a);
																		}
																	}}
																/>
															)}
															<Hidden only={['xs']}>
																<Tooltip title={t('X')}>
																	<Button
																		size="small"
																		className="min-w-0"
																		onClick={() => onAmountChange(ol, 0)}
																		disabled={finished}
																	>
																		<DeleteOutline />
																	</Button>
																</Tooltip>
															</Hidden>
														</>
													)}
												</>
											)}
										</TableCell>
										<Hidden only={['md', 'lg', 'xl']}>
											<TableCell
												className={clsx(
													((ol.followProducts && ol.followProducts.length > 0) ||
														(discountCodes &&
															ol.discountCodeProducts &&
															ol.discountCodeProducts.length > 0)) &&
														'no-border border-b-0',
													'h-0'
												)}
												align="right"
											>
												{cartMobileLayout && (
													<ProductTotalCell
														orderLine={ol}
														onAmountChange={onAmountChange}
														finished={finished}
														moneyFormatter={moneyFormatter}
														orderLinesLoading={orderLinesLoading}
														classes={classes}
													/>
												)}
												{!cartMobileLayout && (
													<Typography
														className="font-medium"
														variant="subtitle1"
														color="textSecondary"
													>
														{orderLinesLoading.includes(ol.id) ? (
															<CircularProgress size={24} className={classes.spinner} />
														) : (
															moneyFormatter.format(ol.linePrice / 100)
														)}
													</Typography>
												)}
											</TableCell>
										</Hidden>
										<Hidden only={['xs', 'sm']}>
											<TableCell
												align="right"
												className={clsx(
													((ol.followProducts && ol.followProducts.length > 0) ||
														(discountCodes &&
															ol.discountCodeProducts &&
															ol.discountCodeProducts.length > 0)) &&
														'no-border border-b-0'
												)}
											>
												<Typography
													className="font-medium"
													variant="subtitle1"
													color="textSecondary"
												>
													{orderLinesLoading.includes(ol.id) ? (
														<CircularProgress size={24} className={classes.spinner} />
													) : (
														moneyFormatter.format(ol.linePrice / 100)
													)}
												</Typography>
											</TableCell>
										</Hidden>
									</TableRow>
									{ol.followProducts &&
										ol.followProducts.map((followProductOl, index) => {
											const notLast = index !== ol.followProducts.length - 1;
											return (
												<TableRow key={followProductOl.id} className="opacity-75">
													<TableCell
														className={`${disabled ? '' : classes.tdLeft} relative ${
															notLast ? 'no-border border-b-0' : ''
														}`}
														style={{ paddingTop: 0 }}
													>
														<div
															className="pt-28 sm:pt-16 mr-10 ml-10 border-gray border-dotted absolute top-0"
															style={{ borderWidth: '0 2px 0 0' }}
														/>
														<div className="flex">
															<div
																className="pl-16 mr-10 ml-10 border-gray border-dotted"
																style={{
																	borderWidth: '2px 0 0 0',
																	marginTop: '1.4rem'
																}}
															/>
															<Typography
																className="font-medium"
																variant="subtitle1"
																color="textSecondary"
															>
																{multiLanguageFunctions
																	? getLocaleFromData(followProductOl, 'name')
																	: followProductOl.name}
															</Typography>
														</div>
														{notLast && (
															<div
																className="mr-10 ml-10 border-gray border-dotted absolute bottom-0"
																style={{
																	borderWidth: '0 2px 0 0',
																	marginBottom: '0.22rem',
																	paddingBottom:
																		upsellModuleWithEvents && ol.eventUid
																			? '5.8rem'
																			: '2.8rem'
																}}
															/>
														)}
														{followProductOl.eventUid &&
															followProductOl.productType !== 'giftTicket' && (
																<div
																	className={
																		'pl-16 ml-20 lg:max-w-none md:max-w-200 max-w-128'
																	}
																>
																	<Typography
																		className="font-medium truncate"
																		variant="subtitle2"
																		color="textSecondary"
																	>
																		{!multiLanguageFunctions && (
																			<>
																				{events[followProductOl.eventUid]
																					? events[followProductOl.eventUid]
																							.displayName ||
																					  events[followProductOl.eventUid]
																							.name ||
																					  t('UNKOWN_EVENT')
																					: t('LOADING_EVENT')}
																			</>
																		)}
																		{multiLanguageFunctions && (
																			<>
																				{events[followProductOl.eventUid]
																					? getLocaleFromData(
																							events[
																								followProductOl.eventUid
																							],
																							'displayName'
																					  ) ||
																					  getLocaleFromData(
																							events[
																								followProductOl.eventUid
																							],
																							'name'
																					  ) ||
																					  t('UNKOWN_EVENT')
																					: t('LOADING_EVENT')}
																			</>
																		)}
																	</Typography>
																	{events[followProductOl.eventUid] &&
																		(!optionToHideEventPeriodInCart ||
																			ol.showEventPeriodInCart === null ||
																			ol.showEventPeriodInCart === undefined ||
																			ol.showEventPeriodInCart === true) && (
																			<Typography
																				className="font-medium truncate"
																				variant="subtitle2"
																				color="textSecondary"
																			>
																				{getEventTime(
																					events[followProductOl.eventUid]
																						.start,
																					events[followProductOl.eventUid].end
																				)}
																			</Typography>
																		)}
																</div>
															)}
													</TableCell>
													<Hidden only={['xs', 'sm', 'md']}>
														<TableCell
															align="center"
															style={{ paddingTop: 0 }}
															className={notLast ? 'no-border border-b-0' : ''}
														>
															{followProductOl.followProductOnePrLine && (
																<Typography
																	className="font-medium"
																	variant="subtitle1"
																	color="textSecondary"
																>
																	{moneyFormatter.format(
																		followProductOl.unitPrice / 100
																	)}
																</Typography>
															)}
														</TableCell>
													</Hidden>
													<TableCell
														className={notLast ? 'no-border border-b-0' : ''}
														align="center"
														style={{ paddingTop: 0 }}
													>
														{followProductOl.followProductOnePrLine && (
															<>
																{cartMobileLayout && (
																	<ProductAmountCell
																		classes={classes}
																		finished={finished}
																		onAmountChange={onAmountChange}
																		flags={{
																			maxAmountOfProductPerProductGroupInCart,
																			minimumAmountOfProduct
																		}}
																		orderLine={followProductOl}
																		followProduct
																	/>
																)}

																{!cartMobileLayout && (
																	<>
																		{maxAmountOfProductPerProductGroupInCart ? (
																			<CartAmountField
																				className={clsx(
																					classes.numberInput,
																					'md:ml-64 lg:ml-64 rounded-none w-42 h-32 bg-transparent'
																				)}
																				value={followProductOl.qty}
																				type="number"
																				disabled
																			/>
																		) : (
																			<TextField
																				className={clsx(
																					classes.numberInput,
																					'md:ml-64 lg:ml-64 rounded-none w-42 h-32 bg-transparent'
																				)}
																				value={followProductOl.qty}
																				type="number"
																				disabled
																			/>
																		)}
																		<Hidden only={['xs']}>
																			<Tooltip
																				title={
																					followProductOl.allowCustomerToRemove
																						? t('REMOVE_PRODUCT_FROM_CART')
																						: t(
																								'REMOVE_PRODUCT_NOT_ALLOWED'
																						  )
																				}
																			>
																				<span>
																					<Button
																						size="small"
																						className="min-w-0"
																						onClick={() =>
																							onAmountChange(ol, 0)
																						}
																						disabled={
																							finished ||
																							!followProductOl.allowCustomerToRemove
																						}
																					>
																						<DeleteOutline />
																					</Button>
																				</span>
																			</Tooltip>
																		</Hidden>
																	</>
																)}
															</>
														)}
													</TableCell>
													<Hidden only={['md', 'lg', 'xl']}>
														<TableCell
															className={notLast ? 'no-border border-b-0' : ''}
															align="right"
															style={{ paddingTop: 0 }}
														>
															{cartMobileLayout && (
																<div className="h-full flex flex-col">
																	<Hidden only={['sm', 'md', 'lg', 'xl']}>
																		<Tooltip title={t('REMOVE_PRODUCT_FROM_CART')}>
																			<div className="flex-1 flex justify-end items-start">
																				<Button
																					size="small"
																					className="p-0 min-w-0"
																					onClick={() =>
																						onAmountChange(ol, 0)
																					}
																					disabled={
																						finished ||
																						!followProductOl.allowCustomerToRemove
																					}
																				>
																					<DeleteOutline />
																				</Button>
																			</div>
																		</Tooltip>
																	</Hidden>
																	<Typography
																		className="font-medium"
																		variant="subtitle1"
																		color="textSecondary"
																	>
																		{orderLinesLoading.includes(
																			followProductOl.id
																		) ? (
																			<CircularProgress
																				size={24}
																				className={classes.spinner}
																			/>
																		) : (
																			moneyFormatter.format(
																				followProductOl.linePrice / 100
																			)
																		)}
																	</Typography>
																	<Hidden only={['sm', 'md', 'lg', 'xl']}>
																		<div className="flex-1" />
																	</Hidden>
																</div>
															)}
															{!cartMobileLayout && (
																<Typography
																	className="font-medium"
																	variant="subtitle1"
																	color="textSecondary"
																>
																	{orderLinesLoading.includes(followProductOl.id) ? (
																		<CircularProgress
																			size={24}
																			className={classes.spinner}
																		/>
																	) : (
																		moneyFormatter.format(
																			followProductOl.linePrice / 100
																		)
																	)}
																</Typography>
															)}
														</TableCell>
													</Hidden>
													<Hidden only={['xs', 'sm']}>
														<TableCell
															align="right"
															style={{ paddingTop: 0 }}
															className={notLast ? 'no-border border-b-0' : ''}
														>
															<Typography
																className="font-medium"
																variant="subtitle1"
																color="textSecondary"
															>
																{orderLinesLoading.includes(followProductOl.id) ? (
																	<CircularProgress
																		size={24}
																		className={classes.spinner}
																	/>
																) : (
																	moneyFormatter.format(
																		followProductOl.linePrice / 100
																	)
																)}
															</Typography>
														</TableCell>
													</Hidden>
												</TableRow>
											);
										})}
									{discountCodes &&
										ol.discountCodeProducts &&
										ol.discountCodeProducts.map((discountCodeOl, index) => {
											const notLast = index !== ol.discountCodeProducts.length - 1;
											return (
												<TableRow key={discountCodeOl.id} className="opacity-75">
													<TableCell
														className={`${disabled ? '' : classes.tdLeft} relative ${
															notLast ? 'no-border border-b-0' : ''
														}`}
														style={{ paddingTop: 0 }}
													>
														<div
															className="pt-28 sm:pt-16 mr-10 ml-10 border-gray border-dotted absolute top-0"
															style={{ borderWidth: '0 2px 0 0' }}
														/>
														<div className="flex">
															<div
																className="pl-16 mr-10 ml-10 border-gray border-dotted"
																style={{
																	borderWidth: '2px 0 0 0',
																	marginTop: '1.4rem'
																}}
															/>
															<Typography
																className="font-medium"
																variant="subtitle1"
																color="textSecondary"
															>
																{multiLanguageFunctions
																	? getLocaleFromData(discountCodeOl, 'name')
																	: discountCodeOl.name}
															</Typography>
														</div>
														{notLast && (
															<div
																className="pb-28 mr-10 ml-10 border-gray border-dotted absolute bottom-0"
																style={{
																	borderWidth: '0 2px 0 0',
																	marginBottom: '0.22rem'
																}}
															/>
														)}
														{discountCodeOl.eventUid &&
															discountCodeOl.productType !== 'giftTicket' && (
																<>
																	<Typography
																		className="font-medium"
																		variant="subtitle2"
																		color="textSecondary"
																	>
																		{!multiLanguageFunctions && (
																			<>
																				{events[discountCodeOl.eventUid]
																					? events[discountCodeOl.eventUid]
																							.displayName ||
																					  events[discountCodeOl.eventUid]
																							.name ||
																					  t('UNKOWN_EVENT')
																					: t('LOADING_EVENT')}
																			</>
																		)}
																		{multiLanguageFunctions && (
																			<>
																				{events[discountCodeOl.eventUid]
																					? getLocaleFromData(
																							events[
																								discountCodeOl.eventUid
																							],
																							'displayName'
																					  ) ||
																					  getLocaleFromData(
																							events[
																								discountCodeOl.eventUid
																							],
																							'name'
																					  ) ||
																					  t('UNKOWN_EVENT')
																					: t('LOADING_EVENT')}
																			</>
																		)}
																	</Typography>
																	{events[discountCodeOl.eventUid] &&
																		(!optionToHideEventPeriodInCart ||
																			ol.showEventPeriodInCart === null ||
																			ol.showEventPeriodInCart === undefined ||
																			ol.showEventPeriodInCart === true) && (
																			<Typography
																				className="font-medium"
																				variant="subtitle2"
																				color="textSecondary"
																			>
																				{getEventTime(
																					events[discountCodeOl.eventUid]
																						.start,
																					events[discountCodeOl.eventUid].end
																				)}
																			</Typography>
																		)}
																</>
															)}
													</TableCell>
													<Hidden only={['xs', 'sm', 'md']}>
														<TableCell
															align="center"
															style={{ paddingTop: 0 }}
															className={notLast ? 'no-border border-b-0' : ''}
														>
															{discountCodeOl.followProductOnePrLine && (
																<Typography
																	className="font-medium"
																	variant="subtitle1"
																	color="textSecondary"
																>
																	{moneyFormatter.format(
																		discountCodeOl.unitPrice / 100
																	)}
																</Typography>
															)}
														</TableCell>
													</Hidden>
													<TableCell
														className={notLast ? 'no-border border-b-0' : ''}
														align="center"
														style={{ paddingTop: 0 }}
													>
														{discountCodeOl.followProductOnePrLine && (
															<>
																{cartMobileLayout && (
																	<ProductAmountCell
																		classes={classes}
																		finished={finished}
																		onAmountChange={onAmountChange}
																		flags={{
																			maxAmountOfProductPerProductGroupInCart,
																			minimumAmountOfProduct
																		}}
																		orderLine={discountCodeOl}
																		followProduct
																	/>
																)}

																{!cartMobileLayout && (
																	<>
																		{maxAmountOfProductPerProductGroupInCart ? (
																			<CartAmountField
																				className={clsx(
																					classes.numberInput,
																					'md:ml-64 lg:ml-64 rounded-none w-42 h-32 bg-transparent'
																				)}
																				value={discountCodeOl.qty}
																				type="number"
																				disabled
																			/>
																		) : (
																			<TextField
																				className={clsx(
																					classes.numberInput,
																					'md:ml-64 lg:ml-64 rounded-none w-42 h-32 bg-transparent'
																				)}
																				value={discountCodeOl.qty}
																				type="number"
																				disabled
																			/>
																		)}
																		<Hidden only={['xs']}>
																			<Tooltip
																				title={
																					discountCodeOl.allowCustomerToRemove
																						? t('REMOVE_PRODUCT_FROM_CART')
																						: t(
																								'REMOVE_PRODUCT_NOT_ALLOWED'
																						  )
																				}
																			>
																				<span>
																					<Button
																						size="small"
																						className="min-w-0"
																						onClick={() =>
																							onAmountChange(ol, 0)
																						}
																						disabled={
																							finished ||
																							!discountCodeOl.allowCustomerToRemove
																						}
																					>
																						<DeleteOutline />
																					</Button>
																				</span>
																			</Tooltip>
																		</Hidden>
																	</>
																)}
															</>
														)}
													</TableCell>
													<Hidden only={['md', 'lg', 'xl']}>
														<TableCell
															className={notLast ? 'no-border border-b-0' : ''}
															align="right"
															style={{ paddingTop: 0 }}
														>
															{cartMobileLayout && (
																<div className="h-full flex flex-col">
																	<Hidden only={['sm', 'md', 'lg', 'xl']}>
																		<Tooltip title={t('REMOVE_PRODUCT_FROM_CART')}>
																			<div className="flex-1 flex justify-end items-start">
																				<Button
																					size="small"
																					className="p-0 min-w-0"
																					onClick={() =>
																						onAmountChange(ol, 0)
																					}
																					disabled={
																						finished ||
																						!discountCodeOl.allowCustomerToRemove
																					}
																				>
																					<DeleteOutline />
																				</Button>
																			</div>
																		</Tooltip>
																	</Hidden>
																	<Typography
																		className="font-medium"
																		variant="subtitle1"
																		color="textSecondary"
																	>
																		{orderLinesLoading.includes(
																			discountCodeOl.id
																		) ? (
																			<CircularProgress
																				size={24}
																				className={classes.spinner}
																			/>
																		) : (
																			moneyFormatter.format(
																				discountCodeOl.linePrice / 100
																			)
																		)}
																	</Typography>
																	<Hidden only={['sm', 'md', 'lg', 'xl']}>
																		<div className="flex-1" />
																	</Hidden>
																</div>
															)}
															{!cartMobileLayout && (
																<Typography
																	className="font-medium"
																	variant="subtitle1"
																	color="textSecondary"
																>
																	{orderLinesLoading.includes(discountCodeOl.id) ? (
																		<CircularProgress
																			size={24}
																			className={classes.spinner}
																		/>
																	) : (
																		moneyFormatter.format(
																			discountCodeOl.linePrice / 100
																		)
																	)}
																</Typography>
															)}
														</TableCell>
													</Hidden>
													<Hidden only={['xs', 'sm']}>
														<TableCell
															align="right"
															style={{ paddingTop: 0 }}
															className={notLast ? 'no-border border-b-0' : ''}
														>
															<Typography
																className="font-medium"
																variant="subtitle1"
																color="textSecondary"
															>
																{orderLinesLoading.includes(discountCodeOl.id) ? (
																	<CircularProgress
																		size={24}
																		className={classes.spinner}
																	/>
																) : (
																	moneyFormatter.format(
																		discountCodeOl.linePrice / 100
																	)
																)}
															</Typography>
														</TableCell>
													</Hidden>
												</TableRow>
											);
										})}
								</>
							))}
					</TableBody>
				</Table>
				<Table className="simple mt-32">
					<TableBody>
						{upsellModule && (
							<TableRow>
								<TableCell colSpan={2}>
									<Button
										className={addedColorOptionsForButtonsInCart && classes.upsellButton}
										endIcon={<Icon>arrow_forward</Icon>}
										onClick={() => setUpsellModal(true)}
									>
										{t('EDIT_UPSELL_OPTIONS')}
									</Button>
								</TableCell>
							</TableRow>
						)}
						{discountCodes &&
							orderLines
								.filter(
									ol =>
										ol.lineType === 'discountCode' &&
										ol.discountCode &&
										ol.discountCode.discountCalculateType !==
											discountCodeDiscountCalculateType.PER_PRODUCT
								)
								.map(ol => (
									<TableRow>
										<TableCell className={disabled ? '' : classes.tdLeft}>
											<Typography
												className="font-medium"
												variant="subtitle1"
												color="textSecondary"
											>
												{ol.name}
											</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography
												className="font-medium"
												variant="subtitle1"
												color="textSecondary"
											>
												{totalsLoading ? (
													<CircularProgress size={24} className={classes.spinner} />
												) : (
													moneyFormatter.format(ol.linePrice / 100)
												)}
											</Typography>
										</TableCell>
									</TableRow>
								))}
						{orderLines
							.filter(
								ol =>
									ol.lineType === 'followProduct' &&
									ol.visualRelation === followProductVisualView.AT_TOTAL
							)
							.map(ol => (
								<TableRow>
									<TableCell className={disabled ? '' : classes.tdLeft}>
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{ol.name}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{totalsLoading ? (
												<CircularProgress size={24} className={classes.spinner} />
											) : (
												moneyFormatter.format(ol.linePrice / 100)
											)}
										</Typography>
									</TableCell>
								</TableRow>
							))}
						<TableRow>
							<TableCell className={disabled ? '' : classes.tdLeft}>
								<Typography className="font-medium" variant="subtitle1" color="textSecondary">
									Subtotal
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography className="font-medium" variant="subtitle1" color="textSecondary">
									{totalsLoading ? (
										<CircularProgress size={24} className={classes.spinner} />
									) : (
										moneyFormatter.format(totals ? totals.subtotal / 100 : 0)
									)}
								</Typography>
							</TableCell>
						</TableRow>
						{totals && totals.getByTaxPercent ? (
							Object.keys(totals.getByTaxPercent).map(key => (
								<TableRow key={key}>
									<TableCell className={disabled ? '' : classes.tdLeft}>
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{t('TAX')} ({key / 100}%)
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography className="font-medium" variant="subtitle1" color="textSecondary">
											{totalsLoading ? (
												<CircularProgress size={24} className={classes.spinner} />
											) : (
												moneyFormatter.format(totals.getByTaxPercent[key] / 100)
											)}
										</Typography>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell className={disabled ? '' : classes.tdLeft}>
									<Typography className="font-medium" variant="subtitle1" color="textSecondary">
										{t('TAX')}
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography className="font-medium" variant="subtitle1" color="textSecondary">
										{orderLinesLoading.includes('totals') ? (
											<CircularProgress size={24} className={classes.spinner} />
										) : (
											moneyFormatter.format(totals ? totals.tax / 100.0 : 0)
										)}
									</Typography>
								</TableCell>
							</TableRow>
						)}
						<TableRow>
							<TableCell className={disabled ? '' : classes.tdLeft}>
								<Typography className="font-light" variant="h4" color="textSecondary">
									{t('TOTAL')}
								</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography className="font-light" variant="h4" color="textSecondary">
									{totalsLoading ? (
										<CircularProgress size={24} className={classes.spinner} />
									) : (
										moneyFormatter.format(totals ? totals.total / 100 : 0)
									)}
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
			{/* <div className="flex flex-col items-center justify-center p-12"> */}
			{/* 	<div className="m-12"> */}
			{/* 		<Icon className="text-96" color="action"> */}
			{/* 			sentiment_dissatisfied */}
			{/* 		</Icon> */}
			{/* 	</div> */}

			{/* 	<Typography variant="h5" className="text-center mb-16"> */}
			{/* 		Øv */}
			{/* 	</Typography> */}

			{/* 	<Typography className="text-center mb-16 w-full" color="textSecondary"> */}
			{/* 		Du har intet i din kurv endnu. */}
			{/* 	</Typography> */}

			{/* 	<div className="flex flex-col items-center justify-center pt-12 pb-24"> */}
			{/* 		<Button */}
			{/*			variant="contained" */}
			{/*			className="rounded-none" */}
			{/*			color="primary" */}
			{/*			component={Link} */}
			{/*			to="/apps/pricing" */}
			{/*		> */}
			{/*			Tilføj produkter */}
			{/*		</Button> */}
			{/*	</div> */}
			{/* </div> */}
		</div>
	);
}

export default CartContentOverview;
